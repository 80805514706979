import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { logout } from '~/store/auth'
import reducer from '~/store/store'

import env from '~/env'

const BASE_URL = env?.apiUrl

class APIClient {
  private: AxiosInstance
  privateFormData: AxiosInstance
  public: AxiosInstance

  constructor() {
    // Auth API client
    this.private = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
    })

    // Auth API Form Data
    this.privateFormData = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    this.createRequestInterceptor()
    this.createResponseInterceptor()

    // Non-Auth API client
    this.public = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
    })
  }

  createRequestInterceptor = () => {
    this.private.interceptors.request.use(
      function (config: any) {
        const { store } = reducer()

        const session_token = store.getState().auth.session_token
        config.headers['Authorization'] = `Bearer ${session_token}`

        return config
      },
      function (error: any) {
        return Promise.reject(error)
      }
    )

    this.privateFormData.interceptors.request.use(
      function (config: any) {
        const { store } = reducer()

        const session_token = store.getState().auth.session_token
        config.headers['Authorization'] = `Bearer ${session_token}`

        return config
      },
      function (error: any) {
        return Promise.reject(error)
      }
    )
  }

  createResponseInterceptor = () => {
    this.private.interceptors.response.use(
      (response: any) => {
        return response
      },
      (error: any) => {
        if (error?.response?.status === 401) {
          const { store } = reducer()
          store.dispatch(logout())
        }
        return Promise.reject(error)
      }
    )
  }

  handle = <T>(
    promise: Promise<AxiosResponse<T>>
  ): Promise<[AxiosResponse<T> | undefined, any]> => {
    return promise
      .then(
        (data: AxiosResponse<T>) =>
          [data, undefined] as [AxiosResponse<T>, undefined]
      )
      .catch(
        (error: AxiosError<AxiosResponse<any>>) =>
          [undefined, error.response] as [undefined, any]
      )
  }
}

export default new APIClient()
