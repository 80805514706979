import React, { useEffect, useMemo, useState } from 'react'
import { Pressable, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import Text from '~/components/StyledText'
import ReadyToRentBadge from '~/components/badges/ReadyToRentBadge'
import Image from '~/components/base/Image'

import { toCurrency } from '~/lib/money'
import { convertToWeeklyRate } from '~/lib/pricingRate'

interface IProps {
  navigation: any
  product: any
  height: number
}

const ProductTile: React.FC<IProps> = ({ navigation, product, height }) => {
  const tailwind = useTailwind()

  const handleNavigate = (productId: number) => {
    const state = navigation.getState()
    const routes = state.routes

    navigation.push('Product', {
      product_id: String(productId),
    })
  }

  const productImage = useMemo(() => {
    if (product && product.Images) {
      const imageIndex = product?.Images.findIndex(
        (image: { id: any }) => image.id == product.imageOrdering[0]
      )
      if (imageIndex > -1) {
        return product?.Images[imageIndex]
      }
      return product?.Images[0]
    }
    return false
  }, [product, product?.Images])

  return (
    <View style={[tailwind('w-full h-full')]}>
      <Pressable
        style={tailwind('flex flex-col justify-between')}
        onPress={() => handleNavigate(product.id)}
      >
        {productImage && (
          <Image
            imageStyles={[{ aspectRatio: 1 }]}
            source={{
              uri: productImage.url
                ? productImage.variants?.find((i: any) => i === 'thumbnail')
                  ? productImage.url.replace('.jpeg', '_thumbnail.jpeg')
                  : productImage.url
                : 'https://i.imgur.com/8ksd5oE.png',
            }}
            height={height}
            rounded
          />
        )}
        {!product?.Images && (
          <Image
            imageStyles={[
              tailwind('mb-2 w-full rounded-lg'),
              { aspectRatio: 1 },
            ]}
            source={{
              uri: 'https://i.imgur.com/8ksd5oE.png',
            }}
            height={height}
            rounded
          />
        )}
        <ReadyToRentBadge productId={product.id} />

        <View style={{ marginTop: 5 }}>
          <Text style={[tailwind('leading-5'), { fontSize: 12 }]}>
            {product.name}
          </Text>
          {product?.ProductItems && product?.ProductItems.length > 0 && (
            <Text style={[tailwind('font-bold leading-4'), { fontSize: 14 }]}>
              {product?.ProductItems &&
                toCurrency(
                  product.ProductItems.map((productItem: any) =>
                    productItem.PricingRates.map((pricingRate: any) =>
                      convertToWeeklyRate(pricingRate)
                    )
                  )
                    .flat()
                    .sort()[0]
                )}{' '}
              / minggu
            </Text>
          )}

          {product?.ProductItems && product?.ProductItems.length > 0 && (
            <Text
              style={[
                tailwind('text-xs text-gray-400 leading-3'),
                { marginTop: 6 },
              ]}
            >
              Retail{' '}
              {toCurrency(
                product.ProductItems.map(
                  (productItem: any) => productItem.retailPrice
                )
                  .flat()
                  .sort()[0]
                  ?.toLocaleString('ID')
              )}
            </Text>
          )}
        </View>
      </Pressable>
    </View>
  )
}

export default ProductTile
