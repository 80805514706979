import * as React from 'react'
import Svg, { Defs, Path, Symbol, Use } from 'react-native-svg'

const Close = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    {...props}
  >
    <Defs>
      <Symbol id="close">
        <Path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </Symbol>
    </Defs>

    <Use href="#close" />
  </Svg>
)

export default Close
