import React, { useEffect } from 'react'
import { Image, Pressable, ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { getAll } from '~/store/collection'
import { RootState, useAppDispatch, useAppSelector } from '~/store/store'

import Container from '~/components/Container'
import SearchResult from '~/components/SearchResult'
import Text from '~/components/StyledText'
import BackHeader from '~/components/headers/BackHeader'

import { DashboardProps } from '~/navigation/types'

export default function Catalog({ navigation }: DashboardProps<'Collections'>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()
  const { collections } = useAppSelector((state: RootState) => state.collection)

  useEffect(() => {
    dispatch(getAll())
  }, [])

  console.log('collection.Images', collections)

  return (
    <Container>
      <ScrollView style={tailwind('w-full flex flex-col')}>
        <BackHeader navigation={navigation} text="Koleksi Pilihan Bioma" />
        <SearchResult navigation={navigation} />
        <View style={tailwind('pt-14')}>
          <Pressable
            onPress={() => navigation.navigate('Babyloania')}
            style={tailwind('px-4 mb-4')}
          >
            <Image
              source={require('~/assets/images/babyloania/banner.png')}
              style={[
                { aspectRatio: 2, height: '185px' },
                tailwind('rounded-t'),
              ]}
            />
            <View
              style={[{ background: '#B2E2DF' }, tailwind('p-2 rounded-b')]}
            >
              <Text style={tailwind('font-bold')}>Babyloania Collections</Text>
            </View>
          </Pressable>
          {collections.map((collection, i) => (
            <Pressable
              onPress={() =>
                navigation.navigate('Collection', {
                  collection_id: `${collection.id}`,
                })
              }
              style={tailwind('px-4 mb-4')}
              key={i}
            >
              {collection.Images?.length ? (
                <Image
                  source={{ uri: collection.Images[0].url }}
                  style={[
                    { aspectRatio: 2, height: '160px' },
                    tailwind('rounded-t'),
                  ]}
                />
              ) : (
                <React.Fragment />
              )}

              {!collection.Images?.length && (
                <Image
                  source={{ uri: 'https://i.imgur.com/OPsGLq5.png' }}
                  style={[
                    { aspectRatio: 2, height: '160px' },
                    tailwind('rounded-t'),
                  ]}
                />
              )}
              <View
                style={[{ background: '#B2E2DF' }, tailwind('p-2 rounded-b')]}
              >
                <Text style={tailwind('font-bold')}>{collection.name}</Text>
                <Text style={tailwind('text-xs')}>
                  {collection.productCount || '-'} Produk
                </Text>
              </View>
            </Pressable>
          ))}
        </View>
      </ScrollView>
    </Container>
  )
}
