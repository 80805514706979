import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AssetAvailabilityAttributes } from '@withbioma/apitype-publicapi-public/lib/v1/common/asset_availability'

import {
  constructErrorPayload,
  constructExtraReducer,
  constructSuccessPayload,
} from '~/store/helper'

import { getAll as getAllCall } from '~/apis/asset_availabilities'
import { getAllAvailabilityForProductItem as getAllAvailabilityForProductItemCall } from '~/apis/product_availabilities'

interface IState {
  assetAvailabilities: AssetAvailabilityAttributes[]
  assetAvailability?: AssetAvailabilityAttributes
  loading: Record<string, any>
  responses: Record<string, any>
}
const initialState: IState = {
  assetAvailabilities: [],
  assetAvailability: undefined,
  loading: {},
  responses: {},
}

export const getAll = createAsyncThunk(
  'assetAvailability/getAll',
  async (
    {
      productId,
      productItemId,
      durationDays,
    }: { productId: number; productItemId: number; durationDays: number },
    { rejectWithValue }
  ) => {
    const [res, resErr] = await getAllCall(
      productId,
      productItemId,
      durationDays
    )
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const getAllAvailabilityForProductItem = createAsyncThunk(
  'assetAvailability/getAllAvailabilityForProductItem',
  async (
    {
      productId,
      productItemId,
      // startsAt,
      durationDays,
    }: { productId: number; productItemId: number; durationDays?: number },
    { rejectWithValue }
  ) => {
    const [res, resErr] = await getAllAvailabilityForProductItemCall(
      productId,
      productItemId,
      {
        // startsAt,
        durationDays,
      }
    )
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const reducerSlice = createSlice({
  name: 'assetAvailability',
  initialState,
  reducers: {
    resetResponse(state: IState, action: PayloadAction<any>) {
      const apiName = action.payload
      state.responses = {
        ...state.responses,
        [apiName]: null,
      }
    },
    resetAssetAvailabilities(state: IState) {
      state.assetAvailabilities = []
    },
    resetAssetAvailability(state: IState) {
      state.assetAvailability = undefined
    },
  },
  extraReducers: (builder) => {
    constructExtraReducer(
      builder,
      getAll,
      (state: IState, action: PayloadAction<any>) => {
        state.assetAvailabilities = action.payload.body.assetAvailabilities
      }
    )
    constructExtraReducer(
      builder,
      getAllAvailabilityForProductItem,
      (state: IState, action: PayloadAction<any>) => {
        state.assetAvailabilities = action.payload.body.assetAvailabilities
      }
    )
  },
})

export const {
  resetResponse,
  resetAssetAvailabilities,
  resetAssetAvailability,
} = reducerSlice.actions
export default reducerSlice.reducer
