import Svg, { Path } from 'react-native-svg'

const Order = (props: any) => {
  return (
    <Svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M6.76172 8H15.2382"
        stroke={props?.color || '#00443E'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.76172 12.1602H15.2382"
        stroke={props?.color || '#00443E'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.76172 16H11"
        stroke={props?.color || '#00443E'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M15.8732 20.1598H6.12785C4.85276 20.1598 3.82812 19.1398 3.82812 17.8705V6.12918C3.82812 4.85984 4.85276 3.83984 6.12785 3.83984H15.896C17.1483 3.83984 18.173 4.85984 18.173 6.12918V17.8932C18.173 19.1398 17.1483 20.1598 15.8732 20.1598Z"
        stroke={props?.color || '#00443E'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </Svg>
  )
}

export default Order
