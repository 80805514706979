import * as Linking from 'expo-linking'
import { openBrowserAsync } from 'expo-web-browser'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Pressable, ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { publicConfirm, publicGet } from '~/store/order'
import { RootState, useAppDispatch, useAppSelector } from '~/store/store'

import Container from '~/components/Container'
import Text from '~/components/StyledText'
import Button from '~/components/base/Button/Button'
import Input from '~/components/base/Input'
import StockValidityInfo from '~/components/cart/StockValidityInfo'
import AddOnOrderItemRow from '~/components/order_items/AddOnRow'
import ProductOrderItemRow from '~/components/order_items/ProductItemRow'
import {
  OrderContextProvider,
  useOrderContext,
} from '~/components/orders/OrderContext'

import { formatDate } from '~/lib/datetime'
import { toCurrency } from '~/lib/money'
import { RootStackProps } from '~/navigation/types'

function OrderConfirmationContent({
  route,
  navigation,
}: RootStackProps<'OrderConfirmation'>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()
  const {
    evalOrder,
    resetOrder,
    isLoaded,
    subtotal,
    total,
    address,
    paymentDeadline,
  } = useOrderContext()
  const { order_confirmation_uuid } = route.params
  const { publicOrder: order } = useAppSelector(
    (state: RootState) => state.order
  )

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm()

  const [modalVisible, setModalVisible] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)

  const isExpired = useMemo(() => {
    if (paymentDeadline) {
      const now = DateTime.now()
      const candidate = DateTime.fromFormat(paymentDeadline, 'dd LLL HH:mm')

      return now > candidate
    }
    return false
  }, [paymentDeadline])

  const onSubmit = async (data: any) => {
    if (isExpired) return undefined

    setSubmitted(true)

    const payload = {
      deliveryAddressInstructions:
        data.deliveryAddressInstructions || undefined,
    }
    const res = await dispatch(
      publicConfirm({
        orderConfirmationUuid: order_confirmation_uuid as string,
        payload,
      })
    )

    if (publicConfirm.fulfilled.match(res)) {
      const body = res.payload?.body
      const transactions = body?.order.Invoices?.[0]?.Transactions

      if (transactions && transactions[0] && transactions[0].xenditInvoiceUrl) {
        const result = await openBrowserAsync(
          transactions[0].xenditInvoiceUrl,
          {
            showInRecents: true,
          }
        )

        if (result || (result as any)?.type == 'opened') {
          navigation.navigate('Root', {
            screen: 'Order',
          })
          setSubmitted(false)
        }
      }
    }
  }

  useEffect(() => {
    ;(async () => {
      if (order_confirmation_uuid) {
        const res = await dispatch(publicGet(order_confirmation_uuid))

        if (!publicGet.fulfilled.match(res)) {
          navigation.navigate('Root', { screen: 'Dashboard' })
        }
      } else {
        navigation.navigate('Root', { screen: 'Dashboard' })
      }
    })()
  }, [order_confirmation_uuid])

  useEffect(() => {
    if (order) evalOrder(order)
  }, [order])

  return (
    <Container>
      {!isExpired ? (
        <View style={tailwind('pt-14 bg-yellow-400 px-4 py-2')}>
          <Text style={tailwind('text-sm')}>
            Konfirmasi Pembayaran sebelum:{' '}
            <Text style={tailwind('font-bold')}>
              {isLoaded
                ? formatDate(order?.awaitingConfirmationUntil, 'dd LLL HH:mm')
                : '-'}
            </Text>
          </Text>
        </View>
      ) : (
        <StockValidityInfo
          visibleState={[modalVisible, setModalVisible]}
          onPress={() => navigation.navigate('Root')}
          style={{
            marginTop: 0,
            marginLeft: 0,
            marginRight: 0,
            borderRadius: 0,
          }}
        />
      )}

      <ScrollView
        style={tailwind('flex flex-col w-full bg-white')}
        showsVerticalScrollIndicator={false}
      >
        <View style={tailwind('p-4 mt-2')}>
          <Text style={tailwind('text-lg font-bold mb-4')}>Detail Pemesan</Text>
          <Text style={tailwind('text-sm font-bold mb-1')}>
            {order?.Customer?.name}
          </Text>
          <Text style={tailwind('text-sm mb-1')}>
            {order?.Customer?.User?.email}
          </Text>
          <Text style={tailwind('text-sm')}>
            {order?.Customer?.User?.phone}
          </Text>
        </View>

        <View style={tailwind('p-4')}>
          <Text style={tailwind('text-lg font-bold mb-4')}>
            Alamat Pengiriman
          </Text>
          <Text style={tailwind('text-sm')}>{isLoaded ? address : '-'}</Text>
        </View>

        <View style={tailwind('p-4')}>
          <Text style={tailwind('text-lg font-bold mb-4')}>Rincian Produk</Text>

          {order?.OrderItems?.filter((orderItem) => orderItem.ProductItem)?.map(
            (orderItem, i) => {
              const len =
                order?.OrderItems && order?.OrderItems?.length > 1
                  ? order?.OrderItems?.length
                  : 0
              const isLastIndex = len > 1 ? len - 1 == i : false

              return (
                <ProductOrderItemRow
                  key={i}
                  orderItem={orderItem}
                  isLastIndex={isLastIndex}
                />
              )
            }
          )}

          <View
            style={tailwind('flex flex-col border-b-2 border-gray-200 py-4')}
          >
            <View style={tailwind('flex flex-row justify-between')}>
              <Text style={tailwind('text-sm')}>Total biaya sewa</Text>
              <Text style={tailwind('text-sm')}>
                {isLoaded ? toCurrency(subtotal) : '-'}
              </Text>
            </View>

            {order?.OrderItems?.filter((orderItem) => orderItem.AddOn)?.map(
              (orderItem, i) => (
                <AddOnOrderItemRow orderItem={orderItem} key={i} />
              )
            )}
          </View>

          <View style={tailwind('flex flex-col pt-4')}>
            <View style={tailwind('flex flex-row justify-between')}>
              <Text style={tailwind('text-sm')}>Total bayar</Text>
              <Text style={tailwind('text-sm')}>
                {isLoaded ? toCurrency(total) : '-'}
              </Text>
            </View>

            <View style={tailwind('flex flex-row mt-4')}>
              <Text style={tailwind('text-xs mr-2 text-gray-500')}>*</Text>
              <Text style={tailwind('text-xs text-gray-500')}>
                Harga belum final dan dapat berubah sebelum pesanan dikonfirmasi
              </Text>
            </View>
          </View>
        </View>

        <View style={tailwind('p-4')}>
          <Text style={tailwind('text-lg mb-2 font-bold')}>Catatan</Text>
          <Input
            style={[
              tailwind('px-2 w-full rounded'),
              { borderColor: '#EAEAEA' },
            ]}
            placeholder={'Tulis catatan pengiriman'}
            multiline={true}
            numberOfLines={3}
            control={control}
            rules={{
              maxLength: 100,
            }}
            name="deliveryAddressInstructions"
            defaultValue={order?.DeliveryAddress?.instructions || ''}
            errors={errors}
          />
        </View>

        <View style={tailwind('p-4')}>
          <Text style={tailwind('text-lg mb-2 font-bold')}>Bantuan</Text>
          <Text style={tailwind('text-xs text-gray-500 mb-4')}>
            Pesanan tidak sesuai atau ingin mengganti pesanan?
          </Text>
          <Pressable
            style={[
              tailwind('py-2 px-5 rounded border text-white mb-2'),
              {
                borderColor: '#00443E',
              },
            ]}
            onPress={() => {
              Linking.openURL('https://wa.me/6281284455345')
            }}
          >
            <Text style={tailwind('text-center text-sm font-bold')}>
              Hubungi Customer Service
            </Text>
          </Pressable>
        </View>
      </ScrollView>

      <View
        style={[
          tailwind('p-4 sticky bottom-0 rounded-t'),
          { shadowColor: 'rgba(0,0,0,0.25)', shadowRadius: 8, elevation: 5 },
        ]}
      >
        <View style={tailwind('flex flex-row justify-between')}>
          <View style={tailwind('flex flex-col')}>
            <Text style={tailwind('text-xs text-gray-500')}>
              Total Keranjang
            </Text>
            <Text style={tailwind('text-sm font-bold')}>
              {isLoaded ? toCurrency(total) : '-'}
            </Text>
          </View>

          <Button
            onPress={handleSubmit(onSubmit)}
            text={!isSubmitted ? 'Konfirmasi dan Bayar' : 'Tunggu'}
            disabled={isExpired || isSubmitted}
          />
        </View>
      </View>
    </Container>
  )
}

export default function OrderConfirmation({
  route,
  navigation,
}: RootStackProps<'OrderConfirmation'>) {
  return (
    <OrderContextProvider>
      <OrderConfirmationContent route={route} navigation={navigation} />
    </OrderContextProvider>
  )
}
