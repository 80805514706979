import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Animated,
  Dimensions,
  Easing,
  GestureResponderEvent,
  Pressable,
  SafeAreaView,
  TouchableOpacity,
  View,
} from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import Text from '~/components/StyledText'

import Close from '~/svg/Close'

type GestureEvent = (event: GestureResponderEvent) => void

type Props = {
  visible: boolean
  onPress: GestureEvent
  onClose: () => void
}

const CartSuccess: React.FC<Props> = ({ visible, onPress, onClose }) => {
  const tailwind = useTailwind()

  const animatedValue = useRef(new Animated.Value(0)).current

  const startAnimation = useCallback((value: number, func?: Function) => {
    Animated.timing(animatedValue, {
      toValue: value,
      duration: 500,
      useNativeDriver: true,
    }).start(({ finished }) => {
      if (finished && func && typeof func == 'function') {
        func()
      }
    })
  }, [])

  useEffect(() => {
    const timeoutDuration = 2000

    if (visible) {
      startAnimation(1, () => {
        setTimeout(() => startAnimation(0, () => onClose()), timeoutDuration)
      })
    } else {
      startAnimation(0)
    }
  }, [visible, onClose])

  const transformStyles = () => {
    return [
      {
        translateY: animatedValue.interpolate({
          inputRange: [0, 1],
          outputRange: [300, 0],
        }),
      },
      { perspective: 1000 },
      { translateX: '-50%' },
    ]
  }

  if (!visible) return <React.Fragment />

  return (
    <Animated.View
      style={[
        tailwind(
          'flex flex-row absolute justify-between bg-primary p-2 rounded'
        ),
        {
          top: '-70px',
          left: '50%',
          width: '93%',
          marginLeft: 2,
          transform: transformStyles(),
        },
      ]}
    >
      <TouchableOpacity activeOpacity={1} onPress={onPress}>
        <Text style={[tailwind('text-sm'), { color: '#00443E' }]}>
          Produk berhasil ditambahkan ke keranjang
        </Text>
        <Text style={[tailwind('pt-1'), { color: '#00443E' }]}>
          Lihat keranjang sekarang
        </Text>
      </TouchableOpacity>

      <Pressable onPress={() => onClose()}>
        <Close />
      </Pressable>
    </Animated.View>
  )
}

export default CartSuccess
