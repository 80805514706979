export const isDefined = <T>(value: T | undefined | null): boolean => {
  return value !== null && value !== undefined
}

export const isArray = <T>(value: T) => {
  return {}.toString.call(value) === '[object Array]'
}

export function isFunction(value: any): value is (...args: any[]) => boolean {
  return typeof value === 'function'
}

export const isValidDate = (d: Date | string) => {
  return !isNaN(new Date(d).getTime())
}
