import * as React from 'react'
import Svg, { Defs, Path, Symbol, Use } from 'react-native-svg'

const Information = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={20}
    height={20}
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    {...props}
  >
    <Defs>
      <Symbol id="information">
        <Path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </Symbol>
    </Defs>
    <Use href="#information" />
  </Svg>
)

export default Information
