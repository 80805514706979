import { CustomOrderAttributes } from '@withbioma/apitype-publicapi-public/lib/v1/orders/api'
import { DateTime } from 'luxon'
import { createContext, useContext, useState } from 'react'

interface IOrderContext {
  evalOrder(order: CustomOrderAttributes): void
  resetOrder(): void
  // whether or not an order has been evaluated
  isLoaded: boolean
  // sum of order item price
  subtotal: number
  // sum of order item and add on price
  total: number
  // the full formatted address to display
  address: string
  // the full formatted payment deadline
  paymentDeadline: string
  productOrderItemMetadataMap: Map<number, ProductOrderItemMetadata>
}

interface ProductOrderItemMetadata {
  imageUrl: string
  productName: string
  productVariants: string
  startsAt: string
  endsAt: string
  durationDays: number
}

const OrderContext = createContext<IOrderContext>({
  evalOrder: (order: CustomOrderAttributes) => {
    throw new Error('Not implemented yet')
  },
  resetOrder: () => {
    throw new Error('Not implemented yet')
  },
  isLoaded: false,
  subtotal: 0,
  total: 0,
  address: '',
  paymentDeadline: '',
  productOrderItemMetadataMap: new Map(),
})

// OrderContext contains the logic for metadata calculation of order data.
// Central location for calculating prices, dates, and other formatted data that
// are used across multiple components.
export function OrderContextProvider({ children }: { children: any }) {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [subtotal, setSubtotal] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  const [address, setAddress] = useState<string>('')
  const [paymentDeadline, setPaymentDeadline] = useState<string>('')
  const [productOrderItemMetadataMap, setProductOrderItemMetadataMap] =
    useState<Map<number, ProductOrderItemMetadata>>(new Map())

  const evalOrder = (order: CustomOrderAttributes) => {
    setSubtotal(
      order.OrderItems?.filter((orderItem) => orderItem.ProductItem)?.reduce(
        (prev, cur) => prev + cur.price,
        0
      ) || 0
    )
    setTotal(order.OrderItems?.reduce((prev, cur) => prev + cur.price, 0) || 0)
    setAddress(
      [
        order.DeliveryAddress?.address,
        order.DeliveryAddress?.district,
        order.DeliveryAddress?.regency,
        order.DeliveryAddress?.province,
        order.DeliveryAddress?.zipcode,
      ].join(', ')
    )
    if (
      order.Invoices?.[0]?.Transactions &&
      order.Invoices?.[0]?.Transactions?.length
    ) {
      setPaymentDeadline(
        DateTime.fromISO(
          `${order.Invoices?.[0]?.Transactions![0]?.expiresAt}`
        ).toFormat('dd LLL HH:mm')
      )
    }
    evalProductOrderItemMap(order)
    setIsLoaded(true)
  }

  const evalProductOrderItemMap = (order: CustomOrderAttributes) => {
    const map = new Map<number, ProductOrderItemMetadata>(
      order.OrderItems?.filter((orderItem) => orderItem.ProductItem)?.map(
        (orderItem) => [
          orderItem.id,
          {
            imageUrl:
              orderItem.ProductItem?.Images?.[0]?.url ||
              orderItem.ProductItem?.Product?.Images?.[0]?.url ||
              '',
            productName: orderItem.ProductItem?.Product?.name || '',
            productVariants:
              orderItem.ProductItem?.ProductVariants?.map(
                (variant) => variant.name
              ).join(' - ') || '',
            startsAt: DateTime.fromISO(`${orderItem.startsAt}`).toFormat(
              'dd LLL'
            ),
            endsAt: orderItem.endsAt
              ? DateTime.fromISO(`${orderItem.endsAt}`).toFormat('dd LLL')
              : '',
            durationDays: Math.floor(
              DateTime.fromISO(`${orderItem.endsAt}`).diff(
                DateTime.fromISO(`${orderItem.startsAt}`),
                ['days']
              ).days
            ),
          },
        ]
      ) || []
    )
    setProductOrderItemMetadataMap(map)
  }

  const resetOrder = () => {
    setIsLoaded(false)
    setSubtotal(0)
    setTotal(0)
    setAddress('')
    setPaymentDeadline('')
    setProductOrderItemMetadataMap(new Map())
  }

  return (
    <OrderContext.Provider
      value={{
        evalOrder,
        resetOrder,
        isLoaded,
        subtotal,
        total,
        address,
        paymentDeadline,
        productOrderItemMetadataMap,
      }}
    >
      {children}
    </OrderContext.Provider>
  )
}

export function useOrderContext() {
  const context = useContext(OrderContext)
  if (context === undefined) {
    throw new Error('Context must be used within Provider')
  }
  return context
}
