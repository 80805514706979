import { useFocusEffect } from '@react-navigation/native'
import { useCallback, useState } from 'react'
import { FlatList, Image, Pressable, ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import Container from '~/components/Container'
import Text from '~/components/StyledText'
import Button from '~/components/base/Button/Button'
import BackHeader from '~/components/headers/BackHeader'

import { RootStackProps } from '~/navigation/types'

const TermsCondition = ({
  route,
  navigation,
}: RootStackProps<'TermsCondition'>) => {
  const tailwind = useTailwind()
  const params = route.params

  const [state, setState] = useState({
    deliveryPrice: {
      toggled: false,
    },
    profitSharing: {
      toggled: false,
    },
  })

  const [paramsState, setParams] = useState({
    showNext: false,
  })

  const handleExcerpt = (key: keyof typeof state) => {
    setState((prev) => ({
      ...prev,
      [key]: {
        toggled: !prev[key].toggled,
      },
    }))
  }

  useFocusEffect(
    useCallback(() => {
      if (route.params?.showNext || route.params?.showNext === 'true') {
        setParams({
          showNext: true,
        })
      } else {
        setParams({
          showNext: false,
        })
      }
    }, [route.params])
  )

  return (
    <Container>
      <BackHeader
        style={{ backgroundColor: '#fff' }}
        navigation={navigation}
        text={'Syarat & Ketentuan'}
      />

      <ScrollView
        style={[
          tailwind('p-4 pt-20 flex-col h-full relative'),
          {
            height: 'calc(100% - 60px)',
            paddingBottom: 20,
          },
        ]}
        contentContainerStyle={{
          height: '100%',
        }}
      >
        <View
          style={[
            tailwind('relative'),
            {
              height: 'calc(100% - 60px)',
            },
          ]}
        >
          <Image
            source={require('~/assets/images/bioma-logo.png')}
            style={[
              {
                width: 72,
                height: 20,
                resizeMode: 'contain',
              },
              tailwind('mb-4 p-2'),
            ]}
          />

          <Text
            style={[
              tailwind('mb-5 text-3xl font-bold'),
              {
                color: '#00433E',
              },
            ]}
          >
            Syarat & Ketentuan
          </Text>

          <View style={[tailwind('mb-3 relative')]}>
            <Text style={tailwind('mb-2 font-bold text-xl')}>A. SEWA</Text>

            <View style={[tailwind('mb-2')]}>
              <Text style={tailwind('mb-2 font-bold')}>1. Pemesanan</Text>
              <FlatList
                data={[
                  {
                    key: 'a',
                    text: 'Seluruh pemesanan harus dilakukan melalui whatsApp di nomor yang tercantum pada website www.babyloania.com dan Instagram babyloania​.',
                  },
                  {
                    key: 'b',
                    text: 'Pemesanan maksimal 2 hari dari tanggal mulai sewa.',
                  },
                  {
                    key: 'c',
                    text: 'Jika Anda berencana menyewa barang untuk bepergian, silahkan pilih tanggal mulai sewa paling lambat 1 hari sebelum keberangkatan. (Lihat juga Poin 4(c))',
                  },
                ]}
                renderItem={({ item }) => (
                  <Text style={tailwind('mb-1')}>
                    {item.key}. {item.text}
                  </Text>
                )}
              />
            </View>

            <View style={[tailwind('mb-2')]}>
              <Text style={tailwind('mb-2 font-bold')}>2. Biaya Delivery</Text>
              <View>
                <Text>
                  <Text style={{ paddingRight: 4 }}>
                    {state.deliveryPrice.toggled
                      ? READMORE_FULL_TEXT.DELIVERY_PRICE
                      : READMORE_FULL_TEXT.DELIVERY_PRICE.substring(0, 400)}
                  </Text>
                  <Button
                    text={
                      !state.deliveryPrice.toggled
                        ? `Baca Selengkapnya`
                        : `Baca Lebih Sedikit`
                    }
                    style={{
                      backgroundColor: 'transparent',
                      padding: 0,
                    }}
                    onPress={() => handleExcerpt('deliveryPrice')}
                  />
                </Text>
              </View>
            </View>
          </View>

          <View style={[tailwind('mb-3 relative')]}>
            <Text style={tailwind('mb-2 font-bold text-xl')}>
              B. TITIP SEWA
            </Text>

            <View style={[tailwind('mb-2')]}>
              <Text style={tailwind('mb-2 font-bold')}>
                1. Pengajuan kandidat barang
              </Text>
              <FlatList
                data={[
                  {
                    key: 'a',
                    text: 'Pengajuan kandidat barang dilakukan dengan mengirimkan email ke marketplace@babyloania.com dengan Subject [Titip Sewa] serta melampirkan foto barang yang ingin dititipsewakan beserta dengan info nama merek dan tipe barang, harga beli dan kondisi barang.',
                  },
                  {
                    key: 'b',
                    text: 'Babyloania akan mereview barang-barangmu dan memutuskan bisa atau tidaknya barang-barang tersebut untuk ikut serta dalam Program Titip Sewa Babyloania. ',
                  },
                ]}
                renderItem={({ item }) => (
                  <Text style={tailwind('mb-1')}>
                    {item.key}. {item.text}
                  </Text>
                )}
              />
            </View>

            <View style={[tailwind('mb-2')]}>
              <Text style={tailwind('mb-2 font-bold')}>2. Bagi Hasil</Text>
              <FlatList
                data={[
                  {
                    key: 'a',
                    text: 'Ketentuan bagi hasil adalah 45% untuk Anda dan 55% untuk Babyloania.',
                  },
                  {
                    key: 'b',
                    text: 'Bagi hasil akan diakumulasi di bulan berjalan, dari tanggal 26 bulan sebelumnya, hingga tanggal 25 pada bulan tersebut.',
                  },
                  {
                    key: 'c',
                    text: 'Jika tersewa, bagi hasil akan diberikan/ditransfer antara tanggal 12-15 di bulan berikutnya dan laporan akan dikirimkan melalui email antara tanggal 12-15.',
                  },
                ]}
                renderItem={({ item }) => (
                  <Text style={tailwind('mb-1')}>
                    {item.key}. {item.text}{' '}
                    {item.key === 'c' && (
                      <Button
                        text="Baca Selengkapnya"
                        style={{
                          backgroundColor: 'transparent',
                          padding: 0,
                        }}
                      />
                    )}
                  </Text>
                )}
              />
            </View>
          </View>

          {paramsState?.showNext && (
            <View style={[tailwind('w-full sticky bottom-0')]}>
              <Button
                color="primary"
                style={[
                  tailwind(
                    'rounded flex flex-row justify-center items-center text-center'
                  ),
                ]}
                onPress={() => navigation.navigate('Checkout', {})}
                text="Lanjutkan"
              />
            </View>
          )}
        </View>
      </ScrollView>
    </Container>
  )
}

const READMORE_FULL_TEXT = {
  DELIVERY_PRICE:
    'Biaya pengantaran dan pengambilan berlaku untuk pemesanan dengan tanggal mulai sewa dan tanggal akhir sewa yang sama. Jika dalam satu pemesanan terdapat beberapa barang dengan tanggal mulai sewa yang berbeda, pada billing akan kami tambahkan biaya pengantaran sesuai jumlah pengantaran yang perlu dilakukan. Besarnya biaya pengantaran dan pengembalian sama rata untuk wilayah Jakarta, Depok Kota, Tangerang Kota, dan Bekasi Kota.',
  PROFIT_SHARING:
    'Jika tersewa, bagi hasil akan diberikan/ditransfer antara tanggal 12-15 di bulan berikutnya dan laporan akan dikirimkan melalui email antara tanggal 12-15.',
}

export default TermsCondition
