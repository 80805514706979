import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Pressable, ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { getDistricts, getProvinces, getRegencies } from '~/store/province'
import { RootState, useAppDispatch, useAppSelector } from '~/store/store'
import { get, updateAddress } from '~/store/user'

import Container from '~/components/Container'
import SearchResult from '~/components/SearchResult'
import Text from '~/components/StyledText'
import Input from '~/components/base/Input'
import Picker from '~/components/base/Picker'
import Toast, { IRef } from '~/components/base/Toast'
import BackHeader from '~/components/headers/BackHeader'

import { DashboardProps } from '~/navigation/types'

export default function Catalog({
  route,
  navigation,
}: DashboardProps<'Address'>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state: RootState) => state.user)
  const { provinces, regencies, districts } = useAppSelector(
    (state: RootState) => state.province
  )
  const params = route.params

  const toastRef = useRef<IRef>(null)

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
    setValue,
  } = useForm()

  const onSubmit = async (data: any) => {
    delete data.id
    const res = await dispatch(updateAddress({ ...data, zipcode: '11201' }))
    if (updateAddress.fulfilled.match(res)) {
      await dispatch(get())

      toastRef.current?.open('Alamat berhasil di daftarkan')

      if (params?.redirect_from) {
        navigation.navigate(params.redirect_from)
      } else {
        navigation.navigate('Home')
      }
    } else {
      toastRef.current?.open((res?.payload as any).body?.message)
    }
  }

  const watchProvince = watch('province')
  useEffect(() => {
    ;(async () => {
      const provinceName = getValues('province')
      const provinceId = provinces.find((x) => x.name === provinceName)?.id
      if (provinceId) {
        await dispatch(getRegencies({ provinceId }))
      }
    })()
  }, [watchProvince])

  const watchRegency = watch('regency')
  useEffect(() => {
    const provinceName = getValues('province')
    const regencyName = getValues('regency')
    const provinceId = provinces.find((x) => x.name === provinceName)?.id
    const regencyId = regencies.find((x) => x.name === regencyName)?.id
    if (provinceId && regencyId) {
      dispatch(
        getDistricts({
          provinceId,
          regencyId,
        })
      )
    }
  }, [watchProvince, watchRegency])

  useEffect(() => {
    dispatch(getProvinces())
  }, [])

  useEffect(() => {
    if (user && user?.PrimaryAddress) {
      reset(user.PrimaryAddress)
    }
  }, [user])

  return (
    <Container>
      <ScrollView style={tailwind('w-full flex flex-col')}>
        <Toast ref={toastRef} />
        <BackHeader navigation={navigation} text="Alamat" />
        <SearchResult navigation={navigation} />
        <View style={tailwind('px-5 pt-16')}>
          <View style={tailwind('mb-2')}>
            <Text style={tailwind('mb-2')}>Nama Alamat</Text>
            <Input
              style={[
                tailwind('p-4 pr-6 w-full border rounded'),
                { borderColor: '#EAEAEA' },
              ]}
              placeholder={'Nama Alamat'}
              control={control}
              rules={{
                required: 'Nama alamat harus diisi',
              }}
              name="addressName"
              defaultValue=""
              errors={errors}
            />
          </View>
          <Text style={tailwind('mb-2 mt-2 font-bold')}>Detail Alamat</Text>
          <View style={tailwind('mb-2')}>
            <Text style={tailwind('mb-2')}>Provinsi</Text>
            <Picker
              control={control}
              rules={{ required: true }}
              name="province"
              defaultValue=""
              errors={errors}
              options={[
                { label: 'Pilih Provinsi', value: '' },
                ...provinces.map((province) => ({
                  label: province.name,
                  value: province.name,
                })),
              ]}
              enabled={provinces.length !== 0}
            />
          </View>
          <View style={tailwind('mb-2')}>
            <Text style={tailwind('mb-2')}>Kota / Kabupaten</Text>
            <Picker
              control={control}
              rules={{ required: true }}
              name="regency"
              defaultValue=""
              errors={errors}
              options={[
                { label: 'Pilih Kota', value: '' },
                ...regencies.map((regency) => ({
                  label: regency.name,
                  value: regency.name,
                })),
              ]}
              enabled={regencies.length !== 0}
            />
          </View>
          <View style={tailwind('mb-2')}>
            <Text style={tailwind('mb-2')}>Kecamatan</Text>
            <Picker
              control={control}
              rules={{ required: true }}
              name="district"
              defaultValue=""
              errors={errors}
              options={[
                { label: 'Pilih Kecamatan', value: '' },
                ...districts.map((district) => ({
                  label: district.name,
                  value: district.name,
                })),
              ]}
              enabled={districts.length !== 0}
            />
          </View>
          <View style={tailwind('mb-2')}>
            <Text style={tailwind('mb-2')}>Alamat Lengkap</Text>
            <Input
              style={[
                tailwind('p-4 pr-6 w-full border rounded'),
                { borderColor: '#EAEAEA' },
              ]}
              placeholder={'Alamat Lengkap'}
              control={control}
              rules={{
                required: 'Alamat harus diisi',
              }}
              name="address"
              defaultValue=""
              errors={errors}
            />
          </View>
          <View style={tailwind('mb-2')}>
            <Text style={tailwind('mb-2')}>Petunjuk Pengantaran</Text>
            <Input
              style={[
                tailwind('p-4 pr-6 w-full border rounded'),
                { borderColor: '#EAEAEA' },
              ]}
              placeholder={'Petunjuk Pengantaran'}
              control={control}
              rules={{
                required: 'Petunjuk Pengantaran harus diisi',
              }}
              numberOfLines={5}
              name="instructions"
              defaultValue=""
              errors={errors}
            />
          </View>
          <Text style={tailwind('mb-2 mt-2 font-bold')}>Kontak Penerima</Text>
          <View style={tailwind('mb-2')}>
            <Text style={tailwind('mb-2')}>Nama Lengkap</Text>
            <Input
              style={[
                tailwind('p-4 pr-6 w-full border rounded'),
                { borderColor: '#EAEAEA' },
              ]}
              placeholder={'Nama Lengkap'}
              control={control}
              rules={{
                required: 'Nama Lengkap harus diisi',
              }}
              name="recipientName"
              defaultValue=""
              errors={errors}
            />
          </View>
          <View style={tailwind('mb-2')}>
            <Text style={tailwind('mb-2')}>Nomor Telepon</Text>
            <Input
              style={[
                tailwind('p-4 pr-6 w-full border rounded'),
                { borderColor: '#EAEAEA' },
              ]}
              placeholder={'Masukkan Nomor Telepon'}
              control={control}
              rules={{
                required: 'Nomor Telepon harus diisi',
              }}
              name="recipientPhone"
              defaultValue=""
              errors={errors}
            />
          </View>
          <View style={tailwind('my-12')}>
            <Pressable
              style={tailwind('bg-primary p-2 rounded text-white')}
              onPress={handleSubmit(onSubmit)}
            >
              <Text
                style={[
                  tailwind('text-center text-sm'),
                  {
                    color: '#00443E',
                  },
                ]}
              >
                Simpan
              </Text>
            </Pressable>
          </View>
        </View>
      </ScrollView>
    </Container>
  )
}
