import { TransactionAttributesFull } from '@withbioma/apitype-publicapi-public/lib/v1/common/transaction_full'
import { DateTime } from 'luxon'
import React, { useEffect } from 'react'
import { Linking, Pressable, ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { RootState, useAppDispatch, useAppSelector } from '~/store/store'
import { get as getTransaction } from '~/store/transaction'

import Container from '~/components/Container'
import Text from '~/components/StyledText'
import BackHeader from '~/components/headers/BackHeader'
import PaymentChannel from '~/components/transaction_receipt/PaymentChannel'

import { toCurrency } from '~/lib/money'
import { RootStackProps } from '~/navigation/types'
import Copy from '~/svg/Copy'
import PaymentFailed from '~/svg/PaymentFailed'
import PaymentPending from '~/svg/PaymentPending'
import PaymentSuccess from '~/svg/PaymentSuccess'

function TransactionReceipt({
  route,
  navigation,
}: RootStackProps<'TransactionReceipt'>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()

  const { transaction_uuid } = route.params
  const { transaction } = useAppSelector(
    (state: RootState) => state.transaction
  )
  const { authenticated } = useAppSelector((state: RootState) => state.auth)

  const isGuestOrder = authenticated == false

  const { text: statusText, color: statusColor } = parseStatus(
    transaction?.paymentStatus
  )

  useEffect(() => {
    if (transaction_uuid) {
      dispatch(getTransaction(transaction_uuid))
    } else {
      navigation.navigate('Root', { screen: 'Dashboard' })
    }
  }, [transaction_uuid])

  return (
    <Container>
      <BackHeader
        navigation={navigation}
        fallback="Home"
        text="Informasi Transaksi"
        noPrev={isGuestOrder}
        style={{
          backgroundColor: '#fff',
        }}
      />

      <ScrollView
        style={tailwind('pt-14 flex flex-col w-full bg-white')}
        showsVerticalScrollIndicator={false}
      >
        <View
          style={tailwind(
            'flex flex-col items-center justify-center mt-4 mb-8'
          )}
        >
          {transaction?.paymentStatus === 'paid' && <PaymentSuccess />}

          {(transaction?.paymentStatus === 'void' ||
            transaction?.paymentStatus === 'expired') && <PaymentFailed />}

          {transaction?.paymentStatus === 'pending' && <PaymentPending />}
        </View>
        <View style={tailwind('flex flex-row pl-4 mb-3')}>
          <View style={[tailwind('flex flex-col'), { width: 120 }]}>
            <Text style={tailwind('text-sm text-gray-500')}>Order Date</Text>
          </View>
          <View style={[tailwind('flex flex-col pl-4'), { flexGrow: 1 }]}>
            <Text style={tailwind('text-sm')}>
              {' '}
              {DateTime.fromISO(String(transaction?.Order?.createdAt)).toFormat(
                'dd MMM yyyy kk:mm'
              )}
            </Text>
          </View>
        </View>
        <View
          style={tailwind(
            'flex flex-row mx-4 pb-3 border-b-2 border-gray-300 border-dashed'
          )}
        >
          <View style={[tailwind('flex flex-col'), { width: 120 }]}>
            <Text style={tailwind('text-sm text-gray-500')}>No Pemesanan</Text>
          </View>
          <View style={[tailwind('flex flex-col pl-4'), { flexGrow: 1 }]}>
            <Text style={tailwind('text-sm')}>{transaction?.id}</Text>
          </View>
        </View>
        <View style={tailwind('flex flex-row pt-3 pl-4 mb-3')}>
          <View style={[tailwind('flex flex-col'), { width: 120 }]}>
            <Text style={tailwind('text-sm text-gray-500')}>Pemesan</Text>
          </View>
          <View style={[tailwind('flex flex-col pl-4'), { flexGrow: 1 }]}>
            <Text style={tailwind('text-sm')}>
              {transaction?.Order?.Customer?.name}
            </Text>
          </View>
        </View>
        <View style={tailwind('flex flex-row pl-4 mb-3')}>
          <View style={[tailwind('flex flex-col'), { width: 120 }]}>
            <Text style={tailwind('text-sm text-gray-500')}>
              Status Pemesanan
            </Text>
          </View>
          <View style={[tailwind('flex flex-col pl-4'), { flexGrow: 1 }]}>
            <Text
              style={[
                tailwind('text-sm'),
                {
                  color: statusColor,
                },
              ]}
            >
              {statusText}
            </Text>
          </View>
        </View>
        <View style={tailwind('flex flex-row pl-4 mb-3')}>
          <View style={[tailwind('flex flex-col'), { width: 120 }]}>
            <Text style={tailwind('text-sm text-gray-500')}>Pembayaran</Text>
          </View>
          <View style={[tailwind('flex flex-col pl-4'), { flexGrow: 1 }]}>
            <Text style={tailwind('text-sm')}>
              {transaction?.paymentStatus == 'paid'
                ? transaction?.paymentChannel
                : 'Xendit'}
            </Text>
          </View>
        </View>

        {transaction?.paymentStatus !== 'pending' && (
          <View style={tailwind('flex flex-row pl-4 mb-3')}>
            <View style={[tailwind('flex flex-col'), { width: 120 }]}>
              <Text style={tailwind('text-sm text-gray-500')}>Total</Text>
            </View>
            <View style={[tailwind('flex flex-col pl-4'), { flexGrow: 1 }]}>
              <Text style={tailwind('text-sm')}>
                {toCurrency(transaction?.amount) || '-'}
              </Text>
            </View>
          </View>
        )}

        {transaction?.paymentStatus == 'pending' && (
          <View style={tailwind('px-4 pt-5')}>
            <Text style={[tailwind('font-bold text-sm mb-3')]}>
              {/* Pembayaran via Virtual Account */}
              Pembayaran via Xendit
            </Text>

            {/* <PaymentChannel channel='BCA' />

            <View style={tailwind('py-2')}>
              <Text style={tailwind('text-sm')}>
                No Rekening
              </Text>

              <View style={tailwind('flex flex-row items-center')}>
                <Text style={tailwind('text-lg')}>126 0858 6660 8855</Text>
                <Copy style={tailwind('pl-3 mt-1')} />
              </View>
            </View> */}

            <View style={tailwind('flex flex-row justify-between my-3')}>
              <View style={[tailwind('flex flex-col')]}>
                <Text style={tailwind('text-sm text-gray-500')}>
                  Total Bayar
                </Text>
              </View>
              <View style={[tailwind('')]}>
                <Text style={tailwind('text-sm font-bold')}>
                  {toCurrency(transaction?.amount) || '-'}
                </Text>
              </View>
            </View>

            <View style={tailwind('flex flex-row justify-between mt-2 mb-4')}>
              <View style={[tailwind('flex flex-col')]}>
                <Text style={tailwind('text-sm text-gray-500')}>
                  Batas akhir pembayaran
                </Text>
              </View>
              <View style={[tailwind('')]}>
                <Text
                  style={[
                    tailwind('text-sm'),
                    {
                      color: '#C70000',
                    },
                  ]}
                >
                  {DateTime.fromISO(String(transaction?.expiresAt)).toFormat(
                    'dd MMM yyyy kk:mm'
                  )}
                </Text>
              </View>
            </View>

            {/* <View style={tailwind('mb-7')}>
              <Text style={[
                tailwind('font-bold text-sm mt-5 mb-3')]
              }>
                Petunjuk Pembayaran Via ATM
              </Text>

              <Text style={tailwind('text-gray-500 mb-1')}>1. Pilih Transfer</Text>
              <Text style={tailwind('text-gray-500 mb-1')}>2. Pilih Bank Tujuan</Text>
              <Text style={tailwind('text-gray-500 mb-1')}>3. Masukkan rekening bank tujuan</Text>
              <Text style={tailwind('text-gray-500 mb-1')}>
                4. Cek kembali informasi yang tampil pada layar, pastikan total tagihan sudah benar dari BIOMA.
              </Text>
            </View> */}

            <View style={tailwind('mb-7')}>
              <Text style={[tailwind('font-bold text-sm mt-5 mb-3')]}>
                Bayar Melalui Link Dibawah Ini
              </Text>

              <Pressable
                onPress={() => {
                  Linking.openURL(transaction.xenditInvoiceUrl!)
                }}
              >
                <Text style={tailwind('text-blue-500 mb-1')}>
                  {transaction.xenditInvoiceUrl! || '-'}
                </Text>
              </Pressable>
            </View>
          </View>
        )}
      </ScrollView>

      <View
        style={[
          tailwind('p-4 sticky bottom-0 rounded-t'),
          { shadowColor: 'rgba(0,0,0,0.25)', shadowRadius: 8, elevation: 5 },
        ]}
      >
        <View style={tailwind('flex flex-col')}>
          {transaction?.paymentStatus !== 'void' &&
            transaction?.paymentStatus !== 'expired' && (
              <Pressable
                style={[
                  tailwind('py-2 px-5 rounded border text-white mb-2'),
                  {
                    borderColor: '#00443E',
                  },
                ]}
                onPress={() => {
                  navigation.navigate('TransactionDetail', {
                    transaction_uuid,
                  })
                }}
              >
                <Text style={tailwind('text-center text-sm font-bold')}>
                  Detail Transaksi
                </Text>
              </Pressable>
            )}

          {!isGuestOrder && transaction?.paymentStatus != 'void' && (
            <Pressable
              style={tailwind('bg-primary p-2 rounded text-white')}
              onPress={() => {
                navigation.navigate('Root', {
                  screen: 'Dashboard',
                })
              }}
            >
              <Text
                style={[
                  tailwind('text-center text-sm font-bold'),
                  {
                    color: '#00443E',
                  },
                ]}
              >
                Kembali ke Dashboard
              </Text>
            </Pressable>
          )}

          {isGuestOrder && transaction?.paymentStatus == 'void' && (
            <Pressable
              style={[
                tailwind('py-2 px-5 rounded border text-white mb-2'),
                {
                  borderColor: '#00443E',
                },
              ]}
              onPress={() => {
                Linking.openURL('https://wa.me/6281284455345')
              }}
            >
              <Text
                style={[
                  tailwind('text-center text-sm font-bold'),
                  {
                    color: '#00443E',
                  },
                ]}
              >
                Hubungi Tim CS di WhatsApp
              </Text>
            </Pressable>
          )}
        </View>
      </View>
    </Container>
  )
}

type PaymentStatus = TransactionAttributesFull['paymentStatus']

const parseStatus = (status?: PaymentStatus) => {
  let result = {
    text: '',
    color: '',
  }

  switch (status) {
    case 'paid':
      result.text = 'Lunas'
      result.color = '#B2E2DF'
      break
    case 'pending':
      result.text = 'Menunggu Pembayaran'
      result.color = '#E9BB19'
      break
    case 'void':
    case 'expired':
      result.text = 'Gagal Bayar'
      result.color = '#C70000'
      break
    default:
      result.text = 'Missing status'
      result.color = 'transparent'
      break
  }

  return result
}

export default TransactionReceipt
