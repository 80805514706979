import {
  SearchQueryString as PrivateSearchQueryString,
  SearchResponse as PrivateSearchResponse,
} from '@withbioma/apitype-publicapi-customer/lib/v1/search/api'
import {
  SearchQueryString,
  SearchResponse,
} from '@withbioma/apitype-publicapi-public/lib/v1/search/api'

import API from '~/apis/client'

export async function search(params: SearchQueryString) {
  return await API.handle(
    API.public.get<SearchResponse>(`/public/v1/search`, { params })
  )
}

// When the user is logged in, this method should be used instead of the
// public search API above.
export async function privateSearch(params: PrivateSearchQueryString) {
  return await API.handle(
    API.private.get<PrivateSearchResponse>(`/customer/v1/search`, { params })
  )
}
