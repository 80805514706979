/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */
import { LinkingOptions } from '@react-navigation/native'
import * as Linking from 'expo-linking'

import { RootStackParamList } from '~/navigation/types'

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          Dashboard: {
            screens: {
              Home: 'Home',
              Category: 'Category',
              Product: 'Dashboard/Product',
              Products: 'Products',
              Search: 'Search',
              Babyloania: 'Babyloania',
              Collections: 'Collections',
              Collection: 'Collection',
              Address: 'Address',
            },
          },
          Catalogue: {
            screens: {
              Home: 'Catalogue/Home',
              Category: 'Catalogue/Category',
              Categories: 'Catalogue/Categories',
              Product: 'Catalogue/Product',
              Products: 'Catalogue/Products',
              Babyloania: 'Catalogue/Babyloania',
              Collections: 'Catalogue/Collections',
              Collection: 'Catalogue/Collection',
            },
          },
          Order: {
            screens: {
              Ongoing: 'Order/Ongoing',
              History: 'Order/History',
            },
          },
        },
      },
      NotFound: '*',
      Login: 'Login',
      Register: 'Register',
      Cart: 'Cart',
      Waitlist: 'Waitlist',
      WaitlistSuccess: 'WaitlistSuccess',
      RegisterSuccess: 'RegisterSuccess',
      OrderConfirmation: 'OrderConfirmation',
      TransactionReceipt: 'TransactionReceipt',
      TransactionDetail: 'TransactionDetail',
      Checkout: 'Checkout',
      Product: 'Product',
      ForgotPassword: 'ForgotPassword',
      ForgotPasswordSuccess: 'ForgotPasswordSuccess',
      ResetPassword: 'ResetPassword',
      ResetPasswordSuccess: 'ResetPasswordSuccess',
      TermsCondition: 'TermsCondition',
    },
  },
}

export default linking
