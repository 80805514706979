import { useFocusEffect } from '@react-navigation/native'
import { OrderAttributes } from '@withbioma/apitype-publicapi-customer/lib/v1/common/order'
import { OrderAttributesFull } from '@withbioma/apitype-publicapi-public/lib/v1/common/order_full'
import { AxiosResponse } from 'axios'
import { DateTime } from 'luxon'
import React, { useCallback } from 'react'
import { useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { constructErrorPayload, constructSuccessPayload } from '~/store/helper'
import { RootState, useAppSelector } from '~/store/store'

import Container from '~/components/Container'
import ActionSheet from '~/components/order_histories/ActionSheet'
import EmptyStates from '~/components/order_histories/EmptyStates'
import TransactionItemRow from '~/components/order_histories/TransactionItemRow'
import { parseOrderStatus } from '~/components/order_histories/utils'

import { getAll } from '~/apis/order_histories'

enum FilterOptions {
  ALL_TRANSACTIONS = 'Semua Transaksi',
  DONE = 'Selesai',
  CANCELED = 'Dibatalkan',
}

export default function OrderHistory({ navigation }: any) {
  const tailwind = useTailwind()

  const { authenticated } = useAppSelector((state: RootState) => state.auth)

  const [orders, setOrders] = useState<any[]>([])
  const [ordersFetch, setOrdersFetch] = useState<any[]>([])

  const [actionSelected, setActionSelected] = useState<FilterOptions>(
    FilterOptions.ALL_TRANSACTIONS
  )
  const [awaitingPayment, setAwaitingPayment] = useState<number>(NaN)

  const fetchOrder = useCallback(
    async (params?: {
      statuses: any[] // Todo: update api types
    }) => {
      const res = await fetch(getAll(params ? params : {}))
      const orderHistories = res?.body?.orderHistories
      setOrdersFetch(orderHistories)
    },
    []
  )

  const evalOrder = useCallback(() => {
    const mappingOrders = ordersFetch
      ?.flatMap((order: any) => {
        const orderItems = order.OrderItems.filter(
          (item: any) => item.ownerType === 'productItem'
        )
        const OrderItem = orderItems[0] || undefined
        const OtherItems =
          orderItems.filter((val: any) => val.id !== OrderItem.id) || []

        const endsAtMaps = orderItems.map((value: any) =>
          DateTime.fromISO(value.endsAt)
        )
        const latestEndsAt = DateTime.max.apply(undefined, endsAtMaps).toISO()

        return {
          ...order,
          OrderItem,
          OtherItems,
          latestEndsAt,
        }
      })
      .filter((value: any) => value !== undefined)

    const awaitingPaymentOrders = ordersFetch?.filter(
      (val) => val?.status == 'awaiting_payment'
    )

    // :: Mapping data structure to flat
    if (mappingOrders) {
      setOrders(mappingOrders)
    }

    // :: Set number of awaiting payments
    if (awaitingPaymentOrders && awaitingPaymentOrders.length > 0) {
      setAwaitingPayment(awaitingPaymentOrders.length)
    }
  }, [ordersFetch])

  // :: We don't use store for data placement, only using local state
  // :: Cause these pages, have two tabs rendering in the same time
  useFocusEffect(
    useCallback(() => {
      fetchOrder({
        statuses: ['fulfilled'],
      })
    }, [fetchOrder])
  )

  // :: Order filters
  useEffect(() => {
    if (actionSelected == FilterOptions.DONE) {
      fetchOrder({
        statuses: ['fulfilled'],
      })
    } else if (actionSelected == FilterOptions.CANCELED) {
      fetchOrder({
        statuses: ['canceled'],
      })
    } else {
      fetchOrder({
        statuses: ['fulfilled'],
      })
    }
  }, [actionSelected, fetchOrder])

  // :: New data structure for order
  useEffect(() => {
    evalOrder()
  }, [ordersFetch])

  return (
    <Container>
      {authenticated && (
        <ActionSheet
          items={['Semua Transaksi', 'Selesai', 'Dibatalkan']}
          selectedState={actionSelected}
          onSelected={(index: number, state: string) => {
            Object.entries(FilterOptions).forEach(([key, val]) => {
              if (state == val) {
                setActionSelected((FilterOptions as any)[key])
              }
            })
          }}
        />
      )}

      <ScrollView style={tailwind('w-full flex flex-col px-4')}>
        {!authenticated && (
          <EmptyStates
            title="Masuk akun untuk melihat transaksi"
            description="Untuk melihat semua transaksi, kamu harus masuk akun terlebih dahulu"
            btnText="Login"
            source={require('~/assets/images/order_transactions/empty_user.png')}
            onPress={() => {
              navigation.navigate('Login')
            }}
          />
        )}

        {authenticated && !ordersFetch?.length && (
          <EmptyStates
            title="Belum ada transaksi yang diproses"
            description="Pilih produk yang kamu mau dan sewa di Bioma"
            btnText="Cari Produk"
            source={require('~/assets/images/order_transactions/empty_order.png')}
            onPress={() => {
              navigation.navigate('Catalogue', {
                screen: 'Home',
              })
            }}
          />
        )}

        <View style={tailwind('pt-7')}>
          {authenticated && orders.length > 0 && (
            <React.Fragment>
              {orders.map((order, idx) => {
                const len = orders && orders?.length > 1 ? orders?.length : 0
                const isFirstIndex = idx == 0
                const isLastIndex = len > 0 ? len - 1 == idx : false

                return (
                  <TransactionItemRow
                    key={idx}
                    source={order}
                    isFirstIndex={isFirstIndex}
                    isLastIndex={isLastIndex}
                    onPress={(uuid) => {}}
                    orderStatus={parseOrderStatus(order.status)}
                  />
                )
              })}
            </React.Fragment>
          )}
        </View>
      </ScrollView>
    </Container>
  )
}

const fetch = async (
  task: Promise<[AxiosResponse<any, any> | undefined, any]>
) => {
  const [res, resErr] = await task
  if (resErr) {
    throw constructErrorPayload(resErr)
  }
  return res && constructSuccessPayload(res)
}
