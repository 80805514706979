import { useEffect } from 'react'
import { Pressable, ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { getAll } from '~/store/productType'
import { useAppDispatch } from '~/store/store'

import Container from '~/components/Container'
import { SearchContextProvider } from '~/components/SearchContext'
import SearchResult from '~/components/SearchResult'
import Text from '~/components/StyledText'
import Categories from '~/components/category/Categories'
import CategoriesBabyloania from '~/components/category/CategoriesBabyloania'
import ContactBioma from '~/components/contacts/ContactBioma'
import AppHeader from '~/components/headers/AppHeader'
import CollectionBanner from '~/components/home/CollectionBanner'

import { BottomTabProps } from '~/navigation/types'

export default function Catalog({ navigation }: BottomTabProps<'Catalogue'>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getAll())
  }, [])

  return (
    <SearchContextProvider>
      <Container>
        <ScrollView style={tailwind('w-full flex flex-col')}>
          <AppHeader navigation={navigation} />
          <SearchResult navigation={navigation} />
          <View style={tailwind('mt-4')}>
            <View
              style={tailwind(
                'flex flex-row items-center justify-between mb-4'
              )}
            >
              <Text style={tailwind('text-base font-bold px-4')}>
                🎉 Koleksi Pilihan Bioma
              </Text>
              <Pressable
                onPress={() =>
                  navigation.navigate('Catalogue', {
                    screen: 'Collections',
                  })
                }
              >
                <Text style={tailwind('text-teal px-4')}>Lihat Semua</Text>
              </Pressable>
            </View>
            <CollectionBanner navigation={navigation} />
            <Categories navigation={navigation} />
            <CategoriesBabyloania navigation={navigation} />
            <ContactBioma navigation={navigation} />
          </View>
        </ScrollView>
      </Container>
    </SearchContextProvider>
  )
}
