import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const Cart = (props: any) => (
  <Svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M9.23998 6.8698H23.52C23.52 6.8698 23.52 16.4271 18.76 16.4271H11.62C6.85998 16.4271 6.85998 11.6485 6.85998 6.8698C6.85998 4.48047 4.47998 4.48047 4.47998 4.48047"
      stroke="#1A443E"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M11.5734 23.5199C12.3982 23.5199 13.0667 22.8513 13.0667 22.0265C13.0667 21.2018 12.3982 20.5332 11.5734 20.5332C10.7487 20.5332 10.0801 21.2018 10.0801 22.0265C10.0801 22.8513 10.7487 23.5199 11.5734 23.5199Z"
      stroke="#1A443E"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M17.9201 23.5199C18.7448 23.5199 19.4134 22.8513 19.4134 22.0265C19.4134 21.2018 18.7448 20.5332 17.9201 20.5332C17.0953 20.5332 16.4268 21.2018 16.4268 22.0265C16.4268 22.8513 17.0953 23.5199 17.9201 23.5199Z"
      stroke="#1A443E"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M18.8676 16.4268H9.44813C9.44813 16.4268 7.09326 16.4268 7.09326 17.5468C7.09326 18.6668 9.44813 18.6668 9.44813 18.6668H22.3999"
      stroke="#1A443E"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default Cart
