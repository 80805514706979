import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const ChatDots = (props: any) => (
  <Svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M13.3333 3.33398H2.66667C2.29848 3.33398 2 3.63246 2 4.00065V12.6136C2 13.1726 2.64662 13.4834 3.08313 13.1341L5.15072 11.4801C5.26893 11.3855 5.41581 11.334 5.56719 11.334H13.3333C13.7015 11.334 14 11.0355 14 10.6673V4.00065C14 3.63246 13.7015 3.33398 13.3333 3.33398Z"
      stroke="#333333"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    <Path
      d="M10.667 7.33398H10.6683V7.33532H10.667V7.33398Z"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8 7.33398H8.00133V7.33532H8V7.33398Z"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M5.33301 7.33398H5.33434V7.33532H5.33301V7.33398Z"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default ChatDots
