import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import {
  Animated,
  Dimensions,
  Easing,
  GestureResponderEvent,
  Pressable,
  SafeAreaView,
  TouchableOpacity,
  View,
} from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import Text from '~/components/StyledText'

import Close from '~/svg/Close'

type GestureEvent = (event: GestureResponderEvent) => void

type IProps = {
  onPress?: GestureEvent
  onClose?: () => void
  cta?: string
  color?: string
}

export type IRef = { open: (text: string) => void }

const CartSuccess = forwardRef<IRef, IProps>(
  ({ onPress, onClose, cta, color }, ref) => {
    const tailwind = useTailwind()

    const [visible, setVisible] = useState(false)
    const [text, setText] = useState<string | undefined>()

    useImperativeHandle(ref, () => ({
      open: (text: string) => {
        setVisible(true)
        setText(text)
      },
    }))

    const animatedValue = useRef(new Animated.Value(0)).current

    const startAnimation = useCallback((value: number, func?: Function) => {
      Animated.timing(animatedValue, {
        toValue: value,
        duration: 500,
        useNativeDriver: true,
      }).start(({ finished }) => {
        if (finished && func && typeof func == 'function') {
          func()
        }
      })
    }, [])

    // useEffect(() => {
    //   const timeoutDuration = 2000

    //   if (visible) {
    //     startAnimation(1, () => {
    //       setTimeout(
    //         () =>
    //           startAnimation(0, () => {
    //             setVisible(false)
    //             if (onClose) onClose()
    //           }),
    //         timeoutDuration
    //       )
    //     })
    //   } else {
    //     startAnimation(0)
    //   }
    // }, [visible, onClose])

    const transformStyles = () => {
      return [
        {
          translateY: animatedValue.interpolate({
            inputRange: [0, 1],
            outputRange: [300, 0],
          }),
        },
        { perspective: 1000 },
        { translateX: '-50%' },
      ]
    }

    let bgColor = tailwind('bg-primary')
    let textColor = tailwind('text-primary-200')

    if (color === 'danger') {
      bgColor = tailwind('bg-red-500')
      textColor = tailwind('text-white')
    }

    useEffect(() => {
      const timeoutDuration = 2000

      if (visible) {
        setTimeout(() => {
          setVisible(false)
          if (onClose) onClose()
        }, timeoutDuration)
      } else {
        startAnimation(0)
      }
    }, [visible, onClose])

    if (!visible) return <React.Fragment />

    return (
      <View
        style={[
          tailwind(
            'flex flex-row absolute bottom-4 left-0 bg-primary p-2 rounded w-full justify-between'
          ),
          bgColor,
        ]}
      >
        <TouchableOpacity activeOpacity={1} onPress={onPress}>
          <Text style={[tailwind('text-sm break-words'), textColor]}>
            {text}
          </Text>
          {cta && (
            <Text style={[tailwind('pt-1'), { color: '#00443E' }]}>{cta}</Text>
          )}
        </TouchableOpacity>

        <Pressable
          onPress={() => {
            setVisible(false)
            if (onClose) onClose()
          }}
        >
          <Close />
        </Pressable>
      </View>
    )
  }
)

export default CartSuccess
