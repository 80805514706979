import { useFocusEffect } from '@react-navigation/native'
import { useCallback, useEffect, useState } from 'react'
import {
  Image,
  Linking,
  Pressable,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { initiateResetPassword } from '~/store/resetPassword'
import { RootState, useAppDispatch, useAppSelector } from '~/store/store'

import Container from '~/components/Container'
import Text from '~/components/StyledText'
import Button from '~/components/base/Button/Button'
import Input from '~/components/base/Input'
import BackHeader from '~/components/headers/BackHeader'

import useCountdownTimer from '~/hooks/useCountdownTimer'
import { RootStackProps } from '~/navigation/types'

export default function ForgotPasswordSuccess({
  route,
  navigation,
}: RootStackProps<'ForgotPasswordSuccess'>) {
  const tailwind = useTailwind()
  const params = route.params

  const dispatch = useAppDispatch()

  const [timer, startCountdown, isTimerStart] = useCountdownTimer(undefined, 60)

  const onSendEmailVerification = async () => {
    startCountdown(true)

    const res = await dispatch(
      initiateResetPassword({
        email: params.email,
      })
    )

    if (initiateResetPassword.fulfilled.match(res)) {
      navigation.navigate('ForgotPasswordSuccess', {
        email: params.email,
      })

      startCountdown(false)
    }
  }

  // Make sure, pass email params
  useFocusEffect(
    useCallback(() => {
      startCountdown(true)

      if (!params.email) {
        navigation.navigate('Login')
      }
    }, [params.email])
  )

  return (
    <Container>
      <BackHeader
        navigation={navigation}
        text={'Lupa Password'}
        overidePrev={() => navigation.navigate('ForgotPassword')}
      />

      <ScrollView
        style={[
          tailwind('pt-14 flex flex-col w-full relative'),
          {
            height: 'calc(100% - 60px)',
            paddingBottom: 20,
          },
        ]}
        contentContainerStyle={{
          height: '100%',
        }}
      >
        <View
          style={[
            tailwind('pt-6 px-4 relative'),
            {
              height: 'calc(100% - 60px)',
            },
          ]}
        >
          <Image
            source={require('~/assets/images/bioma-logo.png')}
            style={[
              {
                width: 72,
                height: 20,
                resizeMode: 'contain',
              },
              tailwind('mb-4 p-2'),
            ]}
          />
          <Text
            style={[
              tailwind('mb-5 text-3xl font-bold'),
              {
                color: '#00433E',
              },
            ]}
          >
            Email Terkirim
          </Text>

          <Text style={tailwind('text-base mb-5')}>
            Kami telah mengirimkan email kepada alamat {params.email}
          </Text>

          <Text style={tailwind('text-base mb-5')}>
            Silahkan ikuti langkah dengan mengunjungi link yang diberikan
            melalui email untuk melanjutkan proses reset password.
          </Text>

          <View style={tailwind('mt-3')}>
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => navigation.navigate('ForgotPassword')}
              style={tailwind('pb-3')}
            >
              <Text>Bukan Email Saya?</Text>
            </TouchableOpacity>

            <TouchableOpacity
              activeOpacity={1}
              onPress={onSendEmailVerification}
              disabled={isTimerStart}
              style={tailwind('pb-3')}
            >
              <Text style={tailwind(isTimerStart ? 'text-gray-300' : '')}>
                Kirim ulang email verifikasi{' '}
                <Text
                  style={tailwind(
                    isTimerStart ? 'text-gray-300' : 'text-teal-500'
                  )}
                >
                  ({timer})
                </Text>
              </Text>
            </TouchableOpacity>
          </View>
        </View>

        <View style={[tailwind('sticky bottom-1.5 w-full px-4')]}>
          <Button
            color="primary"
            text="Kembali Login"
            style={tailwind('text-center py-3')}
            onPress={() => navigation.navigate('Login')}
          />
        </View>
      </ScrollView>
    </Container>
  )
}
