import { AntDesign } from '@expo/vector-icons'
import { useMemo } from 'react'
import { Pressable, ScrollView } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { useSearchContext } from '~/components/SearchContext'
import Text from '~/components/StyledText'

const SearchResult = ({ navigation }: { navigation: any }) => {
  const tailwind = useTailwind()

  const { resetSearch, searchTerm, isTyping, loading, products, productTypes } =
    useSearchContext()

  const productTypesFull = useMemo(() => {
    const productTypesMap = new Map(productTypes.map((val) => [val.id, val]))

    const productTypeProducts = products.flatMap(
      (product) => product.ProductTypes
    )

    if (productTypeProducts.length) {
      productTypeProducts.forEach((val) => {
        productTypesMap.set(val.id, val)
      })
    }

    return [...productTypesMap.values()]
  }, [productTypes, products])

  if (!searchTerm) {
    return <></>
  }

  return (
    <ScrollView
      style={[
        tailwind('flex flex-col bg-white absolute p-4'),
        { height: '100%', width: '100%', top: 55, zIndex: 1000 },
      ]}
    >
      <Text style={tailwind('mb-4 font-bold')}>Kategori</Text>
      {!isTyping &&
        productTypesFull.map((productType, i) => (
          <Pressable
            key={i}
            style={tailwind('mb-4 flex-row items-center')}
            onPress={() => {
              navigation.push('Category', {
                product_type_id: productType.id,
              })
              resetSearch()
            }}
          >
            <AntDesign
              name="search1"
              size={20}
              style={tailwind('text-gray-500 mr-2')}
            />
            <Text>{productType.name}</Text>
          </Pressable>
        ))}
      {!isTyping && productTypesFull.length === 0 && (
        <Text style={tailwind('mb-4 text-gray-500')}>Tidak ada kategori</Text>
      )}
      {isTyping && <Text style={tailwind('mb-4 text-gray-500')}>...</Text>}
      <Text style={tailwind('mb-4 font-bold')}>Produk</Text>
      {!isTyping &&
        products.map((product, i) => (
          <Pressable
            key={i}
            style={tailwind('mb-4 flex-row items-center')}
            onPress={() => {
              navigation.push('Product', { product_id: product.id })
              resetSearch()
            }}
          >
            <AntDesign
              name="search1"
              size={20}
              style={tailwind('text-gray-500 mr-2')}
            />
            <Text>
              {product.brand} - {product.name}
            </Text>
          </Pressable>
        ))}
      {!isTyping && products.length === 0 && (
        <Text style={tailwind('mb-4 text-gray-500')}>Tidak ada produk</Text>
      )}
      {isTyping && <Text style={tailwind('mb-4 text-gray-500')}>...</Text>}
    </ScrollView>
  )
}

export default SearchResult
