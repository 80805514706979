import { Linking, Pressable } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import Image from '~/components/base/Image'

interface IProps {
  navigation: any
  isProductPage?: boolean
}

const ContactBioma: React.FC<IProps> = ({ navigation, isProductPage }) => {
  const tailwind = useTailwind()

  const handlePress = () => {
    // navigation.navigate('WaitlistInfo')
    Linking.openURL('https://bioma.id/consultation')
  }

  return (
    <Pressable
      style={tailwind(isProductPage ? '' : 'mx-4')}
      onPress={handlePress}
    >
      <Image
        rounded
        source={require('~/assets/images/home/artboard-cs.jpg')}
        height={170}
        imageStyles={[{ aspectRatio: 1 }]}
      />
    </Pressable>
  )
}

export default ContactBioma
