import { Pressable, View } from 'react-native'
import { ArrowLeftIcon } from 'react-native-heroicons/solid'
import { useTailwind } from 'tailwind-rn'

import Text from '~/components/StyledText'

const BackHeader = ({
  navigation,
  text,
  fallback = 'Home',
  style,
  noPrev = false,
  overidePrev,
}: {
  navigation: any
  text: string
  fallback?: string
  style?: any
  noPrev?: boolean
  overidePrev?: any | undefined
}) => {
  const tailwind = useTailwind()
  return (
    <View
      style={[
        tailwind('absolute w-full'),
        {
          top: 0,
          left: 0,
          zIndex: 50,
        },
      ]}
    >
      <Pressable
        onPress={() => {
          if (overidePrev) {
            overidePrev()
            return
          }
          if (!noPrev) {
            navigation.canGoBack()
              ? navigation.goBack()
              : navigation.navigate(fallback)
          }
        }}
      >
        <View style={[tailwind('flex flex-row p-4 items-center'), style]}>
          {!noPrev && <ArrowLeftIcon color={style?.color} />}

          <Text
            style={[
              tailwind(`text-lg ${!noPrev ? 'ml-2' : ''}`),
              {
                color: style?.color,
              },
            ]}
          >
            {text}
          </Text>
        </View>
      </Pressable>
    </View>
  )
}

export default BackHeader
