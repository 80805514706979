import { useFocusEffect } from '@react-navigation/native'
import { useCallback, useEffect } from 'react'
import { ControllerProps, useForm } from 'react-hook-form'
import { Image, Pressable, ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import { RootState, useAppDispatch, useAppSelector } from '~/store/store'
import { create, resetGroup } from '~/store/waitlist'

import Container from '~/components/Container'
import Text from '~/components/StyledText'
import Input from '~/components/base/Input'
import BackHeader from '~/components/headers/BackHeader'

import { Regex } from '~/lib/pattern'
import { RootStackProps } from '~/navigation/types'

const Waitlist = ({ navigation }: RootStackProps<'Waitlist'>) => {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()

  const { group } = useAppSelector((state: RootState) => state.waitlist)

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  })

  const isError = Object.keys(errors)?.length > 0

  const onSubmit = async (data: any) => {
    if (!isError) {
      const payload = {
        ...group,
        ...data,
      }
      const res = await dispatch(create(payload))

      if (create.fulfilled.match(res)) {
        navigation.navigate('WaitlistSuccess')
      }
    }
  }

  // :: Triggering input (on init), to get error state
  useEffect(() => {
    trigger(undefined, {
      shouldFocus: true,
    })
  }, [trigger])

  useFocusEffect(
    useCallback(() => {
      return () => {
        dispatch(resetGroup())
      }
    }, [])
  )

  return (
    <Container>
      <BackHeader
        navigation={navigation}
        fallback="Home"
        text="Gabung Antrian"
        style={{
          backgroundColor: '#00443E',
          color: '#fff',
        }}
      />

      <ScrollView
        style={[
          tailwind('pt-14 flex flex-col w-full relative'),
          {
            backgroundColor: '#00443E',
            height: 'calc(100% - 60px)',
            paddingBottom: 20,
          },
        ]}
        contentContainerStyle={{
          height: '100%',
        }}
      >
        <View
          style={[
            tailwind('pt-6 px-4 relative'),
            {
              height: 'calc(100% - 60px)',
            },
          ]}
        >
          <Text style={tailwind('text-2xl mb-2 text-white font-bold')}>
            Barang yang ingin Anda sewa tidak tersedia?
          </Text>
          <Text style={tailwind('text-lg mb-6 text-white')}>
            Yuk, masuk waiting list sekarang! Tim Bioma akan menghubungi ketika
            barang siap untuk disewa.
          </Text>

          <View style={tailwind('mb-4')}>
            <Text style={tailwind('mb-2 text-white')}>Nama</Text>
            <Input
              style={[
                tailwind('p-4 pr-6 w-full bg-white rounded'),
                { borderColor: '#EAEAEA' },
              ]}
              placeholder={'Nama Lengkap'}
              control={control}
              rules={{
                maxLength: 100,
                required: true,
              }}
              name="name"
              defaultValue=""
              errors={errors}
            />
          </View>

          <View style={tailwind('mb-4')}>
            <Text style={tailwind('mb-2 text-white')}>Email</Text>
            <Input
              style={[
                tailwind('p-4 pr-6 w-full bg-white border rounded'),
                { borderColor: '#EAEAEA' },
              ]}
              placeholder={'Alamat Email'}
              control={control}
              rules={
                {
                  maxLength: 100,
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  },
                } as Partial<NonNullable<ControllerProps['rules']>>
              }
              name="email"
              defaultValue=""
              errors={errors}
            />
          </View>

          <View style={tailwind('mb-2')}>
            <Text style={tailwind('mb-2 text-white')}>No. HP: </Text>
            <Input
              style={[
                tailwind('p-4 pr-6 w-full bg-white border rounded'),
                { borderColor: '#EAEAEA' },
              ]}
              placeholder={'Nomor Handphone'}
              control={control}
              rules={
                {
                  required: true,
                  maxLength: 14,
                  pattern: Regex.phoneID,
                } as Partial<NonNullable<ControllerProps['rules']>>
              }
              name="phone"
              keyboardType="phone-pad"
              defaultValue=""
              errors={errors}
            />
          </View>

          {/* :: Background Image*/}
          <View
            style={[
              tailwind('absolute'),
              {
                bottom: '15%',
                left: '50%',
                width: '100%',
                transform: 'translateX(-50%)',
                zIndex: -1,
              },
            ]}
          >
            <Image
              source={require('~/assets/images/waitlist/people.png')}
              style={[
                tailwind('rounded-lg'),
                {
                  aspectRatio: 2,
                  height: 264,
                  resizeMode: 'center',
                },
              ]}
            />
          </View>

          <View
            style={[
              tailwind('absolute'),
              {
                width: '100%',
                bottom: '11%',
                left: 0,
                right: 0,
                zIndex: -2,
              },
            ]}
          >
            <Image
              source={require('~/assets/images/waitlist/sg3.png')}
              style={[
                {
                  aspectRatio: 3,
                  height: 305,
                  resizeMode: 'cover',
                },
              ]}
            />
          </View>

          <View style={[tailwind(' w-full mt-8')]}>
            <Pressable
              disabled={isError}
              style={[
                tailwind(
                  'rounded flex flex-row justify-center items-center text-center'
                ),
                {
                  backgroundColor: isError ? '#C6D9D8' : '#B2E2DF',
                  height: 55,
                },
              ]}
              onPress={handleSubmit(onSubmit)}
            >
              <Text
                style={[
                  tailwind(''),
                  {
                    color: isError ? '#7AA29F' : '#00443E',
                  },
                ]}
              >
                Gabung Antrian
              </Text>
            </Pressable>
          </View>
        </View>
      </ScrollView>
    </Container>
  )
}

export default Waitlist
