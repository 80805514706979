import { useFocusEffect } from '@react-navigation/native'
import { OrderAttributes } from '@withbioma/apitype-publicapi-customer/lib/v1/common/order'
import { AxiosResponse } from 'axios'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { constructErrorPayload, constructSuccessPayload } from '~/store/helper'
import { RootState, useAppSelector } from '~/store/store'

import Container from '~/components/Container'
import ActionSheet from '~/components/order_histories/ActionSheet'
import Alert from '~/components/order_histories/Alert'
import EmptyStates from '~/components/order_histories/EmptyStates'
import TransactionItemRow from '~/components/order_histories/TransactionItemRow'
import { parseOrderStatus } from '~/components/order_histories/utils'

import { getAll } from '~/apis/order_histories'

enum FilterOptions {
  ALL_TRANSACTIONS = 'Semua Transaksi',
  AWAITING_PAYMENT = 'Menunggu Pembayaran',
  AWAITING_PROCESS = 'Menunggu Diproses',
  ACTIVE_ORDER = 'Pesanan Aktif',
  ORDER_PROCESSED = 'Pesanan Diproses',
  ORDER_SENT = 'Pesanan Dikirim',
  RETURN_PROCESS = 'Proses Pengembalian',
}

function OrderOngoing({ navigation }: any) {
  const tailwind = useTailwind()

  const { authenticated } = useAppSelector((state: RootState) => state.auth)

  const [orders, setOrders] = useState<any[]>([])
  const [ordersFetch, setOrdersFetch] = useState<any[]>([])

  const [actionSelected, setActionSelected] = useState<FilterOptions>(
    FilterOptions.ALL_TRANSACTIONS
  )
  const [awaitingPayment, setAwaitingPayment] = useState<number>(NaN)
  const [awaitingPaymentPoint, setAwaitingPaymentPoint] = useState(false)

  const [isOpen, toggleIsOpen] = useReducer(
    (state: boolean, action: 'OPEN' | 'CLOSE') => {
      switch (action) {
        case 'OPEN':
          return true
        case 'CLOSE':
          return false
        default:
          return state
      }
    },
    false
  )

  const fetchOrder = useCallback(
    async (params?: {
      statuses: any[] // Todo: update api types
    }) => {
      const res = await fetch(getAll(params ? params : {}))
      const orderHistories = res?.body?.orderHistories
      setOrdersFetch(orderHistories)
    },
    []
  )

  const evalOrder = useCallback(() => {
    const mappingOrders = ordersFetch
      ?.flatMap((order: any) => {
        const orderItems = order.OrderItems.filter(
          (item: any) => item.ownerType === 'productItem'
        )
        const OrderItem = orderItems[0] || undefined
        const OtherItems =
          orderItems.filter((val: any) => val.id !== OrderItem.id) || []

        let latestEndsAt = ''
        const endsAtMaps = orderItems.map((value: any) => {
          if (value.endsAt) {
            return DateTime.fromISO(value.endsAt)
          }
        })
        if (
          endsAtMaps.length > 0 &&
          !Array.from(endsAtMaps).includes(undefined)
        ) {
          latestEndsAt = DateTime.max.apply(undefined, endsAtMaps).toISO()
        }

        return {
          ...order,
          OrderItem,
          OtherItems,
          latestEndsAt,
        }
      })
      .filter((value: any) => value !== undefined)

    const awaitingPaymentOrders = ordersFetch?.filter(
      (val) => val?.status == 'awaiting_payment'
    )

    // :: Mapping data structure to flat
    if (mappingOrders) {
      setOrders(mappingOrders)
    }

    // :: Set number of awaiting payments
    if (awaitingPaymentOrders && awaitingPaymentOrders.length > 0) {
      setAwaitingPayment(awaitingPaymentOrders.length)
    }
  }, [ordersFetch])

  // :: We don't use store for data placement, only using local state
  // :: Cause these pages, have two tabs rendering in the same time
  useFocusEffect(
    useCallback(() => {
      fetchOrder()
    }, [fetchOrder])
  )

  // :: Order filters
  useEffect(() => {
    if (actionSelected == FilterOptions.AWAITING_PAYMENT) {
      fetchOrder({
        statuses: ['awaiting_payment'],
      })
    } else if (actionSelected == FilterOptions.AWAITING_PROCESS) {
      fetchOrder({
        statuses: ['awaiting_process'],
      })
    } else if (actionSelected == FilterOptions.ORDER_PROCESSED) {
      fetchOrder({
        statuses: ['order_processed'],
      })
    } else if (actionSelected == FilterOptions.ORDER_SENT) {
      fetchOrder({
        statuses: ['order_sent'],
      })
    } else if (actionSelected == FilterOptions.ACTIVE_ORDER) {
      fetchOrder({
        statuses: ['active'],
      })
    } else if (actionSelected == FilterOptions.RETURN_PROCESS) {
      fetchOrder({
        statuses: ['return_process'],
      })
    } else {
      fetchOrder()
    }
  }, [actionSelected, fetchOrder])

  // :: New data structure for order
  useEffect(() => {
    evalOrder()
  }, [ordersFetch])

  useEffect(() => {
    if (awaitingPaymentPoint) {
      setActionSelected(FilterOptions.AWAITING_PAYMENT)
    } else {
      // Reset reducer
      toggleIsOpen('CLOSE')
      setActionSelected(FilterOptions.ALL_TRANSACTIONS)
    }
  }, [awaitingPaymentPoint])

  return (
    <Container>
      {authenticated && (
        <ActionSheet
          items={[
            'Semua Transaksi',
            'Menunggu Pembayaran',
            'Menunggu Diproses',
            'Pesanan Aktif',
            'Pesanan Diproses',
            'Pesanan Dikirim',
            'Proses Pengembalian',
          ]}
          selectedState={actionSelected}
          onSelected={(_index: number, state: string) => {
            // Reset point
            setAwaitingPaymentPoint(false)

            Object.entries(FilterOptions).forEach(([key, val]) => {
              if (state == val) {
                setActionSelected((FilterOptions as any)[key])
              }
            })
          }}
        />
      )}

      <ScrollView style={tailwind('w-full flex flex-col px-4')}>
        {!authenticated && (
          <EmptyStates
            title="Masuk akun untuk melihat transaksi"
            description="Untuk melihat semua transaksi, kamu harus masuk akun terlebih dahulu"
            btnText="Login"
            source={require('~/assets/images/order_transactions/empty_user.png')}
            onPress={() => {
              navigation.navigate('Login')
            }}
          />
        )}

        {authenticated && !ordersFetch?.length && (
          <EmptyStates
            title="Belum ada transaksi yang diproses"
            description="Pilih produk yang kamu mau dan sewa di Bioma"
            btnText="Cari Produk"
            source={require('~/assets/images/order_transactions/empty_order.png')}
            onPress={() => {
              navigation.navigate('Catalogue', {
                screen: 'Home',
              })
            }}
          />
        )}

        {authenticated && orders.length > 0 && (
          <React.Fragment>
            {!isNaN(awaitingPayment) && (
              <Alert
                total={awaitingPayment}
                onPress={() => {
                  setAwaitingPaymentPoint((state) => !state)
                  fetchOrder({
                    statuses: ['awaiting_payment'],
                  })
                }}
                openReducer={[isOpen, toggleIsOpen]}
              />
            )}

            <View style={tailwind('pt-7')}>
              {orders.map((order, idx) => {
                const len = orders && orders?.length > 1 ? orders?.length : 0
                const isFirstIndex = idx == 0
                const isLastIndex = len > 0 ? len - 1 == idx : false

                return (
                  <TransactionItemRow
                    key={idx}
                    source={order}
                    isFirstIndex={isFirstIndex}
                    isLastIndex={isLastIndex}
                    onPress={(uuid) => {
                      navigation.navigate('TransactionDetail', {
                        transaction_uuid: uuid,
                      })
                    }}
                    orderStatus={parseOrderStatus(order.status)}
                  />
                )
              })}
            </View>
          </React.Fragment>
        )}
      </ScrollView>
    </Container>
  )
}

export default OrderOngoing

const fetch = async (
  task: Promise<[AxiosResponse<any, any> | undefined, any]>
) => {
  const [res, resErr] = await task
  if (resErr) {
    throw constructErrorPayload(resErr)
  }
  return res && constructSuccessPayload(res)
}
