import { useEffect, useRef, useState } from 'react'

type Return = [
  number, // interval result
  (state: boolean) => void, // Start / Reset countdown function
  boolean // Check countdown still running
]

const useCountdownTimer = (
  refreshCycle = 1000, // re-renders every `refreshCycle` milliseconds (default: 1000ms)
  interval = 180
): Return => {
  const [isTriggered, setTrigger] = useState(false)
  const [verificationTimerState, setTime] = useState(interval)

  useEffect(() => {
    let intervalId: number | null = null

    if (isTriggered) {
      let step = verificationTimerState
      intervalId = setInterval(() => {
        setTime((state) => {
          step = state

          // This countdown will queue a re-render every 1000ms.
          return state - 1
        })
      }, refreshCycle)

      if (step < 1) {
        setTime(interval)
        setTrigger(false)
        clearInterval(intervalId)
      }
    } else {
      setTime(interval)

      if (intervalId) clearInterval(intervalId)
    }

    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  })

  return [verificationTimerState, setTrigger, isTriggered]
}

export default useCountdownTimer
