import { zodResolver } from '@hookform/resolvers/zod'
import { LoginRequestSchema } from '@withbioma/apitype-publicapi-public/lib/v1/api'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Image, Pressable, TouchableOpacity, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { login } from '~/store/auth'
import { useAppDispatch } from '~/store/store'
import { get } from '~/store/user'

import Container from '~/components/Container'
import Text from '~/components/StyledText'
import Input from '~/components/base/Input'
import InputPassword from '~/components/base/InputPassword'
import Toast, { IRef } from '~/components/base/Toast'
import BackHeader from '~/components/headers/BackHeader'

import { RootStackProps } from '~/navigation/types'

export default function Login({ route, navigation }: RootStackProps<'Login'>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()
  const params = route.params

  const toastRef = useRef<IRef>(null)
  const [passVisibleState, setVisiblePass] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(LoginRequestSchema) })

  console.log(errors)

  const onSubmit = async (data: any) => {
    const res = await dispatch(login(data))
    if (login.fulfilled.match(res)) {
      await dispatch(get())

      if (params?.redirect_from) {
        navigation.navigate(params.redirect_from, {
          [params?.param_name]: params?.param_value,
        })
      } else {
        navigation.navigate('Root', { screen: 'Dashboard' })
      }
    } else {
      toastRef.current?.open('Email atau Password salah')
    }
  }

  return (
    <Container bgColor="#D7DDAE">
      <BackHeader navigation={navigation} text={'Kembali'} />

      <View
        style={[
          tailwind('absolute bottom-0 p-5 pt-7 pb-10 flex-col bg-white'),
          {
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            minHeight: '55%',
          },
        ]}
      >
        <View
          style={[
            tailwind('absolute w-full'),
            {
              top: '-49%',
              left: '50%',
              transform: 'translateX(-50%)',
            },
          ]}
        >
          <Image
            source={require('~/assets/images/login/background.png')}
            style={[
              tailwind('p-2'),
              {
                height: 264,
                resizeMode: 'contain',
              },
            ]}
          />

          <View
            style={[
              tailwind('absolute'),
              {
                width: '100%',
                top: '-70%',
                left: 0,
                right: 0,
                zIndex: -2,
              },
            ]}
          >
            <Image
              source={require('~/assets/images/login/pattern.png')}
              style={[
                {
                  aspectRatio: 1,
                  resizeMode: 'contain',
                },
              ]}
            />
          </View>
        </View>

        <Text
          style={[
            tailwind('mb-2 text-2xl font-bold'),
            {
              color: '#00433E',
            },
          ]}
        >
          Selamat Datang
        </Text>

        <Text style={tailwind('mb-4 text-gray-500')}>
          Silahkan masuk atau lakukan daftar jika belum memiliki akun Bioma
        </Text>

        <View style={tailwind('mb-2')}>
          <Text style={tailwind('mb-2')}>Email</Text>
          <Input
            style={[
              tailwind('p-4 pr-6 w-full border rounded'),
              { borderColor: '#EAEAEA' },
            ]}
            placeholder={'Masukkan Email'}
            control={control}
            rules={{
              maxLength: 100,
              required: true,
            }}
            name="email"
            defaultValue=""
            errors={errors}
          />
        </View>
        <View style={tailwind('mb-2')}>
          <InputPassword
            label="Password"
            placeholder={'Masukkan Password'}
            control={control}
            rules={{
              maxLength: 100,
              required: true,
            }}
            name="password"
            defaultValue=""
            passwordVisible={passVisibleState}
            onTogglePasswordVisible={() => setVisiblePass(!passVisibleState)}
            errors={errors}
          />
        </View>

        <TouchableOpacity
          activeOpacity={1}
          onPress={() => navigation.navigate('ForgotPassword')}
        >
          <Text style={[tailwind('ml-2 text-right text-teal')]}>
            Lupa Password?
          </Text>
        </TouchableOpacity>

        <View style={tailwind('mt-12')}>
          <Pressable
            style={tailwind('bg-primary p-2 rounded text-white')}
            onPress={handleSubmit(onSubmit)}
          >
            <Text
              style={[
                tailwind('text-center text-sm'),
                {
                  color: '#00443E',
                },
              ]}
            >
              Masuk
            </Text>
          </Pressable>

          <View
            style={[tailwind('flex flex-row justify-center items-center pt-3')]}
          >
            <Text style={tailwind('text-center text-gray-500')}>
              Belum punya akun?
            </Text>
            <Pressable
              onPress={() => {
                if (params?.redirect_from) {
                  navigation.navigate('Register', {
                    redirect_from: params.redirect_from,
                    param_name: params?.param_name,
                    param_value: params?.param_value,
                  })
                } else {
                  navigation.navigate('Register')
                }
              }}
            >
              <Text
                style={[
                  tailwind('ml-2 text-bio text-center'),
                  {
                    color: '#00443E',
                  },
                ]}
              >
                Daftar disini
              </Text>
            </Pressable>
          </View>
          <Toast ref={toastRef} color="danger" />
        </View>
      </View>
    </Container>
  )
}
