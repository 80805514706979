import { GetAllResponse } from '@withbioma/apitype-publicapi-public/lib/v1/products/product_items/asset_availabilities/api'

import API from '~/apis/client'

export async function getAll(
  productId: number,
  productItemId: number,
  durationDays: number
) {
  return await API.handle(
    API.public.get<GetAllResponse>(
      `/public/v1/products/${productId}/product_items/${productItemId}/asset_availabilities`,
      {
        params: {
          durationDays,
        },
      }
    )
  )
}
