import { useEffect } from 'react'
import { ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { get } from '~/store/collection'
import { RootState, useAppDispatch, useAppSelector } from '~/store/store'

import Container from '~/components/Container'
import { SearchContextProvider } from '~/components/SearchContext'
import SearchResult from '~/components/SearchResult'
import Text from '~/components/StyledText'
import AppHeader from '~/components/headers/AppHeader'
import ProductTile from '~/components/products/ProductTile'

import { DashboardProps } from '~/navigation/types'

export default function Collection({
  route,
  navigation,
}: DashboardProps<'Collection'>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()
  const { collection } = useAppSelector((state: RootState) => state.collection)
  const { collection_id } = route.params

  useEffect(() => {
    dispatch(get(collection_id))
  }, [collection_id])

  return (
    <SearchContextProvider>
      <Container>
        <AppHeader navigation={navigation} backVisible={true} />
        <ScrollView style={tailwind('w-full flex flex-col')}>
          <SearchResult navigation={navigation} />
          {collection && (
            <View>
              <View style={tailwind('px-4')}>
                <Text style={tailwind('font-bold text-xl')}>
                  {collection.name}
                </Text>
                <Text style={tailwind('mb-2')}>
                  {collection.Products?.length || 0} Produk
                </Text>
                <Text>{collection.description}</Text>
              </View>
              <View style={tailwind('flex flex-row flex-wrap')}>
                {collection.Products?.map((product: any, i: any) => (
                  <View style={tailwind('w-6/12 p-4')} key={i}>
                    <ProductTile
                      product={product}
                      navigation={navigation}
                      height={160}
                    />
                  </View>
                ))}
              </View>
            </View>
          )}
        </ScrollView>
      </Container>
    </SearchContextProvider>
  )
}
