enum ActionTypes {
  setVariantSelected = 'setVariantSelected',
  setSelectedProductId = 'setSelectedProductId',
  setSelectedProductItemId = 'setSelectedProductItemId',
  setSelectedDuration = 'setSelectedDuration',
  setSelectedDate = 'setSelectedDate',
  reset = 'reset',
}
export { ActionTypes }

interface VariantSelectedAction {
  type: ActionTypes.setVariantSelected
  value: Map<number, number>
}
interface ProductIdSelectedAction {
  type: ActionTypes.setSelectedProductId
  value: number
}
interface ProductItemIdSelectedAction {
  type: ActionTypes.setSelectedProductItemId
  value: number
}
interface DurationSelectedAction {
  type: ActionTypes.setSelectedDuration
  value: number
}
interface DateSelectedAction {
  type: ActionTypes.setSelectedDate
  value: Date
}
interface ResetAction {
  type: ActionTypes.reset
}

export type ProductSelectedAction =
  | VariantSelectedAction
  | ProductIdSelectedAction
  | ProductItemIdSelectedAction
  | DurationSelectedAction
  | DateSelectedAction
  | ResetAction

export type ProductSelectedState = {
  variantSelected: Map<number, number>
  selectedProductId: number
  selectedProductItemId: number
  selectedDuration: number
  selectedDate: Date | null
}

export type ProductSelectedReducer = (
  state: ProductSelectedState,
  action: ProductSelectedAction
) => ProductSelectedState
