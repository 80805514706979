import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  constructErrorPayload,
  constructExtraReducer,
  constructSuccessPayload,
} from '~/store/helper'

import { getAll as getAllCall } from '~/apis/order_histories'

interface IState {
  orderHistories?: any[]
  loading: object
  responses: object
}
const initialState: IState = {
  orderHistories: [],
  loading: {},
  responses: {},
}

export const getAll = createAsyncThunk(
  'orderHistory/getAll',
  async (params: object, { rejectWithValue }) => {
    const [res, resErr] = await getAllCall(params)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const reducerSlice = createSlice({
  name: 'orderHistory',
  initialState,
  reducers: {
    resetResponse(state: IState, action: PayloadAction<any>) {
      const apiName = action.payload
      state.responses = {
        ...state.responses,
        [apiName]: null,
      }
    },
    resetOrderHistories(state: IState) {
      state.orderHistories = []
    },
  },
  extraReducers: (builder) => {
    constructExtraReducer(
      builder,
      getAll,
      (state: IState, action: PayloadAction<any>) => {
        state.orderHistories = action.payload.body.orderHistories
      }
    )
  },
})

export const { resetResponse, resetOrderHistories } = reducerSlice.actions
export default reducerSlice.reducer
