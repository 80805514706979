import {
  GetAllQueryString,
  GetAllResponse,
  GetResponse,
} from '@withbioma/apitype-publicapi-public/lib/v1/collections/api'

import API from '~/apis/client'
import { useCachedResponse } from '~/apis/utils'

export async function get(collectionId: string) {
  return await API.handle(
    API.public.get<GetResponse>(`/public/v1/collections/${collectionId}`)
  )
}

export async function getAll() {
  return await API.handle(
    API.public.get<GetAllResponse>(`/public/v1/collections`, {
      headers: useCachedResponse,
    })
  )
}

export async function getAllByParam(params: GetAllQueryString) {
  return await API.handle(
    API.public.get<GetAllResponse>(`/public/v1/collections`, {
      params,
      headers: useCachedResponse,
    })
  )
}
