import { CustomOrderAttributes } from '@withbioma/apitype-publicapi-public/lib/v1/orders/api'
import { AxiosResponse } from 'axios'
import { openBrowserAsync } from 'expo-web-browser'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Linking, Pressable, ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import { getCurrentCart } from '~/store/cart'
import { constructErrorPayload, constructSuccessPayload } from '~/store/helper'
import { publicConfirm, publicGet as publicGetOrder } from '~/store/order'
import { RootState, useAppDispatch, useAppSelector } from '~/store/store'

import Container from '~/components/Container'
import Text from '~/components/StyledText'
import Input from '~/components/base/Input'
import CartInfoModal from '~/components/cart/Modal'
import CartCheckout from '~/components/checkout/CartCheckout'
import CheckoutItemRow from '~/components/checkout/CheckoutItemRow'
import BackHeader from '~/components/headers/BackHeader'
import ProductOrderItemRow from '~/components/order_items/ProductItemRow'
import {
  OrderContextProvider,
  useOrderContext,
} from '~/components/orders/OrderContext'

import {
  createOrderFromCart as createOrderFromCartCall,
  getOrderFromCart,
} from '~/apis/cart'
import { toCurrency } from '~/lib/money'
import { RootStackProps } from '~/navigation/types'
import InformationIcon from '~/svg/Information'

const DISCOUNT = 0

function CheckoutContent({ route, navigation }: RootStackProps<'Checkout'>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()

  const routeParam = route.params

  const [order, setOrder] = useState<CustomOrderAttributes>()
  const [isSubmitted, setSubmitted] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  const { user } = useAppSelector((state: RootState) => state.user)
  const { publicOrder } = useAppSelector((state: RootState) => state.order)
  const { authenticated } = useAppSelector((state: RootState) => state.auth)
  const { cart } = useAppSelector((state: RootState) => state.cart)

  const { evalOrder, isLoaded, subtotal, total, paymentDeadline } =
    useOrderContext()

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const totalPrice = useMemo(() => {
    let price = 0
    const cartItems = cart?.CartItems as any[]

    if (cartItems && cartItems.length > 0) {
      const pricingRates = cartItems?.flatMap((item) => {
        return (
          item?.ProductItem?.PricingRates.find(
            (val: any) => val.id == item.pricingRateId
          )?.price || 0
        )
      })

      price = pricingRates.reduce((acc, curr) => acc + curr)
    }

    return price
  }, [cart])

  useEffect(() => {
    ;(async () => {
      const isNotGuest = !routeParam?.uuid && authenticated

      if (isNotGuest && cart) {
        setOrder({
          id: cart.id,
          customerId: cart.customerId,
          OrderItems: cart.CartItems?.map((cartItem: any) => {
            const price: number =
              cartItem.ownerType === 'productItem'
                ? cartItem?.ProductItem?.PricingRates.find(
                    (val: any) => val.id == cartItem.pricingRateId
                  )?.price || 0
                : cartItem?.AddOn?.price || 0

            return {
              id: cartItem.id,
              orderId: cart.id,
              ownerId: cartItem.ownerId,
              ownerType: cartItem.ownerType,
              price,
              ProductItem: cartItem?.ProductItem,
              AddOn: cartItem?.AddOn,
              startsAt: cartItem?.startsAt,
              endsAt: cartItem?.endsAt,
            }
          }),
          status: 'created',
          deliveryAddress: cart?.Address?.address || '',
          deliveryAddressDistrict: cart?.Address?.district || '',
          deliveryAddressProvince: cart?.Address?.province || '',
          deliveryAddressRegency: cart?.Address?.regency || '',
          deliveryAddressZipcode: cart?.Address?.zipcode || '',
          pickupAddress: cart?.Address?.address || '',
          pickupAddressDistrict: cart?.Address?.district || '',
          pickupAddressProvince: cart?.Address?.province || '',
          pickupAddressRegency: cart?.Address?.regency || '',
          pickupAddressZipcode: cart?.Address?.zipcode || '',
          Customer: {
            name: user?.name,
            id: user?.id!,
            userId: user?.userId!,
            User: user?.User!,
          },
          Invoice: {
            total: totalPrice,
            discount: DISCOUNT,
          },
        } as any)
      }
    })()
  }, [cart, authenticated, user, totalPrice])

  // Guest order -> Fetch
  useEffect(() => {
    if (routeParam?.uuid && authenticated == false) {
      dispatch(publicGetOrder(routeParam.uuid))
    }
  }, [routeParam, authenticated])

  // If guest, after dispatched action
  // Change order local state
  useEffect(() => {
    if (authenticated == false) {
      setOrder(publicOrder)
    }
  }, [authenticated, publicOrder])

  // Map order
  useEffect(() => {
    if (order) evalOrder(order)
  }, [order])

  const onSubmit = async (data: any) => {
    setSubmitted(true)

    const res = await fetch(createOrderFromCartCall(cart?.id!, data))
    if (authenticated) {
      if (res?.body?.order) {
        const orderCreate = res?.body?.order
        const transactions = orderCreate?.Invoice?.Transactions

        if (
          transactions.length &&
          transactions[0] &&
          transactions[0].xenditInvoiceUrl
        ) {
          const result = await openBrowserAsync(
            transactions[0].xenditInvoiceUrl,
            {
              showInRecents: true,
            }
          )

          if (result || (result as any)?.type == 'opened') {
            const transaction = transactions[0]
            if (transaction && transaction.uuid) {
              navigation.navigate('TransactionDetail', {
                transaction_uuid: transaction?.uuid,
              })
            }

            setSubmitted(false)
          }
        }
      }
    } else {
      if (routeParam.uuid) {
        navigation.navigate('OrderConfirmation', {
          order_confirmation_uuid: routeParam.uuid,
        })
      }
    }
  }

  return (
    <Container>
      <BackHeader
        navigation={navigation}
        fallback="Home"
        noPrev={!authenticated}
        text="Pembayaran"
      />

      {!authenticated && (
        <React.Fragment>
          <View
            style={[
              tailwind('pt-14 bg-yellow-400 px-4 py-2'),
              { marginTop: 59 },
            ]}
          >
            <Text style={tailwind('text-sm flex items-center')}>
              Konfirmasi Pembayaran sebelum:{' '}
              <Text style={tailwind('font-bold mr-2')}>
                {isLoaded ? paymentDeadline : '-'}
              </Text>
              <Pressable onPress={() => setModalVisible(true)}>
                <InformationIcon />
              </Pressable>
            </Text>
          </View>

          <CartInfoModal open={modalVisible} setOpen={setModalVisible} />
        </React.Fragment>
      )}

      <ScrollView
        style={[
          tailwind('pt-2 pb-7'),
          {
            marginTop: authenticated ? 40 : 5,
          },
        ]}
      >
        <View style={tailwind('pt-3 px-4')}>
          {!authenticated && (
            <React.Fragment>
              <Text style={tailwind('text-lg mb-3 font-bold')}>
                Detail Pemesan
              </Text>

              <View style={tailwind('mb-7')}>
                <Text style={tailwind('mb-2 font-bold')}>
                  {order?.Customer?.name || '-'}
                </Text>
                <Text style={tailwind('mb-1')}>
                  {order?.Customer?.User?.email || '-'})
                </Text>
                <Text>{order?.Customer?.User?.phone}</Text>
              </View>
            </React.Fragment>
          )}

          <Text style={tailwind('text-lg mb-3 font-bold')}>
            Alamat Pengiriman
          </Text>

          <View style={tailwind('mb-4')}>
            <Text style={tailwind('mb-2 font-bold')}>Alamat Utama</Text>
            <Text style={tailwind('mb-1')}>
              {order?.Customer?.name || order?.Customer?.User?.email} (
              {order?.Customer?.User?.phone})
            </Text>
            <Text>{order?.Invoices?.[0]?.deliveryAddress}</Text>
          </View>

          <View style={tailwind('mb-4')}>
            <Text style={tailwind('text-lg mb-3 font-bold')}>
              Rincian Produk
            </Text>

            {order?.OrderItems?.filter(
              (orderItem) => orderItem.ProductItem
            )?.map((orderItem, i) => {
              const len =
                order?.OrderItems && order?.OrderItems?.length > 1
                  ? order?.OrderItems?.length
                  : 0
              const isLastIndex = len > 0 ? len - 1 == i : false

              return (
                <ProductOrderItemRow
                  key={i}
                  orderItem={orderItem}
                  isLastIndex={isLastIndex}
                />
              )
            })}

            <View
              style={tailwind('flex flex-col border-b-2 border-gray-200 py-4')}
            >
              <View style={tailwind('flex flex-col')}>
                <View style={tailwind('flex flex-row justify-between mb-2')}>
                  <Text style={tailwind('text-sm')}>Total biaya sewa</Text>
                  <Text style={tailwind('text-sm')}>
                    {isLoaded ? toCurrency(subtotal) : '-'}
                  </Text>
                </View>

                {order?.OrderItems?.filter((orderItem) => orderItem.AddOn)?.map(
                  (orderItem, i) => (
                    <View
                      style={tailwind('flex flex-row justify-between mb-2')}
                    >
                      <Text style={tailwind('text-sm')}>
                        {orderItem?.AddOn?.name}
                      </Text>
                      <Text style={tailwind('text-sm')}>
                        {toCurrency(orderItem?.price)}
                      </Text>
                    </View>
                  )
                )}

                {/* <View style={tailwind('flex flex-row justify-between mb-2')}>
                  <Text style={tailwind('text-sm')}>Diskon Potongan</Text>
                  <Text
                    style={[
                      tailwind('text-sm'),
                      {
                        color: '#E05151',
                      },
                    ]}
                  >
                    -{toCurrency(order?.Invoices?.[0]?.discount) || 'Rp.0'}
                  </Text>
                </View> */}
              </View>
            </View>

            <View style={tailwind('flex flex-col pt-4')}>
              <View style={tailwind('flex flex-row justify-between')}>
                <Text style={tailwind('text-sm')}>Total bayar</Text>
                <Text style={tailwind('text-sm font-bold')}>
                  {isLoaded ? toCurrency(total) : '-'}
                </Text>
              </View>

              <View style={tailwind('flex flex-row mt-4')}>
                <Text style={tailwind('text-xs mr-2 text-gray-500')}>*</Text>
                <Text style={tailwind('text-xs text-gray-500')}>
                  Harga belum final dan dapat berubah sebelum pesanan
                  dikonfirmasi
                </Text>
              </View>
            </View>
          </View>

          <View style={tailwind('pt-4')}>
            <Text style={tailwind('text-lg mb-2 font-bold')}>Catatan</Text>
            <Input
              style={[
                tailwind('px-2 w-full rounded'),
                { borderColor: '#EAEAEA' },
              ]}
              placeholder={'Tulis catatan pengiriman'}
              multiline={true}
              numberOfLines={3}
              control={control}
              rules={{
                maxLength: 100,
              }}
              name="deliveryAddressInstructions"
              defaultValue={cart?.Address?.instructions || ''}
              errors={errors}
            />
          </View>
        </View>

        {!authenticated && (
          <View style={tailwind('p-4')}>
            <Text style={tailwind('text-lg mb-2 font-bold')}>Bantuan</Text>
            <Text style={tailwind('text-xs text-gray-500 mb-4')}>
              Pesanan tidak sesuai atau ingin mengganti pesanan?
            </Text>
            <Pressable
              style={[
                tailwind('py-2 px-5 rounded border text-white mb-2'),
                {
                  borderColor: '#00443E',
                },
              ]}
              onPress={() => {
                Linking.openURL('https://wa.me/6281284455345')
              }}
            >
              <Text style={tailwind('text-center text-sm font-bold')}>
                Hubungi Customer Service
              </Text>
            </Pressable>
          </View>
        )}
      </ScrollView>

      <CartCheckout
        btnText={
          authenticated
            ? !isSubmitted
              ? 'Pilih Pembayaran'
              : 'Tunggu'
            : 'Konfirmasi dan Bayar'
        }
        total={totalPrice}
        onPress={handleSubmit(onSubmit)}
        disabled={isSubmitted || !cart?.CartItems?.length}
      />
    </Container>
  )
}

const fetch = async (
  task: Promise<[AxiosResponse<any, any> | undefined, any]>
) => {
  const [res, resErr] = await task
  if (resErr) {
    throw constructErrorPayload(resErr)
  }
  return res && constructSuccessPayload(res)
}

// Better using HOC?
export default function Checkout({
  route,
  navigation,
}: RootStackProps<'Checkout'>) {
  return (
    <OrderContextProvider>
      <CheckoutContent route={route} navigation={navigation} />
    </OrderContextProvider>
  )
}
