import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { WaitlistAttributes } from '@withbioma/apitype-publicapi-public/lib/v1/common/waitlist'

import {
  constructErrorPayload,
  constructExtraReducer,
  constructSuccessPayload,
} from '~/store/helper'

import { create as createCall } from '~/apis/waitlist'
import { ISchema } from '~/interfaces/Waitlist'

interface IWaitlistGroup {
  productId?: number | null | undefined
  productItemId?: number | null | undefined
  startsAt?: Date | null | undefined
  endsAt?: Date | null | undefined

  /** Format: {variantGroupName} - {variantName} */
  variants?: string | null | undefined
}

interface IState {
  waitlist?: WaitlistAttributes
  group: IWaitlistGroup
  loading: object
  responses: object
}

const initialGroupState = {
  startsAt: undefined,
  endsAt: undefined,
  productId: undefined,
  productItemId: undefined,
  variants: undefined,
}

const initialState: IState = {
  waitlist: undefined,
  loading: {},
  responses: {},
  group: { ...initialGroupState },
}

export const create = createAsyncThunk(
  'waitlist/create',
  async (payload: ISchema, { rejectWithValue, dispatch }) => {
    const [res, resErr] = await createCall(payload)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const reducerSlice = createSlice({
  name: 'waitlist',
  initialState,
  reducers: {
    resetResponse(state: any, action: PayloadAction<any>) {
      const apiName = action.payload
      state.responses = {
        ...state.responses,
        [apiName]: null,
      }
    },
    resetWaitlist(state: IState) {
      state.waitlist = undefined
    },
    setGroup(state: IState, action: PayloadAction<Partial<IWaitlistGroup>>) {
      const payload = action.payload

      state.group = {
        ...state.group,
        ...payload,
      }
    },
    resetGroup(state: IState) {
      state.group = initialGroupState
    },
  },
  extraReducers: (builder) => {
    constructExtraReducer(builder, create)
  },
})

export const { resetResponse, resetWaitlist, setGroup, resetGroup } =
  reducerSlice.actions
export default reducerSlice.reducer
