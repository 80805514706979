import Svg, { Path } from 'react-native-svg'

const Trash = (props: any) => (
  <Svg
    width={20}
    height={20}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M9.33301 6.66602L9.33301 11.3327"
      stroke="#333333"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M6.66699 6.66602L6.66699 11.3327"
      stroke="#333333"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M4 4V3.35C3.64101 3.35 3.35 3.64101 3.35 4H4ZM12 4H12.65C12.65 3.64101 12.359 3.35 12 3.35V4ZM4 4.65H12V3.35H4V4.65ZM11.35 4V13.3333H12.65V4H11.35ZM11.3333 13.35H4.66667V14.65H11.3333V13.35ZM4.65 13.3333V4H3.35V13.3333H4.65ZM4.66667 13.35C4.66317 13.35 4.66147 13.3494 4.66038 13.349C4.65886 13.3483 4.65686 13.3471 4.65488 13.3451C4.6529 13.3431 4.65169 13.3411 4.65104 13.3396C4.65058 13.3385 4.65 13.3368 4.65 13.3333H3.35C3.35 14.0605 3.93949 14.65 4.66667 14.65V13.35ZM11.35 13.3333C11.35 13.3368 11.3494 13.3385 11.349 13.3396C11.3483 13.3411 11.3471 13.3431 11.3451 13.3451C11.3431 13.3471 11.3411 13.3483 11.3396 13.349C11.3385 13.3494 11.3368 13.35 11.3333 13.35V14.65C12.0605 14.65 12.65 14.0605 12.65 13.3333H11.35Z"
      fill="#333333"
    />
    <Path
      d="M2.66699 4H13.3337"
      stroke="#333333"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10.6663 4V4.65C11.0253 4.65 11.3163 4.35899 11.3163 4H10.6663ZM5.33301 4H4.68301C4.68301 4.35899 4.97402 4.65 5.33301 4.65V4ZM5.99967 2.65H9.99967V1.35H5.99967V2.65ZM10.0163 2.66667V4H11.3163V2.66667H10.0163ZM10.6663 3.35H5.33301V4.65H10.6663V3.35ZM5.98301 4V2.66667H4.68301V4H5.98301ZM9.99967 2.65C10.0032 2.65 10.0049 2.65058 10.006 2.65104C10.0075 2.65169 10.0095 2.6529 10.0115 2.65488C10.0134 2.65686 10.0147 2.65886 10.0153 2.66038C10.0158 2.66147 10.0163 2.66317 10.0163 2.66667H11.3163C11.3163 1.93949 10.7268 1.35 9.99967 1.35V2.65ZM5.99967 1.35C5.2725 1.35 4.68301 1.93949 4.68301 2.66667H5.98301C5.98301 2.66317 5.98359 2.66147 5.98405 2.66038C5.9847 2.65886 5.98591 2.65686 5.98789 2.65488C5.98987 2.6529 5.99186 2.65169 5.99339 2.65104C5.99448 2.65058 5.99617 2.65 5.99967 2.65V1.35Z"
      fill="#333333"
    />
  </Svg>
)

export default Trash
