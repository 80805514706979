import API from '~/apis/client'

export async function getProvinces() {
  return await API.handle(
    API.private.get<any>(`/customer/v1/addresses/provinces`)
  )
}

export async function getRegencies(provinceId: string) {
  return await API.handle(
    API.private.get<any>(`/customer/v1/addresses/provinces/${provinceId}`)
  )
}

export async function getDistricts(provinceId: string, regencyId: string) {
  return await API.handle(
    API.private.get<any>(
      `/customer/v1/addresses/provinces/${provinceId}/regencies/${regencyId}`
    )
  )
}
