import {
  CreateRequest,
  CreateResponse,
  GetResponse,
  UpdateRequest,
  UpdateResponse,
} from '@withbioma/apitype-publicapi-customer/lib/v1/carts/api'
import {
  CreateRequest as OrderCreateRequest,
  CreateResponse as OrderCreateResponse,
} from '@withbioma/apitype-publicapi-customer/lib/v1/carts/orders/api'
import { CreateResponse as CreateResponseRecreate } from '@withbioma/apitype-publicapi-customer/lib/v1/deleted_carts/api'
import { GetResponse as GetResponseOrder } from '@withbioma/apitype-publicapi-public/lib/v1/orders/api'

import API from '~/apis/client'

export async function get(cartId: number) {
  return await API.handle(
    API.private.get<GetResponse>(`/customer/v1/carts/${cartId}`)
  )
}

export async function getDeleted(cartId: number) {
  return await API.handle(
    API.private.get<GetResponse>(`/customer/v1/deleted_carts/${cartId}`)
  )
}

export async function create(payload: CreateRequest) {
  return await API.handle(
    API.private.post<CreateResponse>(`/customer/v1/carts`, payload)
  )
}

export async function update(payload: UpdateRequest) {
  return await API.handle(
    API.private.patch<UpdateResponse>(`/customer/v1/carts`, payload)
  )
}

export async function createOrderFromCart(
  cartId: number,
  payload: OrderCreateRequest
) {
  return await API.handle(
    API.private.post<CreateResponse>(
      `/customer/v1/carts/${cartId}/orders`,
      payload
    )
  )
}

export async function getOrderFromCart(cartId: number) {
  return await API.handle(
    API.private.get<GetResponseOrder>(`/customer/v1/carts/${cartId}/orders`)
  )
}

export async function recreate(cartId: number) {
  return await API.handle(
    API.private.post<CreateResponseRecreate>(
      `/customer/v1/deleted_carts/${cartId}/recreate`
    )
  )
}
