import { LoginRequest } from '@withbioma/apitype-publicapi-public/lib/v1/api'

import API from '~/apis/client'

export async function login(payload: LoginRequest) {
  return await API.handle(API.public.post<{}>(`/public/v1/login`, payload))
}

export async function logout(sessionToken: string) {
  return await API.handle(
    API.public.post<{}>(`/customer/logout`, { sessionToken })
  )
}
