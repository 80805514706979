import { PropsWithChildren } from 'react'
import { View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

type Props = {
  bgColor?: string
}

export default function Container({
  bgColor,
  children,
}: PropsWithChildren<Props>) {
  const tailwind = useTailwind()

  return (
    <View style={tailwind('h-full w-full flex flex-col items-center')}>
      <View
        style={[
          tailwind(`h-full w-full ${!bgColor ? 'bg-white' : ''}`),
          {
            maxWidth: 400,
            backgroundColor: bgColor ? bgColor : '#fff',
          },
        ]}
      >
        {children}
      </View>
    </View>
  )
}
