import { View } from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import Text from '~/components/StyledText'

type EmptyStateProps = {
  text: string
}

export default function EmptyState({ text }: EmptyStateProps) {
  const tailwind = useTailwind()

  return (
    <View
      style={tailwind(
        `flex flex-row justify-between p-2 border border-gray-200 bg-red-100 rounded mb-2 items-center`
      )}
    >
      <Text style={tailwind('text-sm')}>{text}</Text>
    </View>
  )
}
