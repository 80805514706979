import * as React from 'react'
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

const CartUnavailable = (props: any) => (
  <Svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Rect width="64" height="64" rx="32" fill="#FFE1DA" />
    <G clipPath="url(#clip0_7270_313594)">
      <Path
        d="M36.5215 34.7432H29.4655C24.3695 34.7432 24.3695 29.7518 24.3695 24.9432C24.3695 23.0616 22.6185 22.9832 22.4095 22.9832C22.2004 22.9832 22.0175 22.8003 22.0175 22.5912C22.0175 22.3822 22.2004 22.1992 22.4095 22.1992C23.3503 22.1992 25.1535 22.7742 25.1535 24.9432C25.1535 29.5688 25.1535 33.9592 29.4655 33.9592H36.5215C40.2585 33.9592 40.7551 27.06 40.8335 25.3352H27.1135C26.9044 25.3352 26.7215 25.1523 26.7215 24.9432C26.7215 24.7342 26.9044 24.5512 27.1135 24.5512H41.2255C41.4345 24.5512 41.6175 24.7342 41.6175 24.9432C41.6175 25.3352 41.5652 34.7432 36.5215 34.7432Z"
        fill="#5A3D28"
      />
      <Path
        d="M29.3087 41.7994C28.2372 41.7994 27.3748 40.937 27.3748 39.8655C27.3748 38.794 28.2372 37.9316 29.3087 37.9316C30.3801 37.9316 31.2425 38.794 31.2425 39.8655C31.2425 40.937 30.3801 41.7994 29.3087 41.7994ZM29.3087 38.6895C28.6554 38.6895 28.1588 39.2122 28.1588 39.8394C28.1588 40.4927 28.6815 40.9892 29.3087 40.9892C29.9359 40.9892 30.4585 40.4666 30.4585 39.8394C30.4585 39.2122 29.9359 38.6895 29.3087 38.6895Z"
        fill="#5A3D28"
      />
      <Path
        d="M35.6852 41.7994C34.6137 41.7994 33.7513 40.937 33.7513 39.8655C33.7513 38.794 34.6137 37.9316 35.6852 37.9316C36.7567 37.9316 37.6191 38.794 37.6191 39.8655C37.6191 40.937 36.7567 41.7994 35.6852 41.7994ZM35.6852 38.6895C35.0319 38.6895 34.5353 39.2122 34.5353 39.8394C34.5353 40.4927 35.058 40.9892 35.6852 40.9892C36.3124 40.9892 36.8351 40.4666 36.8351 39.8394C36.8351 39.2122 36.3124 38.6895 35.6852 38.6895Z"
        fill="#5A3D28"
      />
      <Path
        d="M40.1279 37.095H27.1919C26.9044 37.095 24.4479 37.0427 24.4479 35.527C24.4479 34.0113 26.9044 33.959 27.1919 33.959H36.5999C36.8089 33.959 36.9919 34.1419 36.9919 34.351C36.9919 34.5601 36.8351 34.743 36.5999 34.743H27.1919C26.5647 34.743 25.2319 34.9259 25.2319 35.527C25.2319 36.1281 26.5647 36.311 27.1919 36.311H40.1279C40.3369 36.311 40.5199 36.4939 40.5199 36.703C40.5199 36.9121 40.3631 37.095 40.1279 37.095Z"
        fill="#5A3D28"
      />
    </G>
    <Rect x="47" y="51" width="11" height="11" rx="5.5" fill="#E05151" />
    <G clipPath="url(#clip1_7270_313594)">
      <Path
        d="M54.375 58.375L50.625 54.625"
        stroke="white"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M54.375 54.625L50.625 58.375"
        stroke="white"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_7270_313594">
        <Rect
          width="19.6"
          height="19.6"
          fill="white"
          transform="translate(22.0175 22.1992)"
        />
      </ClipPath>
      <ClipPath id="clip1_7270_313594">
        <Rect width="5" height="5" fill="white" transform="translate(50 54)" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default CartUnavailable
