import { useEffect, useMemo, useState } from 'react'
import { Image, Linking, Pressable, ScrollView, Text, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { getAllByCollectionIds } from '~/store/collection'
import { RootState, useAppDispatch, useAppSelector } from '~/store/store'

import Container from '~/components/Container'
import { SearchContextProvider } from '~/components/SearchContext'
import SearchResult from '~/components/SearchResult'
import CustomActionSheet from '~/components/base/CustomActionSheet'
import BaseImage from '~/components/base/Image'
import AppHeader from '~/components/headers/AppHeader'

import { DashboardProps } from '~/navigation/types'

export default function Catalog({
  route,
  navigation,
}: DashboardProps<'Babyloania'>) {
  const params = route.params

  const tailwind = useTailwind()
  const dispatch = useAppDispatch()
  const { collections } = useAppSelector((state: RootState) => state.collection)

  const [openModal, setOpen] = useState(!!params?.params_ref)

  const CollectionIds = [
    1332, 1315, 1361, 1450, 1008, 1365, 1009, 1449, 1452, 1451, 1364, 1321,
  ]

  useEffect(() => {
    dispatch(
      getAllByCollectionIds({
        collectionIds: CollectionIds,
      })
    )
  }, [])

  useEffect(() => {
    if (params && params.params_ref) {
      setOpen(true)
    }
  }, [params])

  const reorderedCollections = useMemo(() => {
    return [...collections].sort(
      (a: any, b: any) =>
        CollectionIds.indexOf(a.id) - CollectionIds.indexOf(b.id)
    )
  }, [collections])

  return (
    <SearchContextProvider>
      <Container>
        <ScrollView style={tailwind('w-full flex flex-col')}>
          <AppHeader navigation={navigation} backVisible />
          <SearchResult navigation={navigation} />
          <View>
            <View style={tailwind('mb-4')}>
              <BaseImage
                source={require('~/assets/images/babyloania/banner.png')}
                imageStyles={[{ aspectRatio: 2 }]}
                width={300}
                height={200}
              />
            </View>
            <View style={tailwind('px-4 mb-4')}>
              <Text style={tailwind('font-bold text-xl')}>
                Babyloania Collections
              </Text>
              <Text style={tailwind('mb-2')}>12 Produk</Text>
              <Text>
                Sekarang Babyloania sudah menjadi bagian dari Bioma. Transaksi
                dapat dilakukan dengan lebih mudah melalui Bioma. Tapi jangan
                khawatir, metode transaksi yang biasa dilakukan seperti melalui
                WhatsApp dan Instagram tetap terbuka!{' '}
                <Text
                  onPress={() => setOpen(true)}
                  style={tailwind('text-blue-700 underline')}
                >
                  Pelajari Lebih Lanjut
                </Text>
                .
              </Text>
            </View>
            {reorderedCollections.map((collection, i) => (
              <Pressable
                onPress={() =>
                  navigation.navigate('Collection', {
                    collection_id: String(collection.id),
                  })
                }
                style={tailwind('px-4 mb-4')}
                key={i}
              >
                {collection.Images && collection.Images.length > 0 && (
                  <Image
                    source={{ uri: collection.Images[0].url }}
                    style={[
                      { aspectRatio: 2, height: '160px' },
                      tailwind('rounded-t'),
                    ]}
                  />
                )}
                {collection.Images && collection.Images.length === 0 && (
                  <Image
                    source={{ uri: 'https://i.imgur.com/OPsGLq5.png' }}
                    style={[
                      { aspectRatio: 2, height: '160px' },
                      tailwind('rounded-t'),
                    ]}
                  />
                )}
                <View
                  style={[{ background: '#B2E2DF' }, tailwind('p-2 rounded-b')]}
                >
                  <Text style={tailwind('font-bold')}>{collection.name}</Text>
                  <Text style={tailwind('text-xs')}>
                    {collection.productCount || '-'} Produk
                  </Text>
                </View>
              </Pressable>
            ))}
          </View>
        </ScrollView>
      </Container>

      <CustomActionSheet
        open={openModal}
        setVisible={setOpen}
        height="70%"
        title="Tentang Babyloania x Bioma"
      >
        <BaseImage
          imageStyles={[tailwind('mb-2 w-full rounded-lg'), { aspectRatio: 1 }]}
          source={{
            uri: require('~/assets/images/babyloania/banner.png'),
          }}
          height={182}
          resizeMode="contain"
          rounded
        />

        <Text style={tailwind('mt-5 pb-4')}>
          Kabar baik buat Momijen! Sekarang Babyloania sudah menjadi bagian dari
          Bioma.
        </Text>

        <Text style={tailwind('pb-4')}>
          Transaksi dapat dilakukan dengan lebih mudah melalui Bioma. Tapi
          jangan khawatir, metode transaksi yang biasa dilakukan seperti melalui
          WhatsApp dan Instagram tetap terbuka!
        </Text>

        <Text style={tailwind('pb-4')}>
          Bioma juga menyediakan berbagai pilihan produk yang lebih lengkap
          mulai dari mebel, peralatan masak, sampai gadget dan perangkat
          elektronik lainnya. Jadi semakin banyak pilihan produk dengan kualitas
          terbaik untuk memenuhi kebutuhan keluarga.
        </Text>

        <Text style={tailwind('pb-4')}>
          Dapatkan kebebasan dan kemudahan akses barang pilihan dengan lebih
          hemat bersama Bioma!
        </Text>

        <Text>
          Untuk pertanyaan lebih lanjut, kontak{' '}
          <Text
            onPress={() => Linking.openURL('http://wa.me/6281219256065')}
            style={tailwind('text-blue-700 underline')}
          >
            CS kami
          </Text>
          .
        </Text>
      </CustomActionSheet>
    </SearchContextProvider>
  )
}
