import { MaterialIcons } from '@expo/vector-icons'
import { DateTime } from 'luxon'
import { Calendar, CalendarProps, LocaleConfig } from 'react-native-calendars'

import { DATE_CONFIG, normalizeDate, timestamp, toDate } from '~/lib/datetime'

LocaleConfig.locales.en = LocaleConfig.locales['']
LocaleConfig.locales.id = {
  monthNames: [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ],
  monthNamesShort: [
    'Jan.',
    'Feb',
    'Mar',
    'Apr',
    'Mei',
    'Jun',
    'Jul',
    'Agu',
    'Sep',
    'Okt',
    'Nov',
    'Des',
  ],
  dayNames: ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'],
  dayNamesShort: ['Min.', 'Sen.', 'Sel.', 'Rab.', 'Kam.', 'Jum.', 'Sab.'],
}

LocaleConfig.defaultLocale = 'id'

type Props = {
  onChange?: (d: Date) => void
} & Partial<Omit<CalendarProps, 'onDayPress'>>

const CalendarComponent: React.FC<Props> = (props) => {
  const {
    minDate,
    initialDate,
    firstDay,
    renderArrow,
    enableSwipeMonths,
    disableAllTouchEventsForDisabledDays,
    disableAllTouchEventsForInactiveDays,
    hideArrows,
    onChange: onChangeProps,
    ...rest
  } = props

  const defaultProps = {
    firstDay: firstDay ?? 1,
    minDate:
      minDate ??
      toDate(timestamp().getTime() + 1000 * 60 * 60 * 24)!.toDateString(),
    initialDate: initialDate ?? timestamp().toDateString(),
    renderArrow:
      renderArrow ??
      ((direction) => {
        return (
          <MaterialIcons
            size={24}
            name={`${direction == 'left' ? 'chevron-left' : 'chevron-right'}`}
            style={{
              color: '#00443E',
              fontWeight: '400',
            }}
          />
        )
      }),
    enableSwipeMonths: enableSwipeMonths ?? true,
    disableAllTouchEventsForDisabledDays:
      disableAllTouchEventsForDisabledDays ?? true,
    disableAllTouchEventsForInactiveDays:
      disableAllTouchEventsForInactiveDays ?? true,
    hideArrows: hideArrows ?? false,
    onDayPress: (day) => {
      if (!day) {
        return
      }

      const date = DateTime.fromFormat(day.dateString, DATE_CONFIG.DATE_FORMAT)
      const normalizedVal = normalizeDate(date.toJSDate()).toJSDate()

      if (onChangeProps) {
        onChangeProps(normalizedVal)
      }

      // else if (onDayPress) {
      //   const { year, month, day, } = date.toObject()
      //   onDayPress({
      //     day,
      //     year,
      //     month,
      //     timestamp: date.toJSDate().getTime(),
      //     dateString: date.toFormat(DATE_CONFIG.DATE_FORMAT)
      //   })
      // }
    },
  } as CalendarProps

  return <Calendar {...defaultProps} {...rest} />
}

export default CalendarComponent
