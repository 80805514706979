import { OrderAttributes } from '@withbioma/apitype-publicapi-customer/lib/v1/common/order'

export type OrderStatus = OrderAttributes['status']

export type ColorType = 'warn' | 'primary' | 'success' | 'complete' | 'cancel'

export type ReturnOrderStatus = {
  orderStatus: string
  primaryColor: string
  secondaryColor: string
}

export const parseOrderStatus = (status: OrderStatus): ReturnOrderStatus => {
  let result = {
    orderStatus: '',
    primaryColor: '',
    secondaryColor: '',
  }

  switch (status) {
    case 'created':
      result = {
        orderStatus: 'Menunggu Konfirmasi',
        primaryColor: '#5A3D28',
        secondaryColor: '#fff',
      }
      break
    case 'awaiting_payment':
      result = {
        orderStatus: 'Menunggu Pembayaran',
        primaryColor: '#5A3D28',
        secondaryColor: '#fff',
      }
      break
    case 'active':
      result = {
        orderStatus: 'Aktif',
        primaryColor: '#5AA7DF',
        secondaryColor: '#DDEBF4',
      }
      break
    case 'fulfilled':
      result = {
        orderStatus: 'Selesai',
        primaryColor: '#808080',
        secondaryColor: '#F0F0F0',
      }
      break
    case 'canceled':
      result = {
        orderStatus: 'Dibatalkan',
        primaryColor: '#E05151',
        secondaryColor: '#F8E3E3',
      }
      break
    case 'awaiting_process':
      result = {
        orderStatus: 'Menunggu Diproses',
        primaryColor: '#5A3D28',
        secondaryColor: '#fff',
      }
      break
    case 'order_processed':
      result = {
        orderStatus: 'Pesanan Diproses',
        primaryColor: '#5A3D28',
        secondaryColor: '#fff',
      }
      break
    case 'order_sent':
      result = {
        orderStatus: 'Pesanan Dikirim',
        primaryColor: '#5A3D28',
        secondaryColor: '#fff',
      }
      break
    case 'return_process':
      result = {
        orderStatus: 'Proses Pengembalian',
        primaryColor: '#5A3D28',
        secondaryColor: '#fff',
      }
  }

  return result
}
