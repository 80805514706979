import { GetResponse } from '@withbioma/apitype-publicapi-public/lib/v1/collections/api'
import { useEffect, useState } from 'react'
import { Pressable, ScrollView, Text, View } from 'react-native'
import { Style, useTailwind } from 'tailwind-rn'

import ProductTile from '~/components/products/ProductTile'

import { get } from '~/apis/collections'

interface IProps {
  navigation: any
  collectionId: number
  style?: Style
  title?: string
}

const Recommendation: React.FC<IProps> = ({
  navigation,
  collectionId,
  title,
  style,
}) => {
  const tailwind = useTailwind()

  const [collection, setCollection] = useState<GetResponse['collection']>()

  const getCollection = async (collectionId: number) => {
    const [res, resErr] = await get(String(collectionId))
    if (res && res.data) {
      setCollection(res.data.collection)
    }
  }

  useEffect(() => {
    getCollection(collectionId)
  }, [])

  return (
    <View style={[tailwind('flex flex-col'), style]}>
      <View style={tailwind('flex flex-row items-center justify-between')}>
        <Text style={tailwind('text-base font-bold px-4')}>
          {title || 'Rekomendasi Untuk Kamu'}
        </Text>
        <Pressable
          onPress={() =>
            navigation.navigate('Collection', {
              collection_id: String(collectionId),
            })
          }
        >
          <Text style={tailwind('text-teal px-4')}>Lihat Semua</Text>
        </Pressable>
      </View>
      <ScrollView
        style={tailwind('mt-4 pl-4')}
        horizontal
        showsHorizontalScrollIndicator={false}
      >
        {collection &&
          collection?.Products &&
          collection.Products.map((product: any, i: any) => (
            <View
              style={[tailwind('mb-6 pr-4 w-full'), { maxWidth: 140 }]}
              key={i}
            >
              <ProductTile
                product={product}
                navigation={navigation}
                height={124}
              />
            </View>
          ))}
      </ScrollView>
    </View>
  )
}

export default Recommendation
