import { useEffect, useState } from 'react'
import { Pressable, ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { RootState, useAppDispatch, useAppSelector } from '~/store/store'

import Image from '~/components/base/Image'

import { getAll } from '~/apis/collections'

interface IProps {
  navigation: any
}

const CollectionBanner: React.FC<IProps> = ({ navigation }) => {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()
  // const { collections } = useAppSelector((state: RootState) => state.collection)
  const [collections, setCollections] = useState<any[]>([])

  const getCollections = async () => {
    const [res] = await getAll()
    if (res) {
      setCollections(res.data.collections)
    }
  }

  useEffect(() => {
    getCollections()
  }, [])

  return (
    <ScrollView
      horizontal
      style={tailwind('pl-4')}
      showsHorizontalScrollIndicator={false}
    >
      {/* Babyloania Banner */}
      <View style={tailwind('pr-4')}>
        <Pressable onPress={() => navigation.navigate('Babyloania')}>
          <Image
            rounded
            source={require('~/assets/images/babyloania/banner.png')}
            imageStyles={[{ aspectRatio: 2 }]}
            width={300}
            height={150}
          />
        </Pressable>
      </View>
      {/* Remaining Banner */}
      {collections.map((collection, i) => (
        <View style={tailwind('pr-4')} key={i}>
          <Pressable
            onPress={() =>
              navigation.navigate('Collection', {
                collection_id: collection.id,
              })
            }
          >
            {collection.Images?.length ? (
              <Image
                rounded
                source={{
                  uri: collection.Images[0].variants?.find(
                    (i: any) => i === 'banner'
                  )
                    ? collection.Images[0].url.replace('.jpeg', '_banner.jpeg')
                    : collection.Images[0].url,
                }}
                imageStyles={[{ aspectRatio: 2 }]}
                width={300}
                height={150}
              />
            ) : (
              <Image
                rounded
                source={require('~/assets/images/home/frame-1.png')}
                imageStyles={[{ aspectRatio: 2 }]}
                width={300}
                height={150}
              />
            )}
          </Pressable>
        </View>
      ))}
    </ScrollView>
  )
}

export default CollectionBanner
