import { Picker } from '@react-native-picker/picker'
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
} from 'react-hook-form'
import { KeyboardTypeOptions, TextInput, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import Text from '~/components/StyledText'

interface IProps {
  style?: any[] | object
  control: Control<any>
  rules: object
  name: string
  defaultValue: string
  errors: DeepMap<FieldValues, FieldError>
  options: { label: string; value: number }[]
  enabled?: boolean
}

const PickerComponent: React.FC<IProps> = ({
  style,
  control,
  rules,
  name,
  defaultValue = '',
  errors,
  options,
  enabled,
}) => {
  const tailwind = useTailwind()
  let styleUnpacked: any[] = []
  if (style) {
    styleUnpacked = typeof style === 'object' ? [style] : style
  }
  return (
    <View style={tailwind('w-full')}>
      <Controller
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }: { field: any }) => (
          <Picker
            selectedValue={value}
            onValueChange={(itemValue) => onChange(itemValue)}
            style={[
              tailwind('p-4 pr-6 w-full border rounded'),
              { borderColor: '#EAEAEA' },
              ...styleUnpacked,
            ]}
            enabled={enabled}
          >
            {options.map((option, i) => (
              <Picker.Item label={option.label} value={option.value} key={i} />
            ))}
          </Picker>
        )}
        name={name}
        defaultValue={defaultValue}
      />
      {errors[name] && <Text>{errors[name].message}</Text>}
    </View>
  )
}

export default PickerComponent
