import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ProductAttributesFull } from '@withbioma/apitype-publicapi-public/lib/v1/common/product'
import { ProductTypeAttributes } from '@withbioma/apitype-publicapi-public/lib/v1/common/product_type'

import {
  constructErrorPayload,
  constructExtraReducer,
  constructSuccessPayload,
} from '~/store/helper'

import {
  privateSearch as privateSearchCall,
  search as searchCall,
} from '~/apis/search'

interface IState {
  products: ProductAttributesFull[]
  productTypes: ProductTypeAttributes[]
  loading: Record<string, any>
  responses: Record<string, any>
}
const initialState: IState = {
  products: [],
  productTypes: [],
  loading: {},
  responses: {},
}

export const search = createAsyncThunk(
  'search/search',
  async (params: { query: string }, { rejectWithValue }) => {
    const [res, resErr] = await searchCall(params)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const privateSearch = createAsyncThunk(
  'search/privateSearch',
  async (params: { query: string }, { rejectWithValue }) => {
    const [res, resErr] = await privateSearchCall(params)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const reducerSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetResponse(state: IState, action: PayloadAction<any>) {
      const apiName = action.payload
      state.responses = {
        ...state.responses,
        [apiName]: null,
      }
    },
    resetSearch(state: IState) {
      state.products = []
      state.productTypes = []
    },
  },
  extraReducers: (builder) => {
    constructExtraReducer(
      builder,
      search,
      (state: IState, action: PayloadAction<any>) => {
        state.products = action.payload.body.products
        state.productTypes = action.payload.body.productTypes
      }
    )
    constructExtraReducer(
      builder,
      privateSearch,
      (state: IState, action: PayloadAction<any>) => {
        state.products = action.payload.body.products
        state.productTypes = action.payload.body.productTypes
      }
    )
  },
})

export const { resetResponse, resetSearch } = reducerSlice.actions
export default reducerSlice.reducer
