import { toCurrency } from '~/lib/money'

export const transformCoupon = (
  coupon: any, // TODO: CouponAttributes
  originalPrice?: number
) => {
  let results: {
    price: number
    discountPrice: number
    couponType: 'percentage' | 'fees' | 'none'
    textual: string
  } = {
    price: 0,
    textual: '',
    discountPrice: 0,
    couponType: 'none',
  }

  // Fee
  if (coupon.amountOff && !coupon.percentOff) {
    results = {
      price: coupon.amountOff,
      couponType: 'fees',
      textual: `${coupon.code}; -${toCurrency(coupon.amountOff)}`,
      discountPrice: originalPrice
        ? +Math.floor(originalPrice - coupon.amountOff).toPrecision(7)
        : 0,
    }
  }

  // Percentage
  if (!coupon.amountOff && coupon.percentOff) {
    results = {
      price: coupon.percentOff,
      couponType: 'percentage',
      textual: `${coupon.code}; -Disc ${coupon.percentOff}%`,
      discountPrice: originalPrice
        ? originalPrice -
          +Math.floor((coupon.percentOff / 100) * originalPrice).toPrecision(7)
        : 0,
    }
  }

  return results
}
