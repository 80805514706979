import useCachedResources from './hooks/useCachedResources'
import Navigation from './navigation'
import reducer from './store/store'
import utilities from './tailwind.json'
import { StatusBar } from 'expo-status-bar'
import { useEffect } from 'react'
import { Platform } from 'react-native'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { TailwindProvider } from 'tailwind-rn'

export default function App() {
  const isLoadingComplete = useCachedResources()
  const { store, persistor } = reducer()

  useEffect(() => {
    if (Platform.OS === 'web') {
      const hotjar = document.createElement('script')
      hotjar.innerHTML =
        "(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3018999,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"
      hotjar.async = true
      document.head.appendChild(hotjar)

      const gaTag = document.createElement('script')
      gaTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-5Y5P326HSJ'
      gaTag.async = true
      document.head.appendChild(gaTag)

      const ga = document.createElement('script')
      ga.innerHTML =
        "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-5Y5P326HSJ');"
      ga.async = true
      document.head.appendChild(ga)
    }
  }, [])

  if (!isLoadingComplete) {
    return null
  } else {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SafeAreaProvider>
            <TailwindProvider utilities={utilities}>
              <Navigation />
              <StatusBar />
            </TailwindProvider>
          </SafeAreaProvider>
        </PersistGate>
      </Provider>
    )
  }
}
