import { DateTime } from 'luxon'
import { Pressable, TouchableOpacity, View } from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import Text from '~/components/StyledText'
import Image from '~/components/base/Image'
import { InitialState } from '~/components/order_histories/types'
import { ReturnOrderStatus } from '~/components/order_histories/utils'

import { toCurrency } from '~/lib/money'
import Calendar from '~/svg/Calendar'

type Props = {
  source: any
  onPress: (uuid: string) => void
  isFirstIndex: boolean
  isLastIndex: boolean
  orderStatus: ReturnOrderStatus
}

const TransactionItemRow: React.FC<Props> = ({
  source,
  onPress,
  isFirstIndex,
  isLastIndex,
  orderStatus,
}) => {
  const tailwind = useTailwind()

  const asset = source?.OrderItem?.Asset

  return (
    <TouchableOpacity
      onPress={() => onPress(source?.Invoice?.Transactions[0]?.uuid)}
    >
      <View
        style={[
          tailwind('border-gray-200 w-full'),
          {
            paddingTop: !isFirstIndex ? 25 : 0,
            paddingBottom: 30,
            borderBottomWidth: !isLastIndex ? 2 : 0,
          },
        ]}
      >
        <View style={tailwind('flex flex-row justify-start w-full')}>
          <Image
            imageStyles={[tailwind('mb-4'), { aspectRatio: 1 }]}
            source={{
              uri:
                asset?.ProductItem?.Images?.[0]?.url ||
                asset?.ProductItem?.Product?.Images?.[0]?.url ||
                '',
            }}
            width={60}
            height={60}
            rounded
          />

          <View style={{ flexGrow: 1 }}>
            <View style={[tailwind('flex flex-row justify-between pl-4')]}>
              <View style={tailwind('flex')}>
                <Text
                  style={[tailwind('font-bold text-base'), { maxWidth: 150 }]}
                >
                  {asset?.ProductItem?.Product?.name || ''}
                </Text>
                <Text style={[tailwind('text-xs'), { maxWidth: 150 }]}>
                  {asset?.ProductItem?.ProductVariants?.map(
                    (variant: any) => variant.name
                  ).join(' - ') || ''}
                </Text>

                {source?.OtherItems?.length > 0 &&
                  source?.status != 'fulfilled' &&
                  source?.status != 'canceled' && (
                    <Text style={tailwind('text-gray-400 text-xs')}>
                      +{source?.OtherItems?.length || 0} barang lainnya
                    </Text>
                  )}

                {source?.status != 'fulfilled' &&
                source?.status != 'canceled' ? (
                  <View style={tailwind('pt-5')}>
                    <Text style={tailwind('font-bold pb-1 text-xs')}>
                      Total Transaksi
                    </Text>
                    <Text>{toCurrency(source?.Invoice?.total)}</Text>
                  </View>
                ) : (
                  <View style={tailwind('pt-5')}>
                    <Text style={tailwind('font-bold pb-1 text-xs')}>
                      Tanggal Sewa
                    </Text>
                    <Text style={tailwind('text-xs')}>
                      <Calendar style={tailwind('pr-2 pt-1')} />
                      {DateTime.fromISO(`${source?.newestStartsAt}`).toFormat(
                        'dd LLL'
                      ) || ''}{' '}
                      -{' '}
                      {DateTime.fromISO(`${source?.latestEndsAt}`).toFormat(
                        'dd LLL'
                      ) || ''}
                    </Text>
                  </View>
                )}
              </View>

              <View>
                <View
                  style={[
                    tailwind('flex flex-col justify-between mb-1'),
                    { flexGrow: 1 },
                  ]}
                >
                  <Pressable
                    style={[
                      tailwind('flex flex-row items-center justify-center'),
                      {
                        maxWidth: 140,
                        height: 26,
                        borderWidth: 1,
                        borderRadius: 16,
                        padding: 7,
                        borderColor: orderStatus?.primaryColor,
                        backgroundColor: orderStatus?.secondaryColor,
                      },
                    ]}
                    onPress={() => {}}
                  >
                    <Text
                      style={[
                        tailwind(''),
                        {
                          fontSize: 11,
                          color: orderStatus?.primaryColor,
                        },
                      ]}
                    >
                      {orderStatus?.orderStatus}
                    </Text>
                  </Pressable>

                  {source?.status != 'fulfilled' &&
                    source?.status != 'canceled' && (
                      <View style={tailwind('pt-5')}>
                        <Text style={tailwind('font-bold pb-1 text-xs')}>
                          Tanggal Pengembalian
                        </Text>
                        <Text style={tailwind('text-xs')}>
                          <Calendar style={tailwind('pr-2 pt-1')} />
                          {DateTime.fromISO(`${source?.latestEndsAt}`).toFormat(
                            'dd LLL yyyy'
                          ) || ''}
                        </Text>
                      </View>
                    )}
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  )
}

export default TransactionItemRow
