import { useEffect } from 'react'
import { ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { getAll } from '~/store/product'
import { RootState, useAppDispatch, useAppSelector } from '~/store/store'

import Container from '~/components/Container'
import SearchResult from '~/components/SearchResult'
import BackHeader from '~/components/headers/BackHeader'
import ProductTile from '~/components/products/ProductTile'

import { RootStackProps } from '~/navigation/types'

export default function Products({ navigation }: RootStackProps<'Products'>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()
  const { products } = useAppSelector((state: RootState) => state.product)

  useEffect(() => {
    dispatch(getAll({ limit: 200 }))
  }, [])

  return (
    <Container>
      <ScrollView style={tailwind('w-full flex flex-col')}>
        <BackHeader navigation={navigation} text="Semua Produk" />
        <SearchResult navigation={navigation} />
        <View style={tailwind('pt-14')}>
          <View style={tailwind('flex flex-row flex-wrap')}>
            {products.map((product: any, i: any) => (
              <View style={tailwind('w-6/12 p-4')} key={i}>
                <ProductTile
                  product={product}
                  navigation={navigation}
                  height={160}
                />
              </View>
            ))}
          </View>
        </View>
      </ScrollView>
    </Container>
  )
}
