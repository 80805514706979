import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ConfirmRequest,
  CustomOrderAttributes,
} from '@withbioma/apitype-publicapi-public/lib/v1/orders/api'

import {
  constructErrorPayload,
  constructExtraReducer,
  constructSuccessPayload,
} from '~/store/helper'

import {
  publicConfirm as publicConfirmCall,
  publicGet as publicGetCall,
} from '~/apis/order'

interface IState {
  publicOrder?: CustomOrderAttributes
  loading: object
  responses: object
}
const initialState: IState = {
  publicOrder: undefined,
  loading: {},
  responses: {},
}

export const publicGet = createAsyncThunk(
  'order/publicGet',
  async (orderConfirmationUuid: string, { rejectWithValue }) => {
    const [res, resErr] = await publicGetCall(orderConfirmationUuid)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const publicConfirm = createAsyncThunk(
  'order/publicConfirm',
  async (
    {
      orderConfirmationUuid,
      payload,
    }: { orderConfirmationUuid: string; payload: ConfirmRequest },
    { rejectWithValue }
  ) => {
    console.log('DEBUG publicConfirm payload', payload)

    const [res, resErr] = await publicConfirmCall(
      orderConfirmationUuid,
      payload
    )
    if (resErr) {
      console.log('DEBUG publicConfirm resErr', resErr)
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const reducerSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    resetResponse(state: any, action: PayloadAction<any>) {
      const apiName = action.payload
      state.responses = {
        ...state.responses,
        [apiName]: null,
      }
    },
    resetPublicOrder(state: IState) {
      state.publicOrder = undefined
    },
  },
  extraReducers: (builder) => {
    constructExtraReducer(
      builder,
      publicGet,
      (state: IState, action: PayloadAction<any>) => {
        state.publicOrder = action.payload.body.order
      }
    )
    constructExtraReducer(
      builder,
      publicConfirm,
      (state: IState, action: PayloadAction<any>) => {
        state.publicOrder = action.payload.body.order
      }
    )
  },
})

export const { resetResponse, resetPublicOrder } = reducerSlice.actions

export default reducerSlice.reducer
