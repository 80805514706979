import {
  CreateRequest,
  CreateResponse,
} from '@withbioma/apitype-publicapi-public/lib/v1/waitlists/api'

import API from '~/apis/client'

export async function create(payload: CreateRequest) {
  return await API.handle(
    API.public.post<CreateResponse>(`/public/v1/waitlists`, payload)
  )
}
