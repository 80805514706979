import { useEffect } from 'react'
import { Pressable, TextInput, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import {
  privateSearch as privateSearchCall,
  search as searchCall,
} from '~/store/search'
import { RootState, useAppDispatch, useAppSelector } from '~/store/store'

import { useSearchContext } from '~/components/SearchContext'
import Text from '~/components/StyledText'

import { useDebounce } from '~/hooks/useDebounce'
import ArrowRight from '~/svg/ArrowRight'
import CartIcon from '~/svg/Cart'
import Hamburger from '~/svg/Hamburger'

interface IProps {
  navigation: any
  backVisible?: true
  fallback?: string
}

const AppHeader: React.FC<IProps> = ({
  navigation,
  backVisible,
  fallback = 'Home',
}) => {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()

  const { cart } = useAppSelector((state: RootState) => state.cart)
  const { authenticated } = useAppSelector((state: RootState) => state.auth)

  const { searchTerm, setSearchTerm, setIsTyping, search, resetSearch } =
    useSearchContext()

  const debouncedSearchTerm = useDebounce(
    searchTerm,
    300,
    (debouncing: boolean) => {
      setIsTyping(debouncing)
    }
  )
  useEffect(() => {
    if (debouncedSearchTerm && searchTerm) {
      search({ query: searchTerm })
    } else {
      resetSearch()
    }
  }, [debouncedSearchTerm])

  return (
    <View>
      <View style={tailwind('flex flex-row items-center my-3 mx-4')}>
        {backVisible && searchTerm === '' && (
          <View style={tailwind('mr-5')}>
            <Pressable
              onPress={() => {
                resetSearch()
                navigation.canGoBack()
                  ? navigation.goBack()
                  : navigation.navigate(fallback)
              }}
            >
              <ArrowRight />
            </Pressable>
          </View>
        )}

        {searchTerm !== '' && (
          <View style={tailwind('mr-5')}>
            <Pressable onPress={() => setSearchTerm('')}>
              <ArrowRight />
            </Pressable>
          </View>
        )}

        <TextInput
          style={[
            tailwind('px-2 w-full border rounded border-teal'),
            { height: '36px' },
          ]}
          placeholder={'Cari nama produk'}
          onChangeText={(text: string) => setSearchTerm(text)}
          value={searchTerm}
          returnKeyType="search"
          onSubmitEditing={() =>
            authenticated
              ? dispatch(privateSearchCall({ query: searchTerm }))
              : dispatch(searchCall({ query: searchTerm }))
          }
        />

        <View style={tailwind('ml-5 relative')}>
          <Pressable
            onPress={() => {
              authenticated
                ? navigation.navigate('Cart')
                : navigation.navigate('Login')
            }}
          >
            <CartIcon />

            {authenticated &&
              cart?.CartItems &&
              cart?.CartItems.filter((item) => item.ownerType === 'productItem')
                .length > 0 && (
                <View
                  style={[
                    tailwind('absolute flex items-center justify-center p-2'),
                    {
                      width: 10,
                      height: 14,
                      backgroundColor: '#E05151',
                      right: '-5px',
                      borderRadius: 16,
                    },
                  ]}
                >
                  <Text
                    style={[tailwind('text-white text-xs'), { fontSize: 11 }]}
                  >
                    {
                      cart.CartItems.filter(
                        (item) => item.ownerType === 'productItem'
                      ).length
                    }
                  </Text>
                </View>
              )}
          </Pressable>
        </View>

        {/* <View style={tailwind("ml-5")}>
          <Pressable
            onPress={() => { }}
          >
            <Bell />
          </Pressable>
        </View> */}

        <View style={tailwind('ml-4')}>
          <Pressable
            onPress={() => navigation.toggleDrawer && navigation.toggleDrawer()}
          >
            <Hamburger />
          </Pressable>
        </View>
      </View>
    </View>
  )
}

export default AppHeader
