import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Image, Pressable, ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { useAppDispatch } from '~/store/store'

import Container from '~/components/Container'
import Text from '~/components/StyledText'
import Button from '~/components/base/Button/Button'
import Input from '~/components/base/Input'
import BackHeader from '~/components/headers/BackHeader'

import { RootStackProps } from '~/navigation/types'

export default function ResetPasswordSuccess({
  route,
  navigation,
}: RootStackProps<'ResetPassword'>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()
  const params = route.params

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()
  // { resolver: zodResolver(CreateRequestSchema) }
  const isError = Object.keys(errors)?.length > 0

  const onSubmit = async (data: any) => {
    //
  }

  useEffect(() => {
    if (!params?.token) {
      // Validate
    }
  }, [params?.token])

  console.log('params', params)

  return (
    <Container>
      <BackHeader navigation={navigation} text={'Reset Password'} />

      <ScrollView
        style={[
          tailwind('pt-14 flex flex-col w-full relative'),
          {
            height: 'calc(100% - 60px)',
            paddingBottom: 20,
          },
        ]}
        contentContainerStyle={{
          height: '100%',
        }}
      >
        <View
          style={[
            tailwind('pt-6 px-4 relative'),
            {
              height: 'calc(100% - 60px)',
            },
          ]}
        >
          <Image
            source={require('~/assets/images/bioma-logo.png')}
            style={[
              {
                width: 72,
                height: 20,
                resizeMode: 'contain',
              },
              tailwind('mb-4 p-2'),
            ]}
          />
          <Text
            style={[
              tailwind('mb-5 text-3xl font-bold'),
              {
                color: '#00433E',
              },
            ]}
          >
            Reset Password Berhasil
          </Text>

          <Text style={tailwind('text-base mb-5')}>
            Kamu telah berhasil melakukan reset password. Silahkan lakukan login
            untuk melakukan transaksi.
          </Text>
        </View>

        <View style={[tailwind('sticky bottom-1.5 w-full px-4')]}>
          <Button
            color="primary"
            text="Kembali Login"
            style={tailwind('text-center py-3')}
            onPress={() => navigation.navigate('Login')}
          />
        </View>
      </ScrollView>
    </Container>
  )
}
