import { useEffect, useMemo } from 'react'
import { ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { getAll } from '~/store/product'
import { getAll as getAllProductType } from '~/store/productType'
import { RootState, useAppDispatch, useAppSelector } from '~/store/store'

import Container from '~/components/Container'
import { SearchContextProvider } from '~/components/SearchContext'
import SearchResult from '~/components/SearchResult'
import Text from '~/components/StyledText'
import AppHeader from '~/components/headers/AppHeader'
import ProductTile from '~/components/products/ProductTile'

import { DashboardProps } from '~/navigation/types'

export default function Category({
  route,
  navigation,
}: DashboardProps<'Category'>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()

  const { product_type_id } = route.params
  const { products } = useAppSelector((state: RootState) => state.product)
  const { productTypes } = useAppSelector(
    (state: RootState) => state.productType
  )

  const productType = useMemo(
    () => productTypes.find((item) => item.id === product_type_id),
    [productTypes, product_type_id]
  )

  useEffect(() => {
    dispatch(getAll({ productTypeId: product_type_id }))
    dispatch(getAllProductType())
  }, [])

  return (
    <SearchContextProvider>
      <Container>
        <AppHeader navigation={navigation} backVisible={true} />
        <ScrollView style={tailwind('w-full flex flex-col')}>
          {/* <Categories navigation={navigation} /> */}
          <SearchResult navigation={navigation} />
          <View style={tailwind('flex flex-col')}>
            <Text style={tailwind('font-bold text-xl px-4')}>
              {productType?.name}
            </Text>
            <View style={tailwind('flex flex-row flex-wrap')}>
              {products.map((product, i) => (
                <View style={tailwind('w-6/12 p-4')} key={i}>
                  <ProductTile
                    product={product}
                    navigation={navigation}
                    height={168}
                  />
                </View>
              ))}
            </View>
          </View>
        </ScrollView>
      </Container>
    </SearchContextProvider>
  )
}
