import {
  CreateRequest,
  CreateResponse,
} from '@withbioma/apitype-publicapi-customer/lib/v1/carts/cart_items/api'

import API from '~/apis/client'

export async function create(payload: CreateRequest, cartId: number) {
  return await API.handle(
    API.private.post<CreateResponse>(
      `/customer/v1/carts/${cartId}/cart_items`,
      payload
    )
  )
}

export async function remove(cartId: number, cartItemId: number) {
  return await API.handle(
    API.private.delete<{}>(
      `/customer/v1/carts/${cartId}/cart_items/${cartItemId}`
    )
  )
}
