import {
  ConfirmRequest,
  ConfirmResponse,
  GetResponse,
} from '@withbioma/apitype-publicapi-public/lib/v1/orders/api'

import API from '~/apis/client'

export async function publicConfirm(
  orderConfirmationUuid: string,
  payload: ConfirmRequest
) {
  return await API.handle(
    API.public.post<ConfirmResponse>(
      `/public/v1/orders/${orderConfirmationUuid}/confirm`,
      payload
    )
  )
}

export async function publicGet(orderConfirmationUuid: string) {
  return await API.handle(
    API.public.get<GetResponse>(`/public/v1/orders/${orderConfirmationUuid}`)
  )
}
