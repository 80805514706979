import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ProductAttributesFull } from '@withbioma/apitype-publicapi-public/lib/v1/common/product'

import {
  constructErrorPayload,
  constructExtraReducer,
  constructSuccessPayload,
} from '~/store/helper'

import { get as getCall } from '~/apis/productItems'

type ProductItem = NonNullable<ProductAttributesFull['ProductItems']>[number]

interface IState {
  productItem?: ProductItem
  loading: Record<string, any>
  responses: Record<string, any>
}
const initialState: IState = {
  productItem: undefined,
  loading: {},
  responses: {},
}

export const get = createAsyncThunk(
  'productItem/get',
  async (
    {
      productId,
      productItemId,
    }: {
      productId: string
      productItemId: string
    },
    { rejectWithValue }
  ) => {
    const [res, resErr] = await getCall(productId, productItemId)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const reducerSlice = createSlice({
  name: 'productItem',
  initialState,
  reducers: {
    resetResponse(state: IState, action: PayloadAction<any>) {
      const apiName = action.payload
      state.responses = {
        ...state.responses,
        [apiName]: null,
      }
    },
    resetProductItem(state: IState) {
      state.productItem = undefined
    },
  },
  extraReducers: (builder) => {
    constructExtraReducer(
      builder,
      get,
      (state: IState, action: PayloadAction<any>) => {
        state.productItem = action.payload.body.productItem
      }
    )
  },
})

export const { resetResponse, resetProductItem } = reducerSlice.actions

export default reducerSlice.reducer
