import { PropsWithChildren } from 'react'
import {
  GestureResponderEvent,
  Pressable,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import Text from '~/components/StyledText'

import { isFunction } from '~/lib/validation'

type Color = 'primary' | 'error' | 'mute'
type Variant = 'contained' | 'outlined'

type Props = {
  text?: string
  style?: StyleProp<ViewStyle>
  onPress?: (e: GestureResponderEvent) => void
  disabled?: boolean
  color?: Color
  variant?: Variant
}

export default function Button({
  text,
  style,
  onPress,
  children,
  disabled = false,
  color = 'primary',
  variant = 'contained',
}: PropsWithChildren<Props>) {
  const tailwind = useTailwind()

  let classes = ''

  // Set background
  if (!disabled && color == 'primary' && variant == 'contained') {
    classes = 'bg-aero'
  } else if (!disabled && color == 'mute' && variant == 'contained') {
    classes = 'bg-lightGray'
  } else if (disabled) {
    classes = 'bg-lightGray'
  } else {
    classes = 'bg-transparent'
  }

  // Set border
  if (color == 'primary' && variant == 'outlined') {
    classes += ' border rounded border-aero-900'
  } else if (color == 'mute' && variant == 'outlined') {
    classes += ' border rounded border-black'
  } else {
    classes += ' border-transparent'
  }

  return (
    <Pressable
      disabled={disabled}
      onPress={(event) => {
        if (!disabled && isFunction(onPress)) {
          onPress(event)
        }
      }}
      style={[
        tailwind(`py-2 px-5 rounded ${classes}
          `),
        style,
      ]}
    >
      {!text && children}

      {text && (
        <Text
          style={tailwind(
            `text-center ${
              !disabled
                ? color == 'primary'
                  ? 'text-aero-900'
                  : 'text-black'
                : 'text-lightGray-600'
            }`
          )}
          children={text}
        />
      )}
    </Pressable>
  )
}
