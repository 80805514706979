import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  constructErrorPayload,
  constructExtraReducer,
  constructSuccessPayload,
} from '~/store/helper'

import {
  getAllByParam as getAllByParamCall,
  getAll as getAllCall,
} from '~/apis/productTypes'

interface IState {
  productTypes: Record<string, any>[]
  loading: Record<string, any>
  responses: Record<string, any>
}
const initialState: IState = {
  productTypes: [],
  loading: {},
  responses: {},
}

export const getAll = createAsyncThunk(
  'productType/getAll',
  async (_, { rejectWithValue }) => {
    const [res, resErr] = await getAllCall()
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const getAllByParam = createAsyncThunk(
  'productType/getAllByParam',
  async (name: string, { rejectWithValue }) => {
    const [res, resErr] = await getAllByParamCall({ name })
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const reducerSlice = createSlice({
  name: 'productType',
  initialState,
  reducers: {
    resetResponse(state: IState, action: PayloadAction<any>) {
      const apiName = action.payload
      state.responses = {
        ...state.responses,
        [apiName]: null,
      }
    },
    resetProductTypes(state: IState) {
      state.productTypes = []
    },
  },
  extraReducers: (builder) => {
    constructExtraReducer(
      builder,
      getAll,
      (state: IState, action: PayloadAction<any>) => {
        state.productTypes = action.payload.body.productTypes
      }
    )
    constructExtraReducer(
      builder,
      getAllByParam,
      (state: IState, action: PayloadAction<any>) => {
        state.productTypes = action.payload.body.productTypes
      }
    )
  },
})

export const { resetResponse, resetProductTypes } = reducerSlice.actions
export default reducerSlice.reducer
