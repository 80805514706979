export const Regex = {
  phoneID: /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3,4}[\s.-]?\d{4}$/g,
}

export const passwordValidator = (password: string, candidate?: string) => {
  const config = {
    minLen: 6,
    commonPasswordPatterns:
      /passw.*|12345.*|09876.*|qwert.*|asdfg.*|zxcvb.*|footb.*|baseb.*|drago.*/, // optional
  }

  let result = {
    isValid: false,
    message: '',
  }

  // Build up the strenth of our password
  let numberOfElements = 0
  numberOfElements = /.*[a-z].*/.test(password)
    ? ++numberOfElements
    : numberOfElements // Lowercase letters
  numberOfElements = /.*[A-Z].*/.test(password)
    ? ++numberOfElements
    : numberOfElements // Uppercase letters
  numberOfElements = /.*[0-9].*/.test(password)
    ? ++numberOfElements
    : numberOfElements // Numbers
  numberOfElements = /[^a-zA-Z0-9]/.test(password)
    ? ++numberOfElements
    : numberOfElements // Special characters (inc. space)

  function isPasswordCommon(password: string): boolean {
    return config.commonPasswordPatterns.test(password)
  }

  // More strict
  // else if (isPasswordCommon(password) === true) {
  //   // Common
  // } else if (numberOfElements === 0 || numberOfElements === 1 || numberOfElements === 2) {
  //   // Weak
  // } else if (numberOfElements === 3) {
  //   // Ok
  // } else {
  //   // Strong
  // }

  // Check then strength of this password using some simple rules
  if (password === null || password.length < config.minLen) {
    result.message = 'Password minimal 6 karakter'
  } else if (candidate && password !== candidate) {
    result.message = 'Password kamu tidak sama, silahkan masukkan lagi.'
  } else {
    result = {
      isValid: true,
      message: 'Valid',
    }
  }

  return result
}
