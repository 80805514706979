import {
  GetAllQueryString,
  GetAllResponse,
} from '@withbioma/apitype-publicapi-public/lib/v1/product_types/api'

import API from '~/apis/client'
import { useCachedResponse } from '~/apis/utils'

export async function getAll() {
  return await API.handle(
    API.private.get<GetAllResponse>(`/public/v1/product_types`, {
      headers: useCachedResponse,
    })
  )
}

export async function getAllByParam(params: GetAllQueryString) {
  return await API.handle(
    API.private.get<GetAllResponse>(`/public/v1/product_types`, {
      params,
      headers: useCachedResponse,
    })
  )
}
