import { useActionSheet } from '@expo/react-native-action-sheet'
import React, { useState } from 'react'
import { Pressable, View } from 'react-native'
import { ChevronDownIcon, ChevronUpIcon } from 'react-native-heroicons/solid'
import { useTailwind } from 'tailwind-rn/dist'

import Text from '~/components/StyledText'

type Props = {
  items: string[]
  onSelected: (index: number, state: string) => void
  selectedState: string
}

export default function ActionSheet({
  items,
  onSelected,
  selectedState,
}: Props) {
  const tailwind = useTailwind()
  const { showActionSheetWithOptions } = useActionSheet()

  const [opened, setOpened] = useState(false)

  const openSheet = () => {
    setOpened(true)

    const options = items

    showActionSheetWithOptions(
      {
        options,
        // cancelButtonIndex: options.length - 1,
        title: 'Status Transaksi',
        // Android only
        titleTextStyle: {
          fontWeight: 'bold',
          color: '#333333',
        },
        textStyle: {
          display: 'flex',
          flexDirection: 'row-reverse',
        },
        containerStyle: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          paddingLeft: 15,
          paddingRight: 15,
          paddingBottom: 10,
        },
        // Android only; default is true
        showSeparators: true,
      },
      (buttonIndex) => {
        // Do something here depending on the button index selected
        onSelected(buttonIndex!, options[buttonIndex!])

        // Toggle state
        setOpened(false)
      }
    )
  }

  return (
    <View style={tailwind('px-4 pt-3')}>
      <Pressable
        style={[
          tailwind('flex flex-row items-center justify-center'),
          {
            maxWidth: 172,
            height: 28,
            borderWidth: 1,
            borderColor: '#00443E',
            borderRadius: 16,
            padding: 10,
            backgroundColor: opened ? '#B2E2DF' : '#fff',
          },
        ]}
        onPress={() => openSheet()}
      >
        <Text
          style={[
            tailwind('text-xs pr-2'),
            {
              fontSize: 11,
            },
          ]}
        >
          {selectedState}
        </Text>

        {!opened ? (
          <ChevronDownIcon width={14} height={14} />
        ) : (
          <ChevronUpIcon width={14} height={14} />
        )}
      </Pressable>
    </View>
  )
}
