import React, { useMemo } from 'react'
import { useTailwind } from 'tailwind-rn'

import { RootState, useAppSelector } from '~/store/store'

import Text from '~/components/StyledText'

import { toCurrency } from '~/lib/money'
import { convertToWeeklyRate } from '~/lib/pricingRate'

interface IProps {
  selectedProductItemId: number
  pricingRates: any[]
  selectedDuration: number
}

export default function PriceVariable({
  selectedProductItemId,
  pricingRates,
  selectedDuration,
}: IProps) {
  const tailwind = useTailwind()

  const { product } = useAppSelector((state: RootState) => state.product)

  const pricingRate = useMemo(() => {
    if (pricingRates && pricingRates.length)
      return pricingRates.find((value) => value?.id == selectedDuration)
  }, [pricingRates, selectedDuration])

  const pricingRateRange: number[] | undefined = useMemo(() => {
    if (product) {
      let min = Number.MAX_VALUE
      let max = 0

      product.ProductItems?.forEach((productItem: any) => {
        productItem.PricingRates.forEach((pricingRate: any) => {
          const pricePerWeek = convertToWeeklyRate(pricingRate)
          min = Math.min(min, pricePerWeek)
          max = Math.max(max, pricePerWeek)
        })
      })

      return [min, max]
    }
    return [0, 0]
  }, [product])

  if (!pricingRates)
    return <Text style={tailwind('text-base font-bold mt-1')}>Rp.0/minggu</Text>

  return (
    <Text style={tailwind('text-base font-bold mt-1')}>
      {/* :: Make sure, have selected variant & product item */}
      {selectedProductItemId && pricingRate
        ? toCurrency(pricingRate?.pricePerWeek)
        : pricingRateRange[0] !== pricingRateRange[1]
        ? `${toCurrency(pricingRateRange[0])} - ${toCurrency(
            pricingRateRange[1]
          )}`
        : toCurrency(pricingRateRange[0])}
      /minggu
    </Text>
  )
}
