import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import { openBrowserAsync } from 'expo-web-browser'
import { useCallback, useState } from 'react'
import { Pressable, ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import { useAppDispatch } from '~/store/store'

import Container from '~/components/Container'
import Text from '~/components/StyledText'

import { create as createInvoice } from '~/apis/xendit'
import { RootStackProps } from '~/navigation/types'

const order = {
  id: 205,
  status: 'created',
  customerId: 23,
  deliveryAddress: 'Jl. Bedugul',
  deliveryAddressZipcode: '80119',
  deliveryAddressInstructions: 'RT  10',
  deliveryAddressProvince: 'SUMATERA UTARA',
  deliveryAddressRegency: 'KABUPATEN TAPANULI SELATAN',
  deliveryAddressDistrict: 'ANGKOLA SELATAN',
  pickupAddress: 'Jl. Bedugul',
  pickupAddressZipcode: '80119',
  pickupAddressInstructions: 'RT  10',
  pickupAddressProvince: 'SUMATERA UTARA',
  pickupAddressRegency: 'KABUPATEN TAPANULI SELATAN',
  pickupAddressDistrict: 'ANGKOLA SELATAN',
  internalNotes: 'CEpat ya',
  OrderItems: [
    {
      id: 168,
      orderId: 205,
      ownerType: 'productItem',
      ownerId: 2,
      assetId: 5,
      startsAt: '2022-07-05T16:00:00.000Z',
      price: 500000,
      ProductItem: {
        id: 2,
        productId: 2,
        description: 'Desc',
        imageOrdering: [1, 2],
        retailPrice: 50000,
        lengthCm: 20,
        heightCm: 20,
        widthCm: 20,
        weightKg: 20,
        isActive: true,
        Product: {
          id: 2,
          name: 'Sepeda BMX',
          brand: 'Mercedez Benz',
          description: 'Sepeda Description',
          imageOrdering: [2, 3],
        },
      },
    },
    {
      id: 169,
      orderId: 205,
      ownerType: 'productItem',
      ownerId: 3,
      assetId: 4,
      startsAt: '2022-07-05T16:00:00.000Z',
      price: 250000,
      ProductItem: {
        id: 3,
        productId: 3,
        description: 'Product items desc 2',
        imageOrdering: [1, 2],
        retailPrice: 15000,
        lengthCm: 25,
        heightCm: 20,
        widthCm: 24,
        weightKg: 2,
        isActive: true,
        Product: {
          id: 3,
          name: 'Sepeda Gunung',
          brand: 'Federal',
          description: 'Sepeda Gunung Desc',
          imageOrdering: [2, 3],
        },
      },
    },
    {
      id: 170,
      orderId: 205,
      ownerType: 'addOn',
      ownerId: 13,
      price: 150000,
      AddOn: {
        id: 13,
        name: 'Paket Regular',
        price: 150000,
      },
    },
  ],
  Customer: {
    id: 23,
    name: 'Alfin',
    userId: 5,
    addressId: 135,
    User: {
      id: 5,
      credentialUuid: 'dd2e3bdb-5c4e-45c4-9a91-d535419ea0d8',
      email: 'alfins132@gmail.com',
      phone: '+628814715695',
    },
  },
}

const Xendit = ({ navigation }: RootStackProps<'Waitlist'>) => {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()

  const isFocused = useIsFocused()

  const [invoiceUrl, setInvoiceUrl] = useState<string>('')

  const onSubmit = async () => {
    try {
      const externalID = `${order.id}_${
        order.customerId
      }_${Date.now().toString()}`

      const mappedOrderItems = order.OrderItems.map((order) => {
        return {
          name: order.ProductItem?.Product.name || order?.AddOn?.name,
          quantity: 1,
          price: order.price,
        }
      })

      // @see https://developers.xendit.co/api-reference/#create-invoice
      const createdInvoice = await createInvoice({
        externalID,
        payerEmail: order.Customer.User.email,
        description: `Invoice for ${externalID}`,
        // amount > 100000 can't use payment methods: grab, gopay, except bank
        amount: 50000,
        customer: {
          given_names: order.Customer.name,
          email: order.Customer.User.email,
          mobile_number: order.Customer.User.phone,
          address: order.deliveryAddress,
        },
        items: mappedOrderItems,
      })
      const result = createdInvoice[0]?.data as any
      console.log('createdInvoice result', result)

      if (createdInvoice && Object.keys(createdInvoice).length > 0) {
        const { transaction } = result
        const { expiry_date, invoice_url } = transaction

        // TODO: check expiry_date
        if (invoice_url) {
          setInvoiceUrl(invoice_url)

          await openBrowserAsync(invoice_url, {
            showInRecents: true,
          })
        }
      }

      // Test url
      // await openBrowserAsync(`https://checkout-staging.xendit.co/web/62c4df12eae62be1cf129c96`, {
      //   showInRecents: true,
      // })
    } catch (error) {
      console.error('Error createInvoice', error)
    }
  }

  useFocusEffect(
    useCallback(() => {
      console.log('invoiceUrl', invoiceUrl)
    }, [invoiceUrl])
  )

  return (
    <Container>
      <Text style={tailwind('pt-8 text-md px-4 mb-2 font-bold')}>
        Order Responses:
      </Text>
      <ScrollView
        overScrollMode="always"
        scrollEnabled
        style={{
          maxHeight: 150,
        }}
      >
        <View style={tailwind('pt-2 px-4 h-full relative')}>
          <Text style={tailwind('text-sm mb-2')}>{JSON.stringify(order)}</Text>
        </View>
      </ScrollView>

      <ScrollView
        style={[
          tailwind('pt-8 flex flex-col w-full relative'),
          {
            backgroundColor: '#fff',
            color: '#000',
            height: '100%',
            paddingBottom: 20,
          },
        ]}
        contentContainerStyle={{
          height: '100%',
        }}
      >
        <View style={tailwind('pt-2 px-4 h-full relative')}>
          <Text style={tailwind('text-2xl mb-2 font-bold')}>
            Xendit Integration
          </Text>
          <Text style={tailwind('mb-5')}>
            After successfull created orders, will invoke `/xendit_tests` API to
            createInvoice. Then after get response from createInvoice, user will
            redirect or copying link to invoice url and choose payment. If paid,
            user will redirect back to our app (from callback url or
            `successRedirectURL`)
          </Text>
          <Pressable
            style={[
              tailwind('rounded p-3 text-center'),
              {
                backgroundColor: '#B2E2DF',
                width: 180,
                height: 40,
              },
            ]}
            onPress={() => onSubmit()}
          >
            <Text>Make Invoice</Text>
          </Pressable>
          <Text style={tailwind('mt-4')}>Invoice url: {invoiceUrl}</Text>
        </View>
      </ScrollView>
    </Container>
  )
}

export default Xendit
