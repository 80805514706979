import {
  UpdateRequest,
  UpdateResponse,
} from '@withbioma/apitype-publicapi-customer/lib/v1/account/addresses/api'
import { GetResponse } from '@withbioma/apitype-publicapi-customer/lib/v1/account/api'
import {
  CreateRequest,
  CreateResponse,
} from '@withbioma/apitype-publicapi-public/lib/v1/customers/api'

import API from '~/apis/client'

export async function get() {
  return await API.handle(API.private.get<GetResponse>(`/customer/v1/account`))
}

export async function register(payload: CreateRequest) {
  return await API.handle(
    API.public.post<CreateResponse>('/public/v1/customers', payload)
  )
}

export async function updateAddress(payload: UpdateRequest) {
  return await API.handle(
    API.private.patch<UpdateResponse>('/customer/v1/account/addresses', payload)
  )
}
