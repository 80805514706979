import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Animated,
  Dimensions,
  FlatList,
  Image,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import SwiperFlatList, { Pagination } from 'react-native-swiper-flatlist'

const { width: DimensionsWidth, height: DimensionsHeight } =
  Dimensions.get('window')

const WIDTH = 375
const HEIGHT = 390

interface ImageSliderProps<T> {
  data: {
    id: number | string
    image: string
  }[]
  imageOrdering?: number[]
}

const ImageSlider = <T,>(props: ImageSliderProps<T>) => {
  const { data, imageOrdering } = props

  const [currentIndex, setCurrentIndex] = useState(0)

  const images = useMemo(() => {
    if (!imageOrdering?.length)
      return data.map((val) => ({
        image: val.image,
      }))

    return data
      .slice()
      .sort(
        (a, b) => imageOrdering.indexOf(+a.id) - imageOrdering.indexOf(+b.id)
      )
      .map((val) => ({
        image: val.image,
      }))
  }, [imageOrdering])

  const keyExtractor = useCallback((_, index) => index.toString(), [])

  return (
    <View style={styles.container}>
      <SwiperFlatList
        autoplay
        autoplayDelay={5}
        index={0}
        autoplayLoop
        autoplayInvertDirection
        maxToRenderPerBatch={1}
        data={images}
        renderItem={({ item }) => (
          <View style={styles.imageContainer}>
            <Image style={styles.image} source={item.image} />
          </View>
        )}
        centerContent
        keyExtractor={keyExtractor}
        style={styles.flatListContainer}
        showPagination
        PaginationComponent={Indicator}
        onChangeIndex={({ index }) => {
          setCurrentIndex(index)
        }}
      />

      <Counter index={currentIndex} total={images.length} />
    </View>
  )
}

const Indicator = (props: any) => {
  return (
    <Pagination
      {...props}
      paginationStyle={styles.paginationContainer}
      paginationStyleItem={styles.pagination}
      paginationDefaultColor="rgb(223, 231, 245)"
      paginationActiveColor="rgb(178, 226, 223)"
    />
  )
}

const Counter = (props: { index: number; total: number }) => {
  const { index, total } = props

  return (
    <View style={styles.counter}>
      <Text style={styles.counterTxt} numberOfLines={1}>
        {index + 1}/{total}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    borderRadius: 16,
  },
  flatListContainer: {
    borderRadius: 16,
  },
  imageContainer: {
    height: HEIGHT,
    width: DimensionsWidth,
  },
  image: {
    width: WIDTH,
    height: HEIGHT,
    resizeMode: 'cover',
  },
  paginationContainer: {
    bottom: -75,
    borderRadius: 100,
  },
  pagination: {
    height: 8,
    width: 8,
    marginHorizontal: 5,
  },
  counter: {
    position: 'absolute',
    top: 15,
    right: 30,
    backgroundColor: 'rgba(32, 32, 32, 0.75)',
    borderRadius: 24,
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  counterTxt: {
    fontSize: 13,
    color: 'white',
    fontWeight: '600',
  },
})

export default ImageSlider
