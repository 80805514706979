import { createStackNavigator } from '@react-navigation/stack'

import OrderScreen from '~/screens/Order'

import { OrderParamList } from '~/navigation/types'

const Order = createStackNavigator<OrderParamList>()

function OrderStackNavigator() {
  return (
    <Order.Navigator initialRouteName="Order">
      <Order.Screen
        name="Order"
        component={OrderScreen}
        options={{
          headerShown: false,
        }}
      />
    </Order.Navigator>
  )
}
export default OrderStackNavigator
