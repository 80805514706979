import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const ChevronRight = (props: any) => (
  <Svg
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path d="M9 5l7 7-7 7" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
)

export default ChevronRight
