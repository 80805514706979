import { useEffect } from 'react'
import { ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { getAll } from '~/store/product'
import { RootState, useAppDispatch, useAppSelector } from '~/store/store'

import Container from '~/components/Container'
import SearchResult from '~/components/SearchResult'
import Text from '~/components/StyledText'
import AppHeader from '~/components/headers/AppHeader'
import ProductTile from '~/components/products/ProductTile'

import { DashboardProps } from '~/navigation/types'

export default function Search({
  route,
  navigation,
}: DashboardProps<'Search'>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()
  const { name } = route.params

  const { products } = useAppSelector((state: RootState) => state.product)

  useEffect(() => {
    dispatch(getAll({ name }))
  }, [])

  return (
    <Container>
      <ScrollView style={tailwind('w-full flex flex-col')}>
        <AppHeader navigation={navigation} backVisible />
        <SearchResult navigation={navigation} />
        <View style={tailwind('flex flex-col')}>
          <Text style={tailwind('text-lg mt-4 px-4')}>Produk di Bioma</Text>
          <View style={tailwind('flex flex-row flex-wrap pl-4 mt-4')}>
            {products.map((product, i) => (
              <ProductTile
                product={product}
                navigation={navigation}
                key={i}
                height={160}
              />
            ))}
          </View>
        </View>
      </ScrollView>
    </Container>
  )
}
