import { DefaultTheme, NavigationContainer } from '@react-navigation/native'
import { Settings } from 'luxon'

import { useAppDispatch } from '~/store/store'

import { getCurrentCart } from '~/store/cart'
import { get } from '~/store/user'

import DrawerNavigator from '~/navigation/DrawerNavigator'
import LinkingConfiguration from '~/navigation/LinkingConfiguration'

export default function Navigation() {
  // Note: retrieve current user on app load. Check if this approach is safe or not.
  const dispatch = useAppDispatch()

  dispatch(get())
  dispatch(getCurrentCart())

  // Set Luxon's Locale
  Settings.defaultLocale = 'id'

  // Configure the default time zone
  Settings.defaultZone = 'Asia/Jakarta'

  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: 'rgb(31, 41, 55)',
        },
      }}
      onStateChange={(state) => {
        // Do every navigation state change
        dispatch(get())
        dispatch(getCurrentCart())
      }}
    >
      <DrawerNavigator />
    </NavigationContainer>
  )
}
