import { zodResolver } from '@hookform/resolvers/zod'
import { CreateRequestSchema } from '@withbioma/apitype-publicapi-public/lib/v1/customers/api'
import Checkbox from 'expo-checkbox'
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Image, Pressable, TouchableOpacity, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { useAppDispatch } from '~/store/store'
import { register } from '~/store/user'

import Container from '~/components/Container'
import Text from '~/components/StyledText'
import Input from '~/components/base/Input'
import InputPassword from '~/components/base/InputPassword'
import Toast, { IRef } from '~/components/base/Toast'
import BackHeader from '~/components/headers/BackHeader'

import { RootStackProps } from '~/navigation/types'
import ChevronRight from '~/svg/ChevronRight'

export default function TabOneScreen({
  route,
  navigation,
}: RootStackProps<any>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()
  const params = route.params

  const toastRef = useRef<IRef>(null)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: zodResolver(CreateRequestSchema) })

  const [step, setStep] = useState(1)
  const [passVisibleState, setVisiblePass] = useState(false)
  const [passConfirmVisibleState, setVisiblePassConfirm] = useState(false)
  const [checkedState, setChecked] = useState(false)

  const isFirstStep = step == 1
  const isLastStep = step == 2

  const onSubmit = async (data: any) => {
    if (data.password === data.confirmPassword) {
      let res = await dispatch(register(data))
      if (register.fulfilled.match(res)) {
        navigation.navigate('RegisterSuccess')

        if (params?.redirect_from) {
          navigation.navigate('RegisterSuccess', {
            redirect_from: params.redirect_from,
            param_name: params?.param_name,
            param_value: params?.param_value,
          })
        } else {
          navigation.navigate('RegisterSuccess')
        }
      } else {
        console.log(res)
        toastRef.current?.open((res?.payload as any).body?.message)
      }
    }
  }

  const handleNextStep = () => {
    setStep((step) => {
      if (step < 2) return step + 1

      handleSubmit(onSubmit)()

      return step
    })
  }

  const resetStep = () => setStep(1)

  return (
    <Container>
      <BackHeader
        navigation={navigation}
        text={'Daftar Akun'}
        overidePrev={step === 2 && resetStep}
      />
      <View
        style={[
          tailwind('p-4 pt-20 flex-col h-full relative'),
          {
            backgroundColor: '#D7DDAE',
          },
        ]}
      >
        <Toast ref={toastRef} color="danger" />
        <Image
          source={require('~/assets/images/bioma-logo.png')}
          style={[
            {
              width: 72,
              height: 20,
              resizeMode: 'contain',
            },
            tailwind('mb-4 p-2'),
          ]}
        />
        <Text
          style={[
            tailwind('mb-5 text-3xl font-bold'),
            {
              color: '#00433E',
            },
          ]}
        >
          {isFirstStep ? 'Tak kenal maka tak sayang' : 'Masukkan Password Kamu'}
        </Text>

        {isLastStep && (
          <Text style={tailwind('text-base mb-5')}>
            Gunakan beberapa gabungan huruf, angka dan beberapa karakter untuk
            keamanan akun kamu
          </Text>
        )}

        <View
          style={[
            tailwind('mb-2 relative'),
            {
              height: `calc(100% - ${isFirstStep ? '200px' : '258px'})`,
            },
          ]}
        >
          {isFirstStep && (
            <React.Fragment>
              <View style={tailwind('mb-3')}>
                <Text style={tailwind('mb-2')}>Nama Lengkap</Text>
                <Input
                  style={[
                    tailwind('p-4 pr-6 w-full bg-white border rounded'),
                    { borderColor: 'transparent' },
                  ]}
                  placeholder={'Nama Lengkap'}
                  control={control}
                  name="name"
                  errors={errors}
                  rules={{}}
                  defaultValue=""
                />
              </View>

              <View style={tailwind('mb-3')}>
                <Text style={tailwind('mb-2')}>Email</Text>
                <Input
                  style={[
                    tailwind('p-4 pr-6 w-full bg-white border rounded'),
                    { borderColor: 'transparent' },
                  ]}
                  placeholder={'Email'}
                  keyboardType="email-address"
                  control={control}
                  name="email"
                  errors={errors}
                  rules={{}}
                  defaultValue=""
                />
              </View>

              <Text style={tailwind('mb-2')}>No. HP</Text>
              <Input
                style={[
                  tailwind('p-4 pr-6 w-full bg-white border rounded'),
                  { borderColor: 'transparent' },
                ]}
                placeholder={'Nomor Handphone'}
                keyboardType="phone-pad"
                control={control}
                name="phone"
                errors={errors}
                rules={{}}
                defaultValue=""
              />
            </React.Fragment>
          )}

          {isLastStep && (
            <React.Fragment>
              <View style={tailwind('mb-3')}>
                <InputPassword
                  style={[
                    tailwind('p-4 pr-6 w-full bg-white border rounded'),
                    { borderColor: 'transparent' },
                  ]}
                  label="Password"
                  placeholder={'Masukkan Password'}
                  control={control}
                  rules={{
                    maxLength: 100,
                    required: true,
                  }}
                  name="password"
                  defaultValue=""
                  passwordVisible={passVisibleState}
                  onTogglePasswordVisible={() =>
                    setVisiblePass(!passVisibleState)
                  }
                  errors={errors}
                />
              </View>

              <InputPassword
                style={[
                  tailwind('p-4 pr-6 w-full bg-white border rounded'),
                  { borderColor: 'transparent' },
                ]}
                label="Masukkan Password"
                placeholder={'Konfirmasi Password'}
                control={control}
                rules={{
                  maxLength: 100,
                  required: true,
                }}
                name="confirmPassword"
                defaultValue=""
                passwordVisible={passConfirmVisibleState}
                onTogglePasswordVisible={() =>
                  setVisiblePassConfirm(!passConfirmVisibleState)
                }
                errors={errors}
              />
            </React.Fragment>
          )}
        </View>

        <View
          style={[
            tailwind('sticky bottom-5 flex items-end justify-end w-full'),
          ]}
        >
          {isLastStep && (
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => setChecked(!checkedState)}
              style={[tailwind('bg-white rounded p-4 mb-2 flex flex-row')]}
            >
              <Checkbox
                style={{ marginTop: 3 }}
                value={checkedState}
                onValueChange={() => setChecked(!checkedState)}
              />
              <Text style={{ paddingLeft: 10 }}>
                Dengan Ini saya membaca, Memahami & menyetujui hal-hal yang
                tercantum pada
                <Pressable
                  onPress={() => {
                    navigation.navigate('TermsCondition')
                  }}
                >
                  <Text style={tailwind('text-teal-400 ml-1')}>
                    Syarat dan ketentuan
                  </Text>
                </Pressable>
              </Text>
            </TouchableOpacity>
          )}

          <Pressable
            style={[
              tailwind(
                'bg-primary flex flex-row items-center justify-center text-center p-2 rounded'
              ),
              {
                width: 100,
                height: 45,
                opacity: isLastStep && !checkedState ? 0.8 : 1,
              },
            ]}
            onPress={() => handleNextStep()}
            disabled={isLastStep && !checkedState}
          >
            <Text
              style={[
                tailwind('text-center text-sm'),
                {
                  color: '#00443E',
                },
              ]}
            >
              Lanjut
            </Text>

            <ChevronRight
              style={[
                tailwind('ml-1'),
                {
                  color: '#00443E',
                },
              ]}
            />
          </Pressable>
        </View>

        <View
          style={[
            tailwind('absolute'),
            {
              width: '100%',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: -2,
            },
          ]}
        >
          <Image
            source={require('~/assets/images/register/pattern.png')}
            style={[
              {
                aspectRatio: 1,
                resizeMode: 'cover',
              },
            ]}
          />
        </View>

        <View
          style={[
            tailwind('absolute'),
            {
              width: '100%',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: -2,
            },
          ]}
        >
          <Image
            source={require('~/assets/images/register/people.png')}
            style={[
              {
                aspectRatio: 1,
                resizeMode: 'cover',
              },
            ]}
          />
        </View>
      </View>
    </Container>
  )
}
