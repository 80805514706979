import * as React from 'react'
import Svg, { Defs, Path, Symbol, Use } from 'react-native-svg'

const House = (props: any) => (
  <Svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Defs>
      <Symbol id="house">
        <Path
          d="M1.69141 6.92003C1.69141 6.92003 7.8358 0.840027 9.93048 0.840027C12.0252 0.840027 18.3092 6.92003 18.3092 6.92003"
          stroke={props?.color ?? '#333'}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <Path
          d="M12.3265 17.16H8.00586V13.1067C8.00586 11.9357 8.91799 11.08 10.1662 11.08C11.4144 11.08 12.3265 11.9357 12.3265 13.1067V17.16Z"
          stroke={props?.color ?? '#333'}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M16.3147 8.20001V14.9315C16.3147 16.1721 15.3128 17.16 14.1058 17.16H10.1662H6.22651C4.9968 17.16 4.01758 16.1721 4.01758 14.9315V8.20001"
          stroke={props?.color ?? '#333'}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </Symbol>
    </Defs>

    <Use href="#house" />
  </Svg>
)

export default House
