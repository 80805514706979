import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  constructErrorPayload,
  constructExtraReducer,
  constructSuccessPayload,
} from '~/store/helper'

import {
  getDistricts as getDistrictsCall,
  getProvinces as getProvincesCall,
  getRegencies as getRegenciesCall,
} from '~/apis/provinces'

interface IState {
  provinces: Record<string, any>[]
  regencies: Record<string, any>[]
  districts: Record<string, any>[]
  loading: Record<string, any>
  responses: Record<string, any>
}
const initialState: IState = {
  provinces: [],
  regencies: [],
  districts: [],
  loading: {},
  responses: {},
}

export const getProvinces = createAsyncThunk(
  'province/getProvinces',
  async (_, { rejectWithValue }) => {
    const [res, resErr] = await getProvincesCall()
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const getRegencies = createAsyncThunk(
  'province/getRegencies',
  async (params: { provinceId: string }, { rejectWithValue }) => {
    const [res, resErr] = await getRegenciesCall(params.provinceId)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const getDistricts = createAsyncThunk(
  'province/getDistricts',
  async (
    params: { provinceId: string; regencyId: string },
    { rejectWithValue }
  ) => {
    const [res, resErr] = await getDistrictsCall(
      params.provinceId,
      params.regencyId
    )
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const reducerSlice = createSlice({
  name: 'province',
  initialState,
  reducers: {
    resetResponse(state: IState, action: PayloadAction<any>) {
      const apiName = action.payload
      state.responses = {
        ...state.responses,
        [apiName]: null,
      }
    },
  },
  extraReducers: (builder) => {
    constructExtraReducer(
      builder,
      getProvinces,
      (state: IState, action: PayloadAction<any>) => {
        state.provinces = action.payload.body.provinces
      }
    )
    constructExtraReducer(
      builder,
      getRegencies,
      (state: IState, action: PayloadAction<any>) => {
        if (action.payload.body!.province) {
          state.regencies = action.payload.body!.province!.Regencies
        }
      }
    )
    constructExtraReducer(
      builder,
      getDistricts,
      (state: IState, action: PayloadAction<any>) => {
        if (action.payload.body!.regency) {
          state.districts = action.payload.body!.regency!.Districts
        }
      }
    )
  },
})

export const { resetResponse } = reducerSlice.actions
export default reducerSlice.reducer
