import { useMemo, useState } from 'react'
import { Pressable, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { RootState, useAppDispatch, useAppSelector } from '~/store/store'
import { setGroup } from '~/store/waitlist'

import Text from '~/components/StyledText'
import { ActionTypes, ProductSelectedAction } from '~/components/product/types'

import { excerptStr } from '~/lib/string'

interface IProps {
  setSelectedAction: React.Dispatch<ProductSelectedAction>
}

export default function Variant({ setSelectedAction }: IProps) {
  const tailwind = useTailwind()

  const dispatch = useAppDispatch()
  const { product } = useAppSelector((state: RootState) => state.product)

  const [variantSelected, setVariantSelected] = useState<Map<number, number>>(
    new Map()
  )

  const intersection = (a: Set<any>, b: Set<any>): Set<any> => {
    return new Set([...a].filter((x) => b.has(x)))
  }

  const createMapping = (
    productItems: any[]
  ): Map<number, Map<number, Set<any>>> => {
    const map = new Map()

    productItems.forEach((productItem: any) => {
      productItem.ProductVariants.forEach((productVariant: any) => {
        if (!map.has(productVariant.productVariantGroupId)) {
          map.set(productVariant.productVariantGroupId, new Map())
        }
        const variantMap = map.get(productVariant.productVariantGroupId)
        if (!variantMap.has(productVariant.id)) {
          variantMap.set(productVariant.id, new Set())
        }
        variantMap.get(productVariant.id).add(productItem)
      })
    })

    return map
  }

  const [filteredMapping, setFilteredMapping] = useState<
    Map<number, Map<number, Set<any>>>
  >(new Map())

  const variantGroupsMap: Map<number, any> | undefined = useMemo(
    () =>
      product &&
      new Map(
        product.ProductVariantGroups?.map((group: any) => [group.id, group])
      ),
    [product]
  )

  const variantsMap: Map<number, any> | undefined = useMemo(() => {
    if (product) {
      const map = new Map()
      product.ProductItems?.forEach((productItem: any) => {
        productItem.ProductVariants.forEach((productVariant: any) => {
          map.set(productVariant.id, productVariant)
        })
      })
      return map
    }
  }, [product])

  const fullMapping: Map<number, Map<number, Set<any>>> | undefined =
    useMemo(() => {
      if (product) {
        const mapping = createMapping(product.ProductItems || [])
        setFilteredMapping(mapping)
        return mapping
      }
    }, [product])

  const handleVariantSelection = (
    variantGroupId: number,
    variantId: number
  ) => {
    let currentVariantSelected = variantSelected

    // :: Returned state, used for product checkout
    setSelectedAction({
      type: ActionTypes.setSelectedProductId,
      value: product?.id || 0,
    })

    // :: Maps have elements of both Objects (a unique key/value pair collection)
    // :: Existing value, will override the first key of 1 with the subsequent one
    currentVariantSelected = currentVariantSelected.set(
      variantGroupId,
      variantId
    )
    setSelectedAction({
      type: ActionTypes.setVariantSelected,
      value: currentVariantSelected,
    })

    // Redo filtering since the variants has changed
    let filteredProductItems = new Set()

    if (fullMapping) {
      currentVariantSelected.forEach(
        (variantId: number, variantGroupId: number) => {
          // :: Typescript doesn't keep track of the .set .get references, and the default signature
          // :: of .get is T | undefined, so here's we can use non-null assertion
          if (fullMapping!.get(variantGroupId)) {
            if (!filteredProductItems.size) {
              filteredProductItems = fullMapping
                .get(variantGroupId)!
                .get(variantId) as Set<any>
            } else {
              filteredProductItems = intersection(
                filteredProductItems,
                fullMapping!.get(variantGroupId)!.get(variantId) as Set<any>
              )
            }
          }
        }
      )
    }
    setFilteredMapping(createMapping([...filteredProductItems]))

    // :: Set selected variants, transforms into one single string val
    if (variantGroupsMap && variantsMap) {
      if (variantGroupsMap.size > 0 && variantsMap.size > 0) {
        const _variants = [
          ...Array.from(filteredProductItems).values(),
        ] as any[]
        const _variantGroups = Array.from(variantGroupsMap.values())

        const newVariantGroups = _variantGroups.flatMap((val, idx) => {
          const lastLen = _variantGroups.length - 1 == idx

          return _variants.flatMap((val2) => {
            const variant = val2.ProductVariants.find(
              (val3: any) => val3.productVariantGroupId == val.id
            )
            const separator = !lastLen ? ', ' : ''

            if (variant) {
              return [
                ...new Set([`${val.name} - `, `${variant.name}${separator}`]),
              ]
            }
          })
        })

        // Set store
        dispatch(
          setGroup({
            variants: newVariantGroups.join(''),
          })
        )
      }
    }

    if (
      currentVariantSelected.size === variantGroupsMap!.size &&
      filteredProductItems.size === 1
    ) {
      setSelectedAction({
        type: ActionTypes.setSelectedProductItemId,
        value: filteredProductItems.values().next().value.id,
      })

      const arrFilterProductItems = [...filteredProductItems.values()]
      const pricingRates = arrFilterProductItems.flatMap(
        (val: any) => val.PricingRates
      )

      // Set default pricing rates
      setSelectedAction({
        type: ActionTypes.setSelectedDuration,
        value: pricingRates[0].id,
      })
    }
  }

  return (
    <View
      style={{
        marginBottom: 8,
      }}
    >
      {fullMapping &&
        Array.from(fullMapping.keys()).map((variantGroupId: number) => (
          <View key={`rootview-group-${variantGroupId}`}>
            <Text style={tailwind('mb-2')}>
              {variantGroupsMap && variantGroupsMap.get(variantGroupId)?.name}
            </Text>

            <View
              style={tailwind('flex flex-row flex-wrap mb-2')}
              key={`view-group-${variantGroupId}`}
            >
              {Array.from(fullMapping!.get(variantGroupId)!.keys()).map(
                (variantId: number) => {
                  const isSelected =
                    variantSelected.get(variantGroupId) === variantId

                  return (
                    <Pressable
                      style={[
                        tailwind(`border py-2 px-4 rounded-full mr-2 mb-2`),
                        {
                          backgroundColor: isSelected ? '#B2E2DF' : '#fff',
                          borderColor: isSelected ? '#00443E' : '#333',
                        },
                      ]}
                      key={`pressable-variant-${variantId}`}
                      onPress={() =>
                        handleVariantSelection(variantGroupId, variantId)
                      }
                    >
                      <Text
                        key={`text-variant-${variantId}`}
                        style={tailwind('text-sm')}
                      >
                        {variantsMap &&
                          excerptStr(variantsMap?.get(variantId)?.name, 30)}
                      </Text>
                    </Pressable>
                  )
                }
              )}
            </View>
          </View>
        ))}
    </View>
  )
}
