import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { TransactionAttributesFull } from '@withbioma/apitype-publicapi-public/lib/v1/common/transaction_full'

import {
  constructErrorPayload,
  constructExtraReducer,
  constructSuccessPayload,
} from '~/store/helper'

import { get as getCall } from '~/apis/transaction'

interface IState {
  transaction?: TransactionAttributesFull
  loading: Record<string, any>
  responses: Record<string, any>
}
const initialState: IState = {
  transaction: undefined,
  loading: {},
  responses: {},
}

export const get = createAsyncThunk(
  'transaction/get',
  async (transactionUuid: string, { rejectWithValue }) => {
    const [res, resErr] = await getCall(transactionUuid)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const reducerSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    resetResponse(state: IState, action: PayloadAction<any>) {
      const apiName = action.payload
      state.responses = {
        ...state.responses,
        [apiName]: null,
      }
    },
    resetTransaction(state: IState) {
      state.transaction = undefined
    },
  },
  extraReducers: (builder) => {
    constructExtraReducer(
      builder,
      get,
      (state: IState, action: PayloadAction<any>) => {
        state.transaction = action.payload.body.transaction
      }
    )
  },
})

export const { resetResponse, resetTransaction } = reducerSlice.actions

export default reducerSlice.reducer
