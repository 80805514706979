import { View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

interface IProps {
  tailwindStyle?: string
}

const CardDivider: React.FC<IProps> = ({ tailwindStyle }) => {
  const tailwind = useTailwind()

  return <View style={tailwind(`h-4 w-full bg-gray-200 ${tailwindStyle}`)} />
}

export default CardDivider
