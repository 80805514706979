import { Pressable, ScrollView, Text, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { ActionTypes, ProductSelectedAction } from '~/components/product/types'

import { toCurrency } from '~/lib/money'

interface IProps {
  pricingRates: any[]
  selectedDuration: number
  setSelectedAction: React.Dispatch<ProductSelectedAction>
}

export default function SelectDuration({
  pricingRates,
  selectedDuration,
  setSelectedAction,
}: IProps) {
  const tailwind = useTailwind()

  const isSelected = (id: number): boolean => {
    return selectedDuration == id
  }

  const sortedPricingRates =
    pricingRates && pricingRates.length
      ? pricingRates.sort((a, b) => b.duration - a.duration)
      : []

  if (!pricingRates)
    return (
      <View style={tailwind(`flex flex-row items-center`)}>
        <Text>Belum ada durasi sewa</Text>
      </View>
    )

  return (
    <ScrollView horizontal showsHorizontalScrollIndicator={false}>
      {sortedPricingRates.map(
        (option: any, i: number) =>
          option && (
            <Pressable
              style={[
                tailwind(
                  `flex flex-col justify-between p-3 border rounded-lg mb-2 items-start ${
                    i != pricingRates.length - 1 ? 'mr-3' : ''
                  }`
                ),
                {
                  backgroundColor: isSelected(option?.id) ? '#B2E2DF' : '#fff',
                  borderColor: isSelected(option?.id) ? '#00461A' : '#333333',
                },
              ]}
              onPress={() =>
                setSelectedAction({
                  type: ActionTypes.setSelectedDuration,
                  value: option?.id,
                })
              }
              key={i}
            >
              <Text
                style={[
                  tailwind(`text-sm font-bold`),
                  { color: isSelected(option?.id) ? '#00461A' : '#333333' },
                ]}
              >
                {option.duration} hari
              </Text>
              <View style={tailwind('mt-4')}>
                <Text
                  style={[
                    tailwind(`text-xs font-bold leading-4`),
                    { color: isSelected(option?.id) ? '#00461A' : '#333333' },
                  ]}
                >
                  {toCurrency(option.price)}
                </Text>
                <Text
                  style={[
                    tailwind(`text-xs leading-4`),
                    { color: isSelected(option?.id) ? '#00461A' : '#333333' },
                  ]}
                >
                  {toCurrency(option.pricePerWeek)} / minggu
                </Text>
              </View>
            </Pressable>
          )
      )}
    </ScrollView>
  )
}
