import {
  ActionReducerMapBuilder,
  AsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit'
import { AxiosResponse, AxiosResponseHeaders } from 'axios'
import producer from 'immer'

import { Loading } from '~/interfaces/Responses'

type AxiosErrorResponse = Pick<AxiosResponse, 'status' | 'statusText' | 'data'>

export const constructStaticPayload = (res: any) => {
  return {
    status: 200,
    statusText: 'Static',
    body: res,
  }
}

export const constructSuccessPayload = <T>(res: AxiosResponse<T>) => {
  return {
    status: res.status,
    statusText: res.statusText,
    body: res.data,
    headers: res.headers,
  }
}

export const constructErrorPayload = (err: AxiosErrorResponse) => {
  return {
    status: err.status,
    statusText: err.statusText,
    body: err.data,
  }
}

export const mutateStateOnFulfilled = (
  state: any,
  apiName: string,
  payload: any
) => {
  return {
    ...state,
    loading: {
      ...state.loading,
      [apiName]: 'fulfilled' as Loading,
    },
    responses: {
      ...state.responses,
      [apiName]: payload,
    },
  }
}

export const mutateStateOnPending = (state: any, apiName: string) => {
  return {
    ...state,
    loading: {
      ...state.loading,
      [apiName]: 'pending' as Loading,
    },
    responses: {
      ...state.responses,
      [apiName]: null,
    },
  }
}

export const mutateStateOnRejected = (
  state: any,
  apiName: string,
  payload: any
) => {
  return {
    ...state,
    loading: {
      ...state.loading,
      [apiName]: 'failed' as Loading,
    },
    responses: {
      ...state.responses,
      [apiName]: payload,
    },
  }
}

export const constructExtraReducer = <S, P, T>(
  builder: ActionReducerMapBuilder<S>,
  thunk: AsyncThunk<
    | {
        status: number
        statusText: string
        body: T
        headers: AxiosResponseHeaders
      }
    | undefined,
    P,
    {}
  >,
  mapState?: (
    state: S,
    action: PayloadAction<
      | {
          status: number
          statusText: string
          body: T
          headers: AxiosResponseHeaders
        }
      | undefined
    >
  ) => void
) => {
  builder.addCase(
    thunk.fulfilled,
    (
      state,
      action: PayloadAction<
        | {
            status: number
            statusText: string
            body: T
            headers: AxiosResponseHeaders
          }
        | undefined
      >
    ) => {
      if (mapState) {
        const nextState = producer(state, (draftState: S) => {
          mapState(draftState, {
            type: action.type,
            payload: action.payload,
          })
        })

        return {
          ...mutateStateOnFulfilled(nextState, thunk.typePrefix, action),
        }
      }

      return {
        ...mutateStateOnFulfilled(state, thunk.typePrefix, action),
      }
    }
  )
  builder.addCase(thunk.pending, (state) => {
    return {
      ...mutateStateOnPending(state, thunk.typePrefix),
    }
  })
  builder.addCase(thunk.rejected, (state, action) => {
    return {
      ...mutateStateOnRejected(state, thunk.typePrefix, action),
    }
  })
}

export const fetchCall = async <T>(
  callFn: Promise<[AxiosResponse<T> | undefined, any]>
) => {
  const [res, resErr] = await callFn

  if (resErr) {
    return constructErrorPayload(resErr)
  }

  return res && constructSuccessPayload(res)
}
