import { Pressable, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import Text from '~/components/StyledText'

import CategoriesList from '~/utils/CategoriesBabyloania'

interface IProps {
  navigation: any
  hideNav?: boolean
}

const CategoriesBabyloania: React.FC<IProps> = ({ navigation, hideNav }) => {
  const tailwind = useTailwind()

  return (
    <View style={tailwind('flex flex-col w-full')}>
      <View style={tailwind('flex flex-row items-center justify-between mt-4')}>
        <Text style={tailwind('text-base font-bold px-4')}>
          👶 Kategori Babyloania
        </Text>
        {!hideNav && (
          <Pressable
            onPress={() =>
              navigation.navigate('Catalogue', {
                screen: 'Categories',
              })
            }
          >
            <Text style={tailwind('text-teal px-4')}>Lihat Semua</Text>
          </Pressable>
        )}
      </View>
      <View style={[tailwind('flex flex-row flex-wrap mt-4')]}>
        {CategoriesList.map((productType, i) => (
          <View style={[tailwind('p-2'), { width: '20%' }]} key={i}>
            <Pressable
              onPress={() =>
                navigation.navigate('Catalogue', {
                  screen: 'Category',
                  params: {
                    product_type_id: productType.id,
                  },
                })
              }
              style={[tailwind('flex flex-col justify-end items-center')]}
            >
              <Text style={tailwind('text-lg text-center')}>
                {productType.icon}
              </Text>
              <Text style={tailwind('text-xs leading-5 text-center')}>
                {productType.name}
              </Text>
            </Pressable>
          </View>
        ))}
      </View>
    </View>
  )
}

export default CategoriesBabyloania
