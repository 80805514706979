import { Pressable, View } from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import Text from '~/components/StyledText'
import Image from '~/components/base/Image'

type Props = {
  navigation: any
}

const EmptyCart: React.FC<Props> = ({ navigation }) => {
  const tailwind = useTailwind()

  return (
    <View
      style={[
        tailwind('flex flex-col items-center justify-center'),
        {
          height: 'calc(100vh - 130px)',
        },
      ]}
    >
      <Image
        source={require('~/assets/images/cart/empty.png')}
        width={112}
        height={112}
        transparent
        imageStyles={[
          {
            aspectRatio: 1,
          },
        ]}
      />

      <View style={tailwind('pt-5 pb-10')}>
        <Text style={tailwind('text-lg')}>Belum ada barang di keranjangmu</Text>
        <Text style={tailwind('text-gray-400 text-sm')}>
          Lihat dan tambahkan barang untuk disewa
        </Text>
      </View>

      <Pressable
        style={tailwind('bg-primary p-2 w-5/12 rounded')}
        onPress={() => {
          navigation.navigate('Root', {
            screen: 'Dashboard',
            params: { screen: 'Home' },
          })
        }}
      >
        <Text
          style={[
            tailwind('text-center text-sm'),
            {
              color: '#00443E',
            },
          ]}
        >
          Katalog Produk
        </Text>
      </Pressable>
    </View>
  )
}

export default EmptyCart
