import React, { SetStateAction } from 'react'
import {
  GestureResponderEvent,
  Pressable,
  TouchableOpacity,
  View,
} from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import Text from '~/components/StyledText'
import CartInfoModal from '~/components/cart/Modal'

import InformationIcon from '~/svg/Information'

type Props = {
  visibleState: [boolean, React.Dispatch<SetStateAction<boolean>>]
  onPress: (event: GestureResponderEvent) => void
  count: number
}

const UnavailableStockInfo: React.FC<Props> = ({
  visibleState,
  onPress,
  count,
}) => {
  const tailwind = useTailwind()

  const [modalVisible, setModalVisible] = visibleState

  return (
    <React.Fragment>
      <TouchableOpacity
        style={[
          tailwind('pt-14 bg-red-500 px-4 mx-4 py-3 mb-4 '),
          {
            borderRadius: 8,
          },
        ]}
        activeOpacity={1}
        onPress={onPress}
      >
        <View style={tailwind('flex flex-row justify-between')}>
          <Text style={tailwind('text-sm text-white')}>
            Ada {count} barang tidak tersedia
          </Text>

          <Pressable
            onPress={(e) => {
              setModalVisible(true)
            }}
          >
            <InformationIcon style={tailwind('text-white')} />
          </Pressable>
        </View>
      </TouchableOpacity>

      <CartInfoModal open={modalVisible} setOpen={setModalVisible} />
    </React.Fragment>
  )
}

export default UnavailableStockInfo
