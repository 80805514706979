import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ProductAttributesFull } from '@withbioma/apitype-publicapi-public/lib/v1/common/product'

import {
  constructErrorPayload,
  constructExtraReducer,
  constructSuccessPayload,
} from '~/store/helper'

import { getAll as getAllCall, get as getCall } from '~/apis/products'

interface IState {
  products: ProductAttributesFull[]
  product?: ProductAttributesFull
  loading: Record<string, any>
  responses: Record<string, any>
}
const initialState: IState = {
  products: [],
  product: undefined,
  loading: {},
  responses: {},
}

export const get = createAsyncThunk(
  'product/get',
  async (productId: string, { rejectWithValue }) => {
    const [res, resErr] = await getCall(productId)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const getAll = createAsyncThunk(
  'product/getAll',
  async (params: object, { rejectWithValue }) => {
    const [res, resErr] = await getAllCall(params)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const reducerSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    resetResponse(state: IState, action: PayloadAction<any>) {
      const apiName = action.payload
      state.responses = {
        ...state.responses,
        [apiName]: null,
      }
    },
    resetProducts(state: IState) {
      state.products = []
    },
    resetProduct(state: IState) {
      state.product = undefined
    },
  },
  extraReducers: (builder) => {
    constructExtraReducer(
      builder,
      get,
      (state: IState, action: PayloadAction<any>) => {
        state.product = action.payload.body.product
      }
    )
    constructExtraReducer(
      builder,
      getAll,
      (state: IState, action: PayloadAction<any>) => {
        state.products = action.payload.body.products
      }
    )
  },
})

export const { resetResponse, resetProducts, resetProduct } =
  reducerSlice.actions
export default reducerSlice.reducer
