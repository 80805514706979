import {
  GetAllQueryString,
  GetAllResponse,
  GetResponse,
} from '@withbioma/apitype-publicapi-public/lib/v1/products/api'

import API from '~/apis/client'
import { useCachedResponse } from '~/apis/utils'

export async function get(productId: string) {
  return await API.handle(
    API.public.get<GetResponse>(`/public/v1/products/${productId}`)
  )
}

export async function getAll(params: GetAllQueryString) {
  return await API.handle(
    API.public.get<GetAllResponse>(`/public/v1/products`, {
      params,
      headers: useCachedResponse,
    })
  )
}
