import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Animated,
  Modal,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import Container from '~/components/Container'
import Text from '~/components/StyledText'

import Close from '~/svg/Close'

type Props = PropsWithChildren<{
  open: boolean
  setVisible: (param: boolean) => void
  title?: string
  children: React.ReactNode
  height?: number | string
}>

// Wrapper for action sheet
const CustomActionSheet: React.FC<Props> = ({
  open,
  title,
  setVisible,
  children,
  height,
}) => {
  const tailwind = useTailwind()

  const animatedValue = useRef(new Animated.Value(0)).current

  const startAnimation = useCallback(
    (value: number, duration?: number, func?: Function) => {
      Animated.timing(animatedValue, {
        toValue: value,
        duration: duration || 300,
        useNativeDriver: true,
      }).start(({ finished }) => {
        if (finished && func && typeof func == 'function') {
          func()
        }
      })
    },
    []
  )

  const handleClose = useCallback(() => {
    startAnimation(-50, 150, () => {
      setVisible(false)
    })
  }, [])

  useEffect(() => {
    if (open) {
      startAnimation(1)
    } else {
      handleClose()
    }
  }, [open])

  return (
    <Modal
      visible={open}
      transparent={true}
      animationType="none"
      onRequestClose={handleClose}
    >
      <Text style={styles.overlay} onPress={handleClose} />

      <Animated.View
        style={[
          tailwind('h-full flex flex-col items-center'),
          styles.container,
          {
            height: height ?? '50%',
            transform: [
              {
                translateY: animatedValue.interpolate({
                  inputRange: [0, 1],
                  outputRange: [300, 0],
                }),
              },
              { perspective: 100 },
            ],
          },
        ]}
      >
        <Animated.View
          style={[
            styles.content,
            {
              transform: [
                {
                  translateY: animatedValue.interpolate({
                    inputRange: [0, 1],
                    outputRange: [300, 0],
                  }),
                },
                { perspective: 100 },
              ],
            },
          ]}
        >
          {title && (
            <Text style={tailwind('font-bold text-lg mb-5')}>{title}</Text>
          )}

          <ScrollView scrollEnabled={true}>{children}</ScrollView>

          <Pressable
            style={styles.closeBtn}
            onPress={() => {
              handleClose()
            }}
          >
            <Close />
          </Pressable>
        </Animated.View>
      </Animated.View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: 0.4,
    backgroundColor: '#000',
    height: '100%',
  },
  container: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
  content: {
    maxWidth: 400,
    backgroundColor: '#fff',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    padding: 20,
    height: '100%',
  },
  closeBtn: {
    position: 'absolute',
    right: 23,
    top: 22,
    borderColor: 'transparent',
    borderWidth: 0,
    backgroundColor: 'transparent',
  },
})

export default CustomActionSheet
