import React from 'react'
import { GestureResponderEvent, TouchableOpacity, View } from 'react-native'
import { EyeIcon, EyeOffIcon } from 'react-native-heroicons/solid'
import { useTailwind } from 'tailwind-rn/dist'

import Text from '~/components/StyledText'
import Input, { IProps } from '~/components/base/Input'

type Props = {
  label: string
} & IProps &
  PasswordVisibleProps

const InputPassword: React.FC<Props> = (props) => {
  const {
    top,
    label,
    style,
    bottom,
    secureTextEntry,
    keyboardType,
    passwordVisible,
    onTogglePasswordVisible,
    ...rest
  } = props

  const passwordVisibleProps = {
    top,
    bottom,
    passwordVisible,
    onTogglePasswordVisible,
  }

  const tailwind = useTailwind()

  return (
    <View style={{ position: 'relative' }}>
      <Text style={tailwind('mb-2')}>{label}</Text>
      <Input
        {...rest}
        style={[tailwind('mb-1'), style]}
        keyboardType="visible-password"
        secureTextEntry={!passwordVisible}
      />

      <PasswordVisible {...passwordVisibleProps} />
    </View>
  )
}

type PasswordVisibleProps = {
  passwordVisible: boolean
  onTogglePasswordVisible?: (e: GestureResponderEvent) => void
  top?: string | number
  bottom?: string | number
}

const PasswordVisible: React.FC<PasswordVisibleProps> = ({
  top,
  bottom,
  passwordVisible,
  onTogglePasswordVisible,
}) => {
  const commonProps = {
    width: 15,
    height: 15,
  }

  const tailwind = useTailwind()

  return (
    <TouchableOpacity
      activeOpacity={1}
      style={[
        tailwind('absolute cursor-pointer'),
        {
          top: '50%',
          right: 10,
          transform: 'translateY(-50%)',
          marginTop: top ?? 10,
          marginBottom: bottom ?? 0,
        },
      ]}
      onPress={onTogglePasswordVisible}
    >
      {passwordVisible ? (
        <EyeIcon {...commonProps} />
      ) : (
        <EyeOffIcon {...commonProps} />
      )}
    </TouchableOpacity>
  )
}

export default InputPassword
