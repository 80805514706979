import { CustomOrderAttributesSchema } from '@withbioma/apitype-publicapi-public/lib/v1/orders/api'
import { useMemo } from 'react'
import { View } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { z } from 'zod'

import Text from '~/components/StyledText'
import Image from '~/components/base/Image'
import { useOrderContext } from '~/components/orders/OrderContext'

import { toCurrency } from '~/lib/money'
import Calendar from '~/svg/Calendar'

const CustomPublicOrderItem =
  CustomOrderAttributesSchema.shape.OrderItems.unwrap().unwrap().element

interface IProps {
  orderItem: z.infer<typeof CustomPublicOrderItem>
  isLastIndex: boolean
}

const ProductOrderItemRow: React.FC<IProps> = ({ orderItem, isLastIndex }) => {
  const tailwind = useTailwind()
  const { productOrderItemMetadataMap } = useOrderContext()

  const metadata = useMemo(
    () => productOrderItemMetadataMap.get(orderItem.id),
    [productOrderItemMetadataMap]
  )

  if (!orderItem.ProductItem) {
    return <></>
  }

  return (
    <View
      style={[
        tailwind(
          'flex flex-row justify-between border-b-2 border-gray-200 pb-4 w-full'
        ),
        {
          marginTop: 15,
          borderBottomWidth: !isLastIndex ? 2 : 0,
        },
      ]}
    >
      <View style={tailwind('flex flex-row justify-start w-full')}>
        <Image
          imageStyles={[tailwind('mb-4'), { aspectRatio: 1 }]}
          source={{
            uri: metadata?.imageUrl || '',
          }}
          width={60}
          height={60}
          rounded
        />
        <View style={[tailwind('flex pl-4'), { flexGrow: '1' }]}>
          <Text style={tailwind('font-bold text-base')}>
            {metadata?.productName || ''}
          </Text>
          <Text style={tailwind('text-sm')}>
            {metadata?.productVariants || ''}
          </Text>
          <View
            style={[
              tailwind('flex flex-row justify-between items-center'),
              { flexGrow: '1' },
            ]}
          >
            <View style={tailwind('flex flex-row items-center mb-1')}>
              <Calendar />
              <Text style={tailwind('text-sm ml-1')}>
                {metadata?.startsAt || ''} - {metadata?.endsAt || ''} (
                {metadata?.durationDays || 0} hari)
              </Text>
            </View>
            <Text style={tailwind('text-sm font-bold')}>
              {toCurrency(orderItem.price)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}
export default ProductOrderItemRow
