import { BASE_URL } from '@env'
import Constants from 'expo-constants'

export const devApiUrl = BASE_URL || 'http://localhost:3000'
export const stagingApiUrl = 'https://staging-api.withbioma.com'
export const prodApiUrl = 'https://api.withbioma.com'

const ENV = {
  dev: {
    apiUrl: devApiUrl,
  },
  staging: {
    apiUrl: stagingApiUrl,
  },
  prod: {
    apiUrl: prodApiUrl,
  },
}

function getEnvVars(env: string = '') {
  if (env === null || env === undefined || env === '') return ENV.dev
  if (env.indexOf('dev') !== -1) return ENV.dev
  if (env.indexOf('staging') !== -1) return ENV.staging
  if (env.indexOf('prod') !== -1) return ENV.prod
}

export default getEnvVars(Constants?.manifest?.releaseChannel)
