import * as React from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

const PaymentPending = (props: any) => (
  <Svg
    width={120}
    height={120}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G clipPath="url(#a)">
      <Path
        d="M26.4 66.416a.542.542 0 0 1-.545-.549V51.282c0-.307.24-.549.545-.549.305 0 .545.242.545.549v14.585c0 .307-.24.549-.545.549ZM72.709 51.282a39.97 39.97 0 0 1-6.415-.505 37.234 37.234 0 0 1-13.112-4.727c-7.07-4.178-13.658-4.847-19.56-1.984-4.397 2.127-6.742 5.516-6.775 5.549a.551.551 0 1 1-.905-.625c.098-.154 2.53-3.652 7.189-5.911a18.201 18.201 0 0 1 8.738-1.821c3.796.154 7.79 1.448 11.847 3.838 8.891 5.253 17.946 5.495 23.99 4.782 6.545-.779 11.028-2.808 11.072-2.83.273-.131.6 0 .72.275.12.274 0 .603-.273.723-.185.088-4.68 2.128-11.4 2.928a42.268 42.268 0 0 1-5.116.308ZM89.018 71.79a.542.542 0 0 1-.545-.55V49.857c0-.307.24-.548.545-.548.306 0 .546.24.546.548V71.24c0 .307-.24.548-.546.548ZM70.713 78.918c-2.51 0-5.062-.373-7.44-1.36-10.55-4.398-17.717-4.343-17.782-4.343a.556.556 0 0 1-.556-.538c0-.307.24-.548.534-.559.295 0 7.44-.055 18.218 4.43 5.422 2.26 11.924 1.12 16.43-.24 4.92-1.481 8.585-3.543 8.629-3.565a.538.538 0 0 1 .741.209.544.544 0 0 1-.207.745c-.153.088-3.796 2.128-8.825 3.652-2.728.823-6.186 1.569-9.742 1.569ZM60.382 68.28c-3.579 0-6.491-2.928-6.491-6.525 0-3.597 2.912-6.526 6.49-6.526 3.579 0 6.491 2.929 6.491 6.526s-2.912 6.525-6.49 6.525Zm0-11.954c-2.979 0-5.4 2.435-5.4 5.429s2.421 5.428 5.4 5.428c2.978 0 5.4-2.434 5.4-5.428 0-2.994-2.422-5.429-5.4-5.429Z"
        fill="#A6DB68"
      />
      <Path
        d="M50.619 42.398a.542.542 0 0 1-.546-.548V29.348c0-.307.24-.548.546-.548.305 0 .545.241.545.548V41.85c0 .307-.24.548-.545.548ZM103.309 29.896H52.691a.542.542 0 0 1-.546-.548c0-.307.24-.548.546-.548h50.618c.306 0 .546.241.546.548 0 .307-.24.549-.546.549ZM103.309 65.21a.542.542 0 0 1-.545-.55V31.433c0-.307.24-.548.545-.548.306 0 .546.241.546.548v33.229c0 .307-.24.548-.546.548ZM101.236 65.209h-9.927a.542.542 0 0 1-.545-.548c0-.307.24-.549.545-.549h9.927c.306 0 .546.242.546.549 0 .307-.24.548-.546.548Z"
        fill="#FED23F"
      />
      <Path
        d="M103.31 35.928H50.619a.542.542 0 0 1-.546-.549c0-.307.24-.548.546-.548h52.691c.305 0 .545.241.545.548 0 .307-.24.549-.545.549ZM103.31 40.425H50.619a.542.542 0 0 1-.546-.548c0-.308.24-.549.546-.549h52.691c.305 0 .545.241.545.548 0 .308-.24.549-.545.549ZM93.437 62.029a2.465 2.465 0 0 1-2.455-2.468 2.465 2.465 0 0 1 2.455-2.467 2.465 2.465 0 0 1 2.454 2.467 2.465 2.465 0 0 1-2.454 2.468Zm0-4.277c-.993 0-1.8.811-1.8 1.81 0 .997.807 1.809 1.8 1.809s1.8-.812 1.8-1.81-.807-1.81-1.8-1.81ZM99 62.029a2.465 2.465 0 0 1-2.454-2.468A2.465 2.465 0 0 1 99 57.094a2.465 2.465 0 0 1 2.455 2.467A2.465 2.465 0 0 1 99 62.03Zm0-4.277c-.992 0-1.8.811-1.8 1.81 0 .997.808 1.809 1.8 1.809.993 0 1.8-.812 1.8-1.81s-.807-1.81-1.8-1.81Z"
        fill="#FED23F"
      />
      <Path
        d="M103.31 35.38H50.62v4.496h52.69V35.38ZM42.71 37.683c-2.434 0-4.419-1.996-4.419-4.442 0-2.445 1.986-4.441 4.418-4.441 2.433 0 4.418 1.996 4.418 4.441 0 2.446-1.985 4.442-4.418 4.442Zm0-7.787c-1.834 0-3.328 1.503-3.328 3.345 0 1.843 1.495 3.345 3.327 3.345 1.833 0 3.327-1.502 3.327-3.345 0-1.842-1.494-3.345-3.327-3.345ZM18 55.339c-1.985 0-3.6-1.623-3.6-3.62 0-1.995 1.615-3.618 3.6-3.618 1.986 0 3.6 1.623 3.6 3.619 0 1.995-1.614 3.619-3.6 3.619Zm0-6.142a2.516 2.516 0 0 0-2.509 2.523 2.516 2.516 0 0 0 2.51 2.522c1.385 0 2.508-1.13 2.508-2.522A2.516 2.516 0 0 0 18 49.197ZM33.11 38.56a2.903 2.903 0 0 1-2.891-2.906 2.904 2.904 0 0 1 2.89-2.906 2.904 2.904 0 0 1 2.892 2.906 2.904 2.904 0 0 1-2.891 2.906Zm0-4.715c-.993 0-1.8.811-1.8 1.81 0 .997.807 1.809 1.8 1.809.992 0 1.8-.812 1.8-1.81s-.808-1.81-1.8-1.81ZM101.237 77.053c-2.411 0-4.364-1.963-4.364-4.387 0-2.424 1.953-4.387 4.364-4.387 2.411 0 4.363 1.963 4.363 4.387 0 2.424-1.952 4.387-4.363 4.387Zm0-7.677c-1.8 0-3.273 1.48-3.273 3.29s1.473 3.29 3.273 3.29 3.272-1.48 3.272-3.29-1.472-3.29-3.272-3.29ZM92.564 83.413c-1.833 0-3.328-1.502-3.328-3.345 0-1.842 1.495-3.344 3.328-3.344 1.832 0 3.327 1.502 3.327 3.344 0 1.843-1.495 3.345-3.327 3.345Zm0-5.593a2.245 2.245 0 0 0-2.237 2.248 2.245 2.245 0 0 0 2.237 2.249 2.245 2.245 0 0 0 2.236-2.249 2.245 2.245 0 0 0-2.236-2.248ZM32.51 91.2c-6.917 0-12.546-5.659-12.546-12.612s5.629-12.611 12.545-12.611c6.917 0 12.546 5.658 12.546 12.611S39.425 91.2 32.509 91.2Zm0-24.127c-6.317 0-11.455 5.166-11.455 11.515 0 6.35 5.138 11.515 11.454 11.515 6.317 0 11.455-5.165 11.455-11.515 0-6.35-5.138-11.515-11.455-11.515Z"
        fill="#FED23F"
      />
      <Path
        d="M32.051 81h-.25a.784.784 0 0 1-.786-.789v-8.29c0-.44.349-.79.785-.79h.251c.436 0 .785.35.785.79v8.29c0 .439-.349.79-.785.79Z"
        fill="#FED23F"
      />
      <Path
        d="M31.059 80.255v-.252c0-.44.349-.79.785-.79h8.291c.436 0 .785.35.785.79v.252c0 .438-.349.79-.785.79h-8.291a.798.798 0 0 1-.785-.79Z"
        fill="#FED23F"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path
          fill="#fff"
          transform="translate(14.4 28.8)"
          d="M0 0h91.2v62.4H0z"
        />
      </ClipPath>
    </Defs>
  </Svg>
)

export default PaymentPending
