// check empty
const isEmpty = (value: any) => {
  return (
    value === null ||
    value === undefined ||
    value === '' ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' && Object.keys(value).length === 0)
  )
}

// check numeric
const isNumeric = (value: any) => {
  const pattern = /^-?(\s*|\d+)$/

  return pattern.test(value)
}

// check decimal
const isDecimal = (value: any) => {
  const pattern = /^-?(?:\d*(?:\.\d*)?)?$/

  return pattern.test(value)
}

// check is email
const isEmail = (value: any) => {
  const pattern = /\S+@\S+\.\S+/

  return pattern.test(value)
}

// Check is phone
const isPhone = (value: any) => {
  const pattern = /^(?:\+62|\(0\d{1,3}\)|0)\s?\d{1,13}/

  return pattern.test(value)
}

const isAlphabet = (value: any, candidate: string) => {
  const pattern = new RegExp(`\\${String(value)}\$`, 'g')

  return pattern.test(candidate)
}

function isDate(input: unknown): input is Date {
  return (
    input instanceof Date ||
    Object.prototype.toString.call(input) === '[object Date]'
  )
}

const isValidDate = (d: Date | string | undefined) => {
  return d && !isNaN(new Date(d).getTime())
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isFunction(value: any): value is (...args: any[]) => any {
  return typeof value === 'function'
}

export {
  isEmpty,
  isNumeric,
  isDecimal,
  isEmail,
  isPhone,
  isAlphabet,
  isValidDate,
  isFunction,
  isDate,
}
