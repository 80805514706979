import { Image, Linking, Pressable, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { useTailwind } from 'tailwind-rn/dist'

import Container from '~/components/Container'
import Text from '~/components/StyledText'
import BackHeader from '~/components/headers/BackHeader'

import { RootStackProps } from '~/navigation/types'
import InstagramWhiteIconXs from '~/svg/InstagramWhiteIconXs'

const DEFAULT_REGISTERED = 1100
const BASE_START_DATE = Date.parse('2022-06-01')
const BIOMA_IG_URL = 'https://www.instagram.com/with.bioma/'

const WaitlistSuccess = ({ route, navigation }: RootStackProps<'Root'>) => {
  const tailwind = useTailwind()

  // This is a gimmick. We do not actually reveal the waitlist count.
  // Calculation: baseline + 2 per hour since 2022-06-01
  const currentCount =
    DEFAULT_REGISTERED +
    Math.ceil(((Date.now() - BASE_START_DATE) / 1000 / 60 / 24) * 2)

  return (
    <Container>
      <BackHeader
        navigation={navigation}
        fallback="Home"
        text="Gabung Antrian"
        style={{
          backgroundColor: '#00443E',
          color: '#fff',
        }}
      />
      <ScrollView
        style={[
          tailwind('pt-14 flex flex-col w-full min-h-screen relative'),
          {
            backgroundColor: '#00443E',
            height: '100%',
          },
        ]}
      >
        <View style={tailwind('pt-6 px-4 h-full relative')}>
          <Text style={tailwind('text-2xl text-white mb-5 font-bold')}>
            Berhasil Gabung Antrian
          </Text>
          <Text style={tailwind('text-sm text-white mt-2 leading-5')}>
            Anda termasuk ke dalam{' '}
            <Text style={tailwind('text-sm font-bold')}>{currentCount}</Text>{' '}
            orang yang menunggu kehadiran Bioma. Tunggu update selanjutnya dari
            kami.
          </Text>

          <Text style={tailwind('text-sm text-white mt-4 leading-5')}>
            Ikuti Instagram @withbioma untuk info lebih lanjut
          </Text>
          <Pressable
            style={tailwind('flex flex-row items-center mt-2')}
            onPress={() => Linking.openURL(BIOMA_IG_URL)}
          >
            <InstagramWhiteIconXs />
            <Text style={tailwind('text-sm text-white ml-1')}>@withbioma</Text>
          </Pressable>

          <View
            style={[
              tailwind('mt-12 relative w-full'),
              {
                left: '50%',
                bottom: 0,
                transform: 'translateX(-50%)',
                marginTop: '100%',
                marginBottom: 40,
              },
            ]}
          >
            <Pressable
              style={[
                tailwind('rounded p-4 text-center'),
                {
                  backgroundColor: '#B2E2DF',
                  height: 55,
                },
              ]}
              onPress={() => navigation.navigate('Root')}
            >
              <Text
                style={[
                  tailwind('text-sm'),
                  {
                    color: '#00443E',
                  },
                ]}
              >
                Kembali ke Beranda
              </Text>
            </Pressable>
          </View>

          {/* :: Background Image*/}
          <View
            style={[
              tailwind('absolute'),
              {
                bottom: '10%',
                left: '50%',
                width: '100%',
                transform: 'translateX(-50%)',
                zIndex: -1,
              },
            ]}
          >
            <Image
              source={require('~/assets/images/waitlistSuccess/people-dab.png')}
              style={[
                tailwind('rounded-lg'),
                {
                  aspectRatio: 4,
                  height: 450,
                  resizeMode: 'cover',
                },
              ]}
            />
          </View>
        </View>
      </ScrollView>
    </Container>
  )
}

export default WaitlistSuccess
