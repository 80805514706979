import { useEffect } from 'react'
import { Pressable, ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { getAll } from '~/store/productType'
import { RootState, useAppDispatch, useAppSelector } from '~/store/store'

import Container from '~/components/Container'
import { SearchContextProvider } from '~/components/SearchContext'
import SearchResult from '~/components/SearchResult'
import Text from '~/components/StyledText'
import Categories from '~/components/category/Categories'
import AppHeader from '~/components/headers/AppHeader'

import { BottomTabProps } from '~/navigation/types'

export default function Catalog({ navigation }: BottomTabProps<'Catalogue'>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()
  const { productTypes } = useAppSelector(
    (state: RootState) => state.productType
  )

  useEffect(() => {
    dispatch(getAll())
  }, [])

  return (
    <SearchContextProvider>
      <Container>
        <ScrollView style={tailwind('w-full flex flex-col')}>
          <AppHeader navigation={navigation} backVisible />
          <SearchResult navigation={navigation} />
          <View style={tailwind('mt-4')}>
            <Categories navigation={navigation} hideNav />
            <View>
              <View
                style={tailwind(
                  'flex flex-row items-center justify-between mt-4 mb-2'
                )}
              >
                <Text style={tailwind('text-base font-bold px-4')}>
                  Semua Kategori
                </Text>
              </View>
              {productTypes.map((productType, i) => {
                return (
                  <Pressable
                    key={i}
                    style={tailwind('px-4 py-2')}
                    onPress={() =>
                      navigation.navigate('Catalogue', {
                        screen: 'Category',
                        params: {
                          product_type_id: productType.id,
                        },
                      })
                    }
                  >
                    <Text>{productType.name}</Text>
                  </Pressable>
                )
              })}
            </View>
          </View>
        </ScrollView>
      </Container>
    </SearchContextProvider>
  )
}
