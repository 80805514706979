import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  constructErrorPayload,
  constructExtraReducer,
  constructSuccessPayload,
} from '~/store/helper'
import { resetUser } from '~/store/user'

import { login as loginCall, logout as logoutCall } from '~/apis/auth'
import { ISchema } from '~/interfaces/Auth'

interface IState {
  authenticated: boolean
  access_token?: string
  session_token?: string
  session_expires_at?: Date
  loading: object
  responses: object
}
const initialState: IState = {
  authenticated: false,
  access_token: undefined,
  session_token: undefined,
  session_expires_at: undefined,
  loading: {},
  responses: {},
}

export const login = createAsyncThunk(
  'auth/login',
  async (payload: ISchema, { rejectWithValue }) => {
    const [res, resErr] = await loginCall(payload)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { getState, rejectWithValue, dispatch }) => {
    const { session_token } = getState() as IState
    if (!session_token) {
      // dispatch(resetRedux())
      dispatch(resetUser())
      return
    }

    const [res, resErr] = await logoutCall(session_token)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }

    // dispatch(resetRedux())
    dispatch(resetUser())

    return res && constructSuccessPayload(res)
  }
)

export const resetRedux = () => ({
  type: 'RESET_REDUX',
})

export const reducerSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetResponse(state: any, action: PayloadAction<any>) {
      const apiName = action.payload
      state.responses = {
        ...state.responses,
        [apiName]: null,
      }
    },
  },
  extraReducers: (builder) => {
    constructExtraReducer(
      builder,
      login,
      (state: IState, action: PayloadAction<any>) => {
        state.authenticated = true
        state.access_token = action.payload.headers['bioma-x-access-token']
        state.session_token = action.payload.headers['bioma-x-session-token']
      }
    )
    constructExtraReducer(builder, logout, (state: IState) => {
      state.authenticated = false
      state.access_token = undefined
      state.session_token = undefined
    })
  },
})

export const { resetResponse } = reducerSlice.actions

export default reducerSlice.reducer
