import { createContext, useContext, useState } from 'react'

import { search as searchCall } from '~/apis/search'

interface ISearchContext {
  searchTerm: string
  setSearchTerm(val: string): void
  isTyping: boolean
  setIsTyping(val: boolean): void
  loading: boolean
  search(params: { query: string }): Promise<void>
  resetSearch(): void
  products: Record<string, any>[]
  productTypes: Record<string, any>[]
}

const SearchContext = createContext<ISearchContext>({
  searchTerm: '',
  setSearchTerm: (val: string) => {
    throw new Error('Not implemented yet')
  },
  isTyping: false,
  setIsTyping: (val: boolean) => {
    throw new Error('Not implemented yet')
  },
  loading: false,
  search: (params: { query: string }): Promise<void> => {
    throw new Error('Not implemented yet')
  },
  resetSearch: () => {
    throw new Error('Not implemented yet')
  },
  products: [],
  productTypes: [],
})

export function SearchContextProvider({ children }: { children: any }) {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [isTyping, setIsTyping] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [products, setProducts] = useState<Record<string, any>[]>([])
  const [productTypes, setProductTypes] = useState<Record<string, any>[]>([])

  const search = async (params: { query: string }): Promise<void> => {
    try {
      setLoading(true)
      const [res, resErr] = await searchCall(params)
      if (res) {
        setProducts(res.data.products)
        setProductTypes(res.data.productTypes)
      }
    } finally {
      setLoading(false)
    }
  }

  const resetSearch = () => {
    setProducts([])
    setProductTypes([])
    setSearchTerm('')
  }

  return (
    <SearchContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        isTyping,
        setIsTyping,
        loading,
        search,
        resetSearch,
        products,
        productTypes,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export function useSearchContext() {
  const context = useContext(SearchContext)
  if (context === undefined) {
    throw new Error('Context must be used within Provider')
  }
  return context
}
