import { createStackNavigator } from '@react-navigation/stack'

import Babyloania from '~/screens/Babyloania'
import CatalogueScreen from '~/screens/Catalogue'
import Categories from '~/screens/Categories'
import Category from '~/screens/Category'
import Collection from '~/screens/Collection'
import Collections from '~/screens/Collections'
import Product from '~/screens/Product'
import Products from '~/screens/Products'

import { CatalogueParamList } from '~/navigation/types'

const Catalogue = createStackNavigator<CatalogueParamList>()

function CatalogueStackNavigator() {
  return (
    <Catalogue.Navigator initialRouteName="Home">
      <Catalogue.Screen
        name="Home"
        component={CatalogueScreen}
        options={{
          headerShown: false,
        }}
      />
      <Catalogue.Screen
        name="Product"
        component={Product}
        options={{ headerShown: false }}
      />
      <Catalogue.Screen
        name="Products"
        component={Products}
        options={{ headerShown: false }}
      />
      <Catalogue.Screen
        name="Category"
        component={Category}
        options={{ headerShown: false }}
      />
      <Catalogue.Screen
        name="Categories"
        component={Categories}
        options={{ headerShown: false }}
      />
      <Catalogue.Screen
        name="Collection"
        component={Collection}
        options={{ headerShown: false }}
      />
      <Catalogue.Screen
        name="Collections"
        component={Collections}
        options={{ headerShown: false }}
      />
      <Catalogue.Screen
        name="Babyloania"
        component={Babyloania}
        options={{ headerShown: false }}
      />
    </Catalogue.Navigator>
  )
}
export default CatalogueStackNavigator
