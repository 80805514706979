import { useMemo } from 'react'
import { SafeAreaView } from 'react-native'

import { RootState, useAppSelector } from '~/store/store'

import ImageSlider from '~/components/base/ImageSlider/ImageSlider'

interface IProps {
  selectedProductItemId: number
  productItemsMap: Map<number, any> | undefined
}

export default function Thumbnail({
  selectedProductItemId,
  productItemsMap,
}: IProps) {
  const { product } = useAppSelector((state: RootState) => state.product)

  const selectedProductItemImages = useMemo(() => {
    if (productItemsMap && selectedProductItemId) {
      return productItemsMap.get(selectedProductItemId)?.Images
    }
    return []
  }, [productItemsMap, selectedProductItemId])

  const productImages = useMemo(() => {
    if (product && product.Images) {
      return product.Images
    }
    return [{ id: NaN, url: '', variants: [] }]
  }, [product])

  const allImages = useMemo(() => {
    if (selectedProductItemImages || selectedProductItemImages?.length) {
      return productImages
        .concat(selectedProductItemImages)
        .map((image, i) => ({ id: image.id, uri: image.url, title: 'Product' }))
    } else {
      return productImages.map((image, i) => ({
        id: image.id,
        uri: image.url,
        title: 'Product',
      }))
    }
  }, [selectedProductItemImages, productImages])

  return (
    <SafeAreaView>
      <ImageSlider
        data={allImages.map((val) => ({
          id: val.id,
          image: val.uri,
        }))}
        imageOrdering={product?.imageOrdering}
      />
    </SafeAreaView>
  )
}
