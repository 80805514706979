import { useEffect, useReducer } from 'react'
import { GestureResponderEvent, TouchableOpacity, View } from 'react-native'
import { ChevronRightIcon } from 'react-native-heroicons/solid'
import { useTailwind } from 'tailwind-rn/dist'

import Text from '~/components/StyledText'

import BellLight from '~/svg/BellLight'

type Props = {
  total: number
  onPress: (event: GestureResponderEvent) => void
  openReducer: [boolean, React.Dispatch<'OPEN' | 'CLOSE'>]
}

const Alert: React.FC<Props> = ({ total, onPress, openReducer }) => {
  const tailwind = useTailwind()

  const [isOpen, toggleIsOpen] = openReducer

  const handlePress = (e: GestureResponderEvent) => {
    toggleIsOpen('OPEN')
    onPress(e)
  }

  return (
    <TouchableOpacity
      style={[
        tailwind('flex flex-row items-center justify-between px-2 mt-5'),
        {
          height: 50,
          borderWidth: 1,
          borderRadius: 8,
          borderColor: '#B2E2DF',
          backgroundColor: isOpen ? 'rgb(213, 242, 240)' : '#fff',
        },
      ]}
      activeOpacity={1}
      onPress={handlePress}
    >
      <View style={[tailwind('flex flex-row items-center justify-between')]}>
        <BellLight />

        <Text style={tailwind('pl-2')}>
          Ada {total} transaksi menunggu pembayaran
        </Text>
      </View>
      <View>
        <ChevronRightIcon
          width={24}
          height={24}
          color="#808080"
          style={{
            paddingTop: 2,
          }}
        />
      </View>
    </TouchableOpacity>
  )
}

export default Alert
