import { useFocusEffect } from '@react-navigation/native'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { Pressable, ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { getAll, resetAssetAvailabilities } from '~/store/asset_availability'
import { fetchCall } from '~/store/helper'
import { get, resetProduct } from '~/store/product'
import { RootState, useAppDispatch, useAppSelector } from '~/store/store'

import Container from '~/components/Container'
import { SearchContextProvider } from '~/components/SearchContext'
import SearchResult from '~/components/SearchResult'
import Text from '~/components/StyledText'
import ReadyToRentBadge from '~/components/badges/ReadyToRentBadge'
import ContactBioma from '~/components/contacts/ContactBioma'
import CardDivider from '~/components/dividers/CardDivider'
import AppHeader from '~/components/headers/AppHeader'
import CollectionBanner from '~/components/home/CollectionBanner'
import DurationCalendar from '~/components/product/DurationCalendar'
import EmptyState from '~/components/product/EmptyState'
import PriceRetail from '~/components/product/PriceRetail'
import PriceVariable from '~/components/product/PriceVariable'
import ProductCheckout from '~/components/product/ProductCheckout'
import ProductDescription from '~/components/product/ProductDescription'
import productSelectedReducer, {
  initialState,
} from '~/components/product/Reducer'
import SelectDuration from '~/components/product/SelectDuration'
import Thumbnail from '~/components/product/Thumbnail'
import TopBanner from '~/components/product/TopBanner'
import Variant from '~/components/product/Variant'
import Wishlist from '~/components/product/Wishlist'
import { ActionTypes, ProductSelectedReducer } from '~/components/product/types'

import { getNextProductAvailabilityForProductItem } from '~/apis/product_availabilities'
import { convertToWeeklyRate } from '~/lib/pricingRate'
import { RootStackProps } from '~/navigation/types'

export default function Product({
  route,
  navigation,
}: RootStackProps<'Product'>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()

  const param = route.params

  const { product } = useAppSelector((state: RootState) => state.product)
  const { user } = useAppSelector((state: RootState) => state.user)
  const { assetAvailabilities } = useAppSelector(
    (state: RootState) => state.assetAvailability
  )

  const [reducerState, reducerDispatch] = useReducer<ProductSelectedReducer>(
    productSelectedReducer,
    initialState
  )

  // Availability date of product item
  const [availabilityDate, setAvailabilityDate] = useState<Date | undefined>()

  const productVariants = useMemo(() => {
    return product?.ProductItems?.flatMap((val) => val?.ProductVariants)
  }, [product])

  const productItemsMap: Map<number, any> | undefined = useMemo(() => {
    if (product) {
      if (product.ProductItems?.length) {
        reducerDispatch({
          type: ActionTypes.setSelectedProductItemId,
          value: product.ProductItems[0]?.id,
        })
        reducerDispatch({
          type: ActionTypes.setSelectedProductId,
          value: product.id,
        })

        const currentVariantSelected = reducerState.variantSelected
        product.ProductItems[0]?.ProductVariants?.map((variant: any) => {
          currentVariantSelected.set(variant.productVariantGroupId, variant.id)
        })

        reducerDispatch({
          type: ActionTypes.setVariantSelected,
          value: currentVariantSelected,
        })
      }
      return new Map(product.ProductItems?.map((item: any) => [item.id, item]))
    }
  }, [product])

  // TODO: Considering abstracting logic
  const pricingRates: any[] = useMemo(() => {
    if (productItemsMap && reducerState.selectedProductItemId) {
      return productItemsMap
        .get(reducerState.selectedProductItemId)
        ?.PricingRates.map((pricingRate: any) => ({
          duration: pricingRate.thresholdDuration,
          price: pricingRate.price,
          pricePerWeek: convertToWeeklyRate(pricingRate),
          id: pricingRate.id,
        }))
    }
    return []
  }, [productItemsMap, reducerState.selectedProductItemId])

  const productItem = useMemo(() => {
    return (
      productItemsMap && productItemsMap.get(reducerState.selectedProductItemId)
    )
  }, [productItemsMap, reducerState.selectedProductItemId])

  const itemDimention = useMemo(() => {
    if (!productItem) return '-'
    return `${productItem.lengthCm} x ${productItem.widthCm} x ${productItem.heightCm} cm`
  }, [productItem])

  const productDescription = useMemo((): string | undefined => {
    if (
      productItem?.description !== '' &&
      !isNaN(reducerState.selectedDuration) &&
      !isNaN(reducerState.selectedDuration)
    ) {
      return productItem?.description
    } else if (product?.description) {
      return product?.description
    }

    return undefined
  }, [
    product,
    productItem,
    reducerState.selectedDuration,
    reducerState.selectedProductItemId,
  ])

  useFocusEffect(
    useCallback(() => {
      if (param?.product_id) {
        dispatch(get(param?.product_id))
      }
      return () => {
        dispatch(resetProduct())
      }
    }, [param])
  )

  return (
    <SearchContextProvider>
      <Container>
        <AppHeader navigation={navigation} backVisible />
        <SearchResult navigation={navigation} />
        {user && !user.PrimaryAddress && (
          <View style={tailwind('p-4')}>
            <Pressable
              style={tailwind('bg-ice-500 p-2 rounded text-ice-800')}
              onPress={() =>
                navigation.navigate('Root', {
                  screen: 'Dashboard',
                  params: { screen: 'Address' },
                })
              }
            >
              <Text style={tailwind('mb-2')}>
                Yuk, atur alamat pengiriman untuk penyewaan agar proses checkout
                lancar
              </Text>
              <Text style={tailwind('underline font-bold')}>
                Atur alamat sekarang
              </Text>
            </Pressable>
          </View>
        )}
        <ScrollView
          style={tailwind('flex flex-col pt-5 w-full')}
          showsVerticalScrollIndicator={false}
        >
          <Thumbnail
            productItemsMap={productItemsMap}
            selectedProductItemId={reducerState.selectedProductItemId}
          ></Thumbnail>

          <View
            style={[
              tailwind('px-4'),
              {
                marginTop: 60,
              },
            ]}
          >
            <ReadyToRentBadge
              style={{
                height: 25,
                marginBottom: 15,
                maxWidth: '30%',
              }}
              productId={product?.id!}
            />

            <Text style={tailwind('text-2xl font-bold')}>
              {product?.brand} - {product?.name}
            </Text>

            <View style={tailwind('flex flex-row justify-between items-end')}>
              <View>
                <PriceVariable
                  selectedProductItemId={reducerState.selectedProductItemId}
                  pricingRates={pricingRates}
                  selectedDuration={reducerState.selectedDuration}
                />
                <PriceRetail
                  selectedProductItemId={reducerState.selectedProductItemId}
                  productItemsMap={productItemsMap}
                />
              </View>
              <Wishlist
                isActive={false}
                onPress={(e, state) => {
                  console.log(state)
                }}
              />
            </View>

            <View style={tailwind('mt-4 mb-4 pt-5')}>
              {productVariants && productVariants.length > 0 && (
                <React.Fragment>
                  <Text style={tailwind('text-sm mb-4')}>
                    Pilih varian produk
                  </Text>
                  <Variant setSelectedAction={reducerDispatch} />
                </React.Fragment>
              )}

              <Text style={tailwind('text-sm')}>Pilih durasi sewa</Text>
              <View style={tailwind('mt-2')}>
                {!reducerState.selectedProductItemId ? (
                  <EmptyState text="Pilih varian terlebih dahulu" />
                ) : (
                  <SelectDuration
                    pricingRates={pricingRates}
                    selectedDuration={reducerState.selectedDuration}
                    setSelectedAction={reducerDispatch}
                  />
                )}
              </View>

              <View style={tailwind('mt-4')}>
                <Text style={tailwind('text-sm mb-3')}>Atur tanggal sewa</Text>
                <DurationCalendar
                  selectedDate={reducerState.selectedDate}
                  setSelectedAction={reducerDispatch}
                  pricingRates={pricingRates}
                  selectedDuration={reducerState.selectedDuration}
                  selectedProductItemId={reducerState.selectedProductItemId}
                  allowedDates={assetAvailabilities || []}
                />
              </View>
            </View>

            <View style={tailwind('mt-4')}>
              <Text style={tailwind('font-bold text-sm')}>
                📙 Deskripsi produk
              </Text>

              <View style={tailwind('mt-4')}>
                <Text style={tailwind('font-bold text-xs mb-2')}>
                  Deskripsi
                </Text>

                {productDescription ? (
                  <ProductDescription description={productDescription} />
                ) : (
                  <Text>Belum ada deskripsi</Text>
                )}
              </View>

              <View style={tailwind('mt-4')}>
                <Text style={tailwind('font-bold text-xs mb-2')}>
                  Spesifikasi
                </Text>

                <View
                  style={tailwind(
                    'flex flex-row items-center justify-between mb-2'
                  )}
                >
                  <Text style={tailwind('')}>Berat</Text>

                  <Text style={tailwind('text-right')}>
                    {productItem ? `${productItem.weightKg} Kg` : `-`}
                  </Text>
                </View>

                <View
                  style={tailwind(
                    'flex flex-row items-center justify-between mb-2'
                  )}
                >
                  <Text style={tailwind('')}>Dimensi (P x L x T)</Text>

                  <Text style={tailwind('text-right')}>{itemDimention}</Text>
                </View>
              </View>
            </View>

            <View style={tailwind('mt-5')}>
              <Text style={tailwind('font-bold text-sm mb-2')}>
                🏢 Untuk Bisnis ingin Menyewa
              </Text>
              <ContactBioma navigation={navigation} isProductPage />
            </View>

            <View style={tailwind('mt-5')}>
              <Text style={tailwind('font-bold text-sm mb-2')}>
                🎉 Koleksi lainnya
              </Text>
              <View style={tailwind('-ml-4')}>
                <CollectionBanner navigation={navigation} />
              </View>
            </View>
          </View>
          <CardDivider tailwindStyle="mt-6" />
        </ScrollView>
        <ProductCheckout
          selectedProductId={reducerState.selectedProductId}
          selectedDate={reducerState.selectedDate}
          selectedDuration={reducerState.selectedDuration}
          selectedProductItemId={reducerState.selectedProductItemId}
          pricingRates={pricingRates}
          navigation={navigation}
          // Not: Using useReducer, we need to pass it to child? Seems like a complicated maneuver. Perhaps redux is better?
          reducerDispatch={reducerDispatch}
        />
      </Container>
    </SearchContextProvider>
  )
}
