import * as React from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

const PaymentSuccess = (props: any) => (
  <Svg
    width={120}
    height={120}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G clipPath="url(#a)">
      <Path
        d="M84.585 44.505H17.732a.711.711 0 0 1-.72-.717c0-.402.317-.718.72-.718h66.853c.402 0 .718.316.718.718a.711.711 0 0 1-.718.717ZM84.585 65.883a.711.711 0 0 1-.719-.717V45.94c0-.402.317-.717.72-.717.402 0 .718.315.718.717v19.226a.711.711 0 0 1-.719.718ZM17.732 64.018a.711.711 0 0 1-.72-.717V45.94c0-.402.317-.717.72-.717.402 0 .718.315.718.717V63.3a.711.711 0 0 1-.718.718ZM17.732 92.57a.711.711 0 0 1-.72-.717V80.088c0-.402.317-.718.72-.718.402 0 .718.316.718.718v11.765a.711.711 0 0 1-.718.717ZM71.214 92.57H20.319a.711.711 0 0 1-.718-.717c0-.402.316-.717.718-.717h50.895c.403 0 .719.315.719.717a.711.711 0 0 1-.719.718ZM15.719 78.796A.711.711 0 0 1 15 78.08V65.166c0-.402.316-.718.719-.718.402 0 .719.316.719.718v12.913a.711.711 0 0 1-.72.717ZM44.617 65.883H17.732a.711.711 0 0 1-.72-.717c0-.402.317-.718.72-.718h26.885c.402 0 .718.316.718.718a.711.711 0 0 1-.718.717ZM44.617 78.796H17.732a.711.711 0 0 1-.72-.717c0-.402.317-.718.72-.718h26.885c.402 0 .718.316.718.718a.711.711 0 0 1-.718.717ZM46.917 78.797a.704.704 0 0 1-.69-.531.727.727 0 0 1 .503-.89c.115-.043 3.062-.975 3.062-5.767 0-4.836-3.58-5.754-3.623-5.754a.713.713 0 0 1-.532-.86.714.714 0 0 1 .863-.532c.043.015 1.236.302 2.4 1.349 1.065.947 2.33 2.726 2.33 5.81 0 5.927-3.954 7.103-4.126 7.146a.84.84 0 0 1-.187.029Z"
        fill="#00433E"
      />
      <Path
        d="M44.257 75.353a3.66 3.66 0 1 1 0-7.318 3.66 3.66 0 1 1 0 7.318Zm0-5.883a2.237 2.237 0 0 0-2.228 2.224 2.237 2.237 0 0 0 2.228 2.224 2.237 2.237 0 0 0 2.228-2.224 2.237 2.237 0 0 0-2.228-2.224Z"
        fill="#00433E"
      />
      <Path
        d="M22.907 42.639a.652.652 0 0 1-.489-.2.722.722 0 0 1-.028-1.02l19.552-20.804a.726.726 0 0 1 1.021-.029.722.722 0 0 1 .029 1.02L23.439 42.408a.72.72 0 0 1-.532.23ZM49.648 28.004a.71.71 0 0 1-.489-.2l-5.175-4.88a.722.722 0 0 1-.029-1.018.726.726 0 0 1 1.02-.029l5.176 4.879a.722.722 0 0 1-.503 1.248ZM31.246 42.639a.71.71 0 0 1-.59-.302.727.727 0 0 1 .173-1.004l4.744-3.3a.73.73 0 0 1 1.007.172c.23.33.143.775-.173 1.005l-4.744 3.3a.75.75 0 0 1-.417.129ZM38.578 37.76a.728.728 0 0 1-.604-.33.714.714 0 0 1 .201-.99L57.44 23.814a.714.714 0 1 1 .79 1.19L38.967 37.632a.663.663 0 0 1-.388.13ZM62.3 31.448a.75.75 0 0 1-.604-.316l-3.163-4.735a.714.714 0 1 1 1.194-.789l3.163 4.735c.215.33.129.774-.202.99a.724.724 0 0 1-.388.115ZM46.054 42.64a.703.703 0 0 1-.632-.374.726.726 0 0 1 .287-.976l24.153-13.056a.729.729 0 0 1 .978.287.726.726 0 0 1-.287.976L46.399 42.553a.767.767 0 0 1-.345.086ZM74.089 36.04a.717.717 0 0 1-.633-.388l-2.588-4.878a.718.718 0 0 1 .302-.976.721.721 0 0 1 .978.301l2.588 4.878a.718.718 0 0 1-.302.976.767.767 0 0 1-.345.086ZM77.396 42.783a.71.71 0 0 1-.647-.402l-2.444-5.022a.72.72 0 0 1 1.294-.631l2.444 5.022a.717.717 0 0 1-.33.96.843.843 0 0 1-.317.073ZM88.466 99.6c-9.115 0-16.533-7.403-16.533-16.5 0-9.096 7.418-16.5 16.533-16.5C97.581 66.6 105 74.005 105 83.1c0 9.097-7.419 16.5-16.534 16.5Zm0-31.565c-8.324 0-15.096 6.758-15.096 15.066 0 8.307 6.772 15.065 15.096 15.065s15.096-6.758 15.096-15.065c0-8.308-6.772-15.066-15.096-15.066Z"
        fill="#A6DB68"
      />
      <Path
        d="m85.95 90.36-.417-.415a1.038 1.038 0 0 1 0-1.464l10.783-10.76a1.043 1.043 0 0 1 1.467 0l.416.415a1.038 1.038 0 0 1 0 1.464L87.418 90.36a1.043 1.043 0 0 1-1.467 0Z"
        fill="#A6DB68"
      />
      <Path
        d="m87.417 90.36.417-.416a1.038 1.038 0 0 0 0-1.463l-5.377-5.38a1.043 1.043 0 0 0-1.467 0l-.417.415a1.038 1.038 0 0 0 0 1.464l5.392 5.38c.388.402 1.05.402 1.452 0Z"
        fill="#A6DB68"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(15 20.4)" d="M0 0h90v79.2H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default PaymentSuccess
