import {
  ActionSheetProvider,
  connectActionSheet,
} from '@expo/react-native-action-sheet'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import React from 'react'

import OrderHistoryScreen from '~/screens/OrderHistory'
import OrderOngoingScreen from '~/screens/OrderOngoing'

import Container from '~/components/Container'
import { SearchContextProvider } from '~/components/SearchContext'
import AppHeader from '~/components/headers/AppHeader'

import { BottomTabProps } from '~/navigation/types'

const Tab = createMaterialTopTabNavigator()

const TabNavigation = () => {
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarActiveTintColor: '#00443E',
        tabBarInactiveTintColor: '#808080',
        tabBarIndicatorStyle: {
          backgroundColor: '#B2E2DF',
        },
        tabBarLabelStyle: {
          fontWeight: 'bold',
        },
      }}
    >
      <Tab.Screen
        name="Ongoing"
        component={OrderOngoingScreen}
        options={{
          title: 'Berlangsung',
        }}
      />
      <Tab.Screen
        name="History"
        component={OrderHistoryScreen}
        options={{
          title: 'Riwayat',
        }}
      />
    </Tab.Navigator>
  )
}

function Order({ route, navigation }: BottomTabProps<'Order'>) {
  return (
    <SearchContextProvider>
      <Container>
        <ActionSheetProvider useNativeDriver={true}>
          <React.Fragment>
            <AppHeader navigation={navigation} />
            <TabNavigation />
          </React.Fragment>
        </ActionSheetProvider>
      </Container>
    </SearchContextProvider>
  )
}

export default connectActionSheet(Order)
