import { GestureResponderEvent, Linking, Pressable, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import Text from '~/components/StyledText'
import Button from '~/components/base/Button/Button'

import { toCurrency } from '~/lib/money'
import ChatDots from '~/svg/ChatDots'

interface IProps {
  total?: number
  btnText: string
  onPress: (event: GestureResponderEvent) => void
  disabled?: boolean
}

export default function CartCheckout({
  total,
  btnText,
  onPress,
  disabled = false,
}: IProps) {
  const tailwind = useTailwind()

  return (
    <View
      style={[
        tailwind('flex flex-row p-4 justify-between bg-white sticky bottom-0'),
        { shadowColor: 'rgba(0,0,0,0.25)', shadowRadius: 8, elevation: 5 },
      ]}
    >
      <View>
        <Text style={tailwind('text-xs text-gray-500')}>Total Keranjang</Text>
        <Text style={tailwind('text-sm font-bold leading-5')}>
          {total ? toCurrency(total) : '-'}
        </Text>
      </View>
      <View style={tailwind('flex flex-row items-center')}>
        <Button
          color="mute"
          style={[
            tailwind('flex items-center justify-center rounded mr-1'),
            {
              width: 44,
              height: 35,
              borderRadius: 5,
            },
          ]}
          onPress={() => {
            Linking.openURL('https://wa.me/6281284455345')
          }}
        >
          <ChatDots />
        </Button>

        <Button
          text={btnText}
          onPress={onPress}
          color="primary"
          disabled={disabled}
        />
      </View>
    </View>
  )
}
