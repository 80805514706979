import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CollectionAttributesFull } from '@withbioma/apitype-publicapi-public/lib/v1/common/collection'

import {
  constructErrorPayload,
  constructExtraReducer,
  constructSuccessPayload,
} from '~/store/helper'

import {
  getAllByParam as getAllByParamCall,
  getAll as getAllCall,
  get as getCall,
} from '~/apis/collections'

interface IState {
  collections: CollectionAttributesFull[]
  collection?: CollectionAttributesFull
  loading: Record<string, any>
  responses: Record<string, any>
}
const initialState: IState = {
  collections: [],
  collection: undefined,
  loading: {},
  responses: {},
}

export const get = createAsyncThunk(
  'collection/get',
  async (collectionId: string, { rejectWithValue }) => {
    const [res, resErr] = await getCall(collectionId)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const getAll = createAsyncThunk(
  'collection/getAll',
  async (_, { rejectWithValue }) => {
    const [res, resErr] = await getAllCall()
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const getAllByParam = createAsyncThunk(
  'collection/getAllByParam',
  async (params: object, { rejectWithValue }) => {
    const [res, resErr] = await getAllByParamCall(params)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const getAllByCollectionIds = createAsyncThunk(
  'collection/getAllByCollectionIds',
  async (paramsRaw: { collectionIds: number[] }, { rejectWithValue }) => {
    const params = {
      collectionIds: paramsRaw.collectionIds.join(','),
    }
    const [res, resErr] = await getAllByParamCall(params)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const reducerSlice = createSlice({
  name: 'collection',
  initialState,
  reducers: {
    resetResponse(state, action) {
      const apiName = action.payload
      state.responses = {
        ...state.responses,
        [apiName]: null,
      }
    },
    resetProductTypes(state: IState) {
      state.collections = []
    },
    resetProductType(state: IState) {
      state.collection = undefined
    },
  },
  extraReducers: (builder) => {
    constructExtraReducer(
      builder,
      get,
      (state: IState, action: PayloadAction<any>) => {
        state.collection = action.payload!.body.collection
      }
    )
    constructExtraReducer(
      builder,
      getAll,
      (state: IState, action: PayloadAction<any>) => {
        state.collections = action.payload!.body.collections
      }
    )
    constructExtraReducer(
      builder,
      getAllByParam,
      (state: IState, action: PayloadAction<any>) => {
        state.collections = action.payload!.body.collections
      }
    )
    constructExtraReducer(
      builder,
      getAllByCollectionIds,
      (state: IState, action: PayloadAction<any>) => {
        state.collections = action.payload!.body.collections
      }
    )
  },
})

export const { resetResponse, resetProductTypes, resetProductType } =
  reducerSlice.actions
export default reducerSlice.reducer
