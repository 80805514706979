import React, { useState } from 'react'
import { Alert, Modal, Pressable, StyleSheet, View } from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import Text from '~/components/StyledText'

import CloseIcon from '~/svg/Close'

type Props = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const CartModal: React.FC<Props> = ({ open, setOpen }) => {
  const tailwind = useTailwind()

  if (!open) return <React.Fragment />

  return (
    <View style={[styles.container]}>
      <Modal
        animationType="fade"
        transparent={true}
        visible
        onRequestClose={() => setOpen(!open)}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View
              style={tailwind(
                'w-full flex flex-row items-center justify-between'
              )}
            >
              <Text style={styles.modalText}>Mengenai Keranjang</Text>
              <Pressable style={[styles.button]} onPress={() => setOpen(!open)}>
                <CloseIcon />
              </Pressable>
            </View>

            <Text style={tailwind('text-gray-500')}>
              Kami hanya dapat menjaga stok untuk keranjang anda selama 30 menit
              agar proses sistemasi stokist kami tetap dapat diandalkan. Waktu
              tersebut akan direset ulang ketika produk baru masuk ke dalam
              keranjangmu.
            </Text>
            <Text style={tailwind('mt-4 text-gray-500')}>
              Bila waktu telah habis, kamu dapat melakukan cek stok secara
              otomatis, bila stok tersedia, produk akan kembali ke keranjangmu
              secara otomatis.
            </Text>
          </View>
        </View>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 25,
    alignItems: 'flex-start',
    shadowColor: '#000',
    maxWidth: 'calc(400px - 50px)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: 'absolute',
    top: '10%',
  },
  button: {
    padding: 0,
    elevation: 2,
    marginTop: '-10px',
    border: 0,
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
    fontSize: 18,
  },
})

export default CartModal
