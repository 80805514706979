import { useFocusEffect } from '@react-navigation/native'
import { useCallback } from 'react'
import { Pressable, ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { getAll } from '~/store/product'
import { RootState, useAppDispatch, useAppSelector } from '~/store/store'

import Container from '~/components/Container'
import { SearchContextProvider } from '~/components/SearchContext'
import SearchResult from '~/components/SearchResult'
import Text from '~/components/StyledText'
import ContactBioma from '~/components/contacts/ContactBioma'
import AppHeader from '~/components/headers/AppHeader'
import CollectionBanner from '~/components/home/CollectionBanner'
import Recommendation from '~/components/home/Recommendation'
import ProductTile from '~/components/products/ProductTile'

import { DashboardProps, RootStackProps } from '~/navigation/types'

export default function Home({
  navigation,
}: DashboardProps<'Home'> & RootStackProps<'Products'>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()

  const { products } = useAppSelector((state: RootState) => state.product)

  const { user } = useAppSelector((state: RootState) => state.user)

  useFocusEffect(
    useCallback(() => {
      dispatch(getAll({ limit: 10 }))
    }, [])
  )

  return (
    <SearchContextProvider>
      <Container>
        <ScrollView
          style={tailwind('flex flex-col')}
          showsVerticalScrollIndicator={false}
        >
          {/* Trigger deploy */}
          <AppHeader navigation={navigation} />
          <SearchResult navigation={navigation} />
          {user && !user.PrimaryAddress && (
            <View style={tailwind('p-4')}>
              <Pressable
                style={tailwind('bg-ice-500 p-2 rounded text-ice-800')}
                onPress={() => navigation.navigate('Address')}
              >
                <Text style={tailwind('mb-2')}>
                  Yuk, atur alamat pengiriman untuk penyewaan agar proses
                  checkout lancar
                </Text>
                <Text style={tailwind('underline font-bold')}>
                  Atur alamat sekarang
                </Text>
              </Pressable>
            </View>
          )}
          <CollectionBanner navigation={navigation} />
          <Recommendation
            navigation={navigation}
            collectionId={1008}
            title="Terbaik dari Babyloania"
            style={tailwind('mt-4')}
          />
          <Recommendation navigation={navigation} collectionId={17} />
          <ContactBioma navigation={navigation} />
          <Recommendation
            navigation={navigation}
            collectionId={11}
            title="Commercial Kitchen Equipment"
            style={tailwind('mt-2')}
          />
          <Recommendation
            navigation={navigation}
            collectionId={13}
            title="Perlengkapan Ruang Keluarga"
            style={tailwind('mt-2')}
          />
          <Recommendation
            navigation={navigation}
            collectionId={12}
            title="Peralatan Dapur"
            style={tailwind('mt-2')}
          />
          <Recommendation
            navigation={navigation}
            collectionId={10}
            title="Perlengkapan Kantor"
            style={tailwind('mt-2')}
          />
          <View style={tailwind('flex flex-col')}>
            <View
              style={tailwind(
                'flex flex-row items-center justify-between mt-4'
              )}
            >
              <Text style={tailwind('text-lg px-4')}>🛒 Sewa di Bioma</Text>
              <Pressable onPress={() => navigation.navigate('Products')}>
                <Text style={tailwind('text-teal px-4')}>Lihat Semua</Text>
              </Pressable>
            </View>
            <ScrollView
              style={tailwind('mt-4 pl-4')}
              horizontal
              showsHorizontalScrollIndicator={false}
            >
              {products?.map((product, i) => (
                <View
                  style={[tailwind('mb-6 pr-4 w-full'), { maxWidth: 140 }]}
                  key={i}
                >
                  <ProductTile
                    product={product}
                    navigation={navigation}
                    height={124}
                  />
                </View>
              ))}
            </ScrollView>
          </View>
        </ScrollView>
      </Container>
    </SearchContextProvider>
  )
}
