import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { InitiateResetPasswordRequest } from '@withbioma/apitype-publicapi-public/lib/v1/api'
import type { ResetPasswordRequest } from '@withbioma/apitype-publicapi-public/lib/v1/api'

import {
  constructErrorPayload,
  constructExtraReducer,
  constructSuccessPayload,
} from '~/store/helper'

import {
  initiateResetPassword as initiateResetPasswordCall,
  resetPassword as resetPasswordCall,
} from '~/apis/resetPassword'

interface IState {
  loading: object
  responses: object
}
const initialState: IState = {
  loading: {},
  responses: {},
}

export const initiateResetPassword = createAsyncThunk(
  'resetPassword/initiate',
  async (
    payload: InitiateResetPasswordRequest,
    { rejectWithValue, dispatch }
  ) => {
    const [res, resErr] = await initiateResetPasswordCall(payload)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const resetPassword = createAsyncThunk(
  'resetPassword/resetPassword',
  async (payload: ResetPasswordRequest, { rejectWithValue, dispatch }) => {
    const [res, resErr] = await resetPasswordCall(payload)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const reducerSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    resetResponse(state: any, action: PayloadAction<any>) {
      const apiName = action.payload
      state.responses = {
        ...state.responses,
        [apiName]: null,
      }
    },
  },
  extraReducers: (builder) => {
    constructExtraReducer(builder, initiateResetPassword)
    constructExtraReducer(builder, resetPassword)
  },
})

export const { resetResponse } = reducerSlice.actions

export default reducerSlice.reducer
