import CustomTabBar from './CustomTabBar'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import React from 'react'

import CatalogueNavigator from '~/navigation/CatalogueNavigator'
import DashboardNavigator from '~/navigation/DashboardNavigator'
import OrderNavigator from '~/navigation/OrderNavigator'
import { BottomTabParamList } from '~/navigation/types'

const BottomTab = createBottomTabNavigator<BottomTabParamList>()

function BottomTabNavigator() {
  return (
    <BottomTab.Navigator
      initialRouteName="Dashboard"
      tabBar={(props) => <CustomTabBar {...props} />}
    >
      <BottomTab.Screen
        name="Dashboard"
        component={DashboardNavigator}
        options={{
          headerShown: false,
          title: 'Home',
        }}
      />
      <BottomTab.Screen
        name="Catalogue"
        component={CatalogueNavigator}
        options={{
          headerShown: false,
          title: 'Katalog',
        }}
      />

      <BottomTab.Screen
        name="Order"
        component={OrderNavigator}
        options={{
          headerShown: false,
          title: 'Pesanan',
        }}
      />
    </BottomTab.Navigator>
  )
}
export default BottomTabNavigator
