import React, { SetStateAction } from 'react'
import { Pressable, View } from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import Text from '~/components/StyledText'
import CartInfoModal from '~/components/cart/Modal'

import InformationIcon from '~/svg/Information'

type TimeRemainingState = {
  minutes: number
  seconds: number
}

type Props = {
  visibleState: [boolean, React.Dispatch<SetStateAction<boolean>>]
  timeRemaining: TimeRemainingState
}

const AvailableStockInfo: React.FC<Props> = ({
  visibleState,
  timeRemaining,
}) => {
  const tailwind = useTailwind()

  const [modalVisible, setModalVisible] = visibleState

  return (
    <React.Fragment>
      <View
        style={[
          tailwind(
            'pt-14 mt-16 bg-yellow-300 px-4 mx-4 py-2 mb-4 flex flex-row justify-between'
          ),
          {
            borderRadius: 8,
          },
        ]}
      >
        <Text style={tailwind('text-sm')}>
          Sisa waktu jaminan stok tersedia:{' '}
          <Text style={tailwind('font-bold')}>
            {timeRemaining.minutes}m{timeRemaining.seconds.toFixed(0)}s
          </Text>
        </Text>

        <Pressable onPress={() => setModalVisible(true)}>
          <InformationIcon />
        </Pressable>
      </View>

      <CartInfoModal open={modalVisible} setOpen={setModalVisible} />
    </React.Fragment>
  )
}

export default AvailableStockInfo
