import { Asset } from 'expo-asset'
import React, { useRef, useState } from 'react'
import {
  Animated,
  ImageResizeMode,
  ImageStyle,
  ImageURISource,
  View,
  ViewStyle,
} from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

type ImageType =
  | number
  | Animated.Value
  | Animated.AnimatedInterpolation
  | Animated.WithAnimatedObject<ImageURISource>
  | Animated.WithAnimatedArray<ImageURISource>

export default function Image({
  height,
  width,
  source,
  resizeMode,
  style,
  imageStyles,
  rounded = false,
  transparent = false,
}: {
  height?: number
  width?: number
  source: ImageType
  resizeMode?: ImageResizeMode
  style?: ViewStyle
  imageStyles?: ImageStyle[]
  rounded?: boolean
  transparent?: boolean
}) {
  const tailwind = useTailwind()

  const [isFadeInFinished, toggleFadeInFinished] = useState(false)

  const imageOpacity = useRef(new Animated.Value(0)).current
  const fadeInImage = (data: any) => {
    Animated.timing(imageOpacity, {
      useNativeDriver: true,
      toValue: 1,
      duration: 200,
    }).start(() => toggleFadeInFinished(true))
  }

  return (
    <View style={style}>
      <View
        style={[
          rounded ? tailwind('rounded-lg') : undefined,
          {
            backgroundColor: !transparent
              ? 'rgb(232, 232, 232)'
              : 'transparent',
          },
          {
            width: width ?? '100%',
            height: height ?? 168,
          },
          style,
        ]}
      />

      <Animated.Image
        onLoad={(e) => fadeInImage(e)}
        source={source}
        resizeMode={resizeMode}
        style={[
          imageStyles?.concat([
            {
              opacity: imageOpacity as any,
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              height: height ?? 168,
              width: '100%',
            },
          ]),
          rounded ? tailwind('rounded-lg') : undefined,
        ]}
      />
    </View>
  )
}
