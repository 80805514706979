import { MaterialIcons } from '@expo/vector-icons'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { Pressable, View } from 'react-native'
import { CalendarProps, Calendar as _ } from 'react-native-calendars'
import { useTailwind } from 'tailwind-rn'

import {
  getAll,
  getAllAvailabilityForProductItem,
  resetAssetAvailabilities,
} from '~/store/asset_availability'
import { fetchCall } from '~/store/helper'
import { get } from '~/store/productItem'
import { RootState, useAppDispatch, useAppSelector } from '~/store/store'

import Text from '~/components/StyledText'
import Calendar from '~/components/base/Calendar/Calendar'
import { ActionTypes, ProductSelectedAction } from '~/components/product/types'

import { getNextProductAvailabilityForProductItem } from '~/apis/product_availabilities'
import { DATE_CONFIG, toDate, toDateTime } from '~/lib/datetime'

const formatDate = 'dd MMM yyyy'

interface IProps {
  selectedDate: Date | null
  setSelectedAction: React.Dispatch<any>
  selectedDuration: number
  pricingRates: any[]
  selectedProductItemId: number
  allowedDates: any[]
}

export default function DurationCalendar({
  pricingRates,
  selectedDate,
  setSelectedAction,
  selectedDuration,
  selectedProductItemId,
  allowedDates,
}: IProps) {
  const dispatch = useAppDispatch()
  const product = useAppSelector((state: RootState) => state.product.product)
  const productItem = useAppSelector(
    (state: RootState) => state.productItem.productItem
  )
  const tailwind = useTailwind()

  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const [initialDate, setInitialDate] = useState(
    DateTime.fromJSDate(new Date())!.toFormat(DATE_CONFIG.DATE_FORMAT)
  )

  const pricingRate = useMemo(() => {
    if (pricingRates && pricingRates.length)
      return pricingRates.find((value) => value?.id == selectedDuration)
  }, [pricingRates, selectedDuration])

  const markedDates = useMemo(() => {
    const dates: CalendarProps['markedDates'] | undefined = {}

    if (
      pricingRates &&
      pricingRates.length &&
      pricingRate &&
      allowedDates.length
    ) {
      for (let i = 0; i < allowedDates.length; i++) {
        const curr = allowedDates[i]
        const dateString = DateTime.fromISO(curr.date)!.toFormat(
          DATE_CONFIG.DATE_FORMAT
        )
        if (!curr.assetIds.length) {
          dates[dateString] = {
            inactive: true,
            disabled: true,
            disableTouchEvent: true,
          }
        }
      }
    }

    if (selectedDate && pricingRate) {
      const dur = pricingRate
      ;[...Array(dur!.duration).keys()].forEach((a, i) => {
        const dateString = DateTime.fromJSDate(selectedDate)
          .plus({ days: i })
          .toFormat(DATE_CONFIG.DATE_FORMAT)

        dates[dateString] = {
          startingDay: i === 0,
          endingDay: i === parseInt(dur!.duration) - 1,
          selected: i > 0 && i < parseInt(dur!.duration) - 1,
          color:
            i > 0 && i < parseInt(dur!.duration) - 1 ? '#B2E2DF' : '#00443E',
          customTextStyle: {
            color:
              i > 0 && i < parseInt(dur!.duration) - 1
                ? 'rgb(45, 65, 80)'
                : 'rgb(255, 255, 255)',
          },
          disableTouchEvent: false,
        }
      })
    }

    return dates
  }, [selectedDuration, selectedDate, pricingRates, allowedDates, pricingRate])

  const itemDisabled =
    selectedProductItemId == 0 ||
    !pricingRate ||
    !(productItem as any)?.Assets?.length // TODO: Update types

  const isDateSelected = !!selectedDate

  useEffect(() => {
    if (product && selectedProductItemId) {
      // Reset state first,
      setShowCalendar(false)
      dispatch(
        get({
          productId: String(product.id),
          productItemId: String(selectedProductItemId),
        })
      )
    }
  }, [product, selectedProductItemId])

  // Set available dates on first init
  useEffect(() => {
    if (!itemDisabled && product && selectedProductItemId) {
      dispatch(
        getAllAvailabilityForProductItem({
          productId: product!.id,
          productItemId: selectedProductItemId,
          durationDays: pricingRate.thresholdDuration,
        })
      )
    }
  }, [
    itemDisabled,
    product,
    pricingRate,
    selectedProductItemId,
    selectedDuration,
  ])

  // Everytime duration changed, close the calendar
  useEffect(() => {
    setShowCalendar(false)
  }, [selectedDuration])

  return (
    <View>
      <Pressable
        disabled={itemDisabled}
        style={[
          tailwind(
            `rounded py-3 px-2 border flex flex-row items-center justify-between`
          ),
          {
            borderColor: itemDisabled ? 'rgb(159, 159, 159)' : '#00443E',
            opacity: itemDisabled ? 0.6 : 1,
          },
        ]}
        onPress={() => setShowCalendar(!showCalendar)}
      >
        <Text style={[{ color: isDateSelected ? '#00443E' : '#333333' }]}>
          {selectedDate
            ? toDateTime(selectedDate)!.toFormat(formatDate)
            : 'Pilih Tanggal Sewa'}
        </Text>
        <View>
          <MaterialIcons
            size={24}
            style={[
              tailwind(`mr-2`),
              {
                color: isDateSelected ? '#00443E' : '#333333',
              },
            ]}
            name={'date-range'}
          />
        </View>
      </Pressable>

      {showCalendar && (
        <Calendar
          onChange={(date) => {
            setSelectedAction({
              type: ActionTypes.setSelectedDate,
              value: date,
            })
          }}
          initialDate={initialDate}
          minDate={initialDate}
          markingType={'period'}
          markedDates={markedDates}
        />
      )}
    </View>
  )
}
