import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const Bell = (props: any) => (
  <Svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11V13ZM5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13L5 11ZM19 11L5 11L5 13L19 13V11Z"
      fill="#333333"
    />
    <Path
      d="M10.2929 18.7071C10.6834 19.0976 11.3166 19.0976 11.7071 18.7071C12.0976 18.3166 12.0976 17.6834 11.7071 17.2929L10.2929 18.7071ZM5 12L4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071L5 12ZM11.7071 6.70711C12.0976 6.31658 12.0976 5.68342 11.7071 5.29289C11.3166 4.90237 10.6834 4.90237 10.2929 5.29289L11.7071 6.70711ZM11.7071 17.2929L5.70711 11.2929L4.29289 12.7071L10.2929 18.7071L11.7071 17.2929ZM5.70711 12.7071L11.7071 6.70711L10.2929 5.29289L4.29289 11.2929L5.70711 12.7071Z"
      fill="#333333"
    />
  </Svg>
)

export default Bell
