import { useFocusEffect } from '@react-navigation/native'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Image, Pressable, ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { resetPassword } from '~/store/resetPassword'
import { useAppDispatch } from '~/store/store'

import Container from '~/components/Container'
import Text from '~/components/StyledText'
import Button from '~/components/base/Button/Button'
import Input from '~/components/base/Input'
import InputPassword from '~/components/base/InputPassword'
import BackHeader from '~/components/headers/BackHeader'

import { passwordValidator } from '~/lib/pattern'
import { RootStackProps } from '~/navigation/types'

export default function ResetPassword({
  route,
  navigation,
}: RootStackProps<'ResetPassword'>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()
  const params = route.params

  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    getValues,
  } = useForm({
    mode: 'onChange',
  })
  const isError = Object.keys(errors)?.length > 0

  const [passVisibleState, setVisiblePass] = useState(false)
  const [passConfirmVisibleState, setVisiblePassConfirm] = useState(false)

  const onSubmit = async (data: any) => {
    const res = await dispatch(
      resetPassword({
        token: params?.token!,
        password: data.password,
      })
    )

    if (resetPassword.fulfilled.match(res)) {
      navigation.navigate('ResetPasswordSuccess')
    }
  }

  // Trigger form on init
  useFocusEffect(
    useCallback(() => {
      trigger()
    }, [])
  )

  return (
    <Container>
      <BackHeader navigation={navigation} text={'Reset Password'} />

      <ScrollView
        style={[
          tailwind('pt-14 flex flex-col w-full relative'),
          {
            height: 'calc(100% - 60px)',
            paddingBottom: 20,
          },
        ]}
        contentContainerStyle={{
          height: '100%',
        }}
      >
        <View
          style={[
            tailwind('pt-6 px-4 relative'),
            {
              height: 'calc(100% - 60px)',
            },
          ]}
        >
          <Image
            source={require('~/assets/images/bioma-logo.png')}
            style={[
              {
                width: 72,
                height: 20,
                resizeMode: 'contain',
              },
              tailwind('mb-4 p-2'),
            ]}
          />
          <Text
            style={[
              tailwind('mb-5 text-3xl font-bold'),
              {
                color: '#00433E',
              },
            ]}
          >
            Reset Password
          </Text>

          <Text style={tailwind('text-base mb-5')}>Masukkan Password Baru</Text>

          <View>
            <View style={tailwind('mb-5')}>
              <InputPassword
                style={[
                  tailwind(
                    'p-4 pr-6 w-full bg-white border border-gray-200 rounded'
                  ),
                ]}
                label="Password"
                placeholder={'Password'}
                control={control}
                rules={{
                  required: true,
                  validate: async (value: string) => {
                    const candidate = getValues('confirmPassword')
                    const { isValid, message } = passwordValidator(
                      value,
                      candidate
                    )

                    return isValid || message
                  },
                }}
                name="password"
                defaultValue=""
                passwordVisible={passVisibleState}
                onTogglePasswordVisible={() =>
                  setVisiblePass(!passVisibleState)
                }
                errors={errors}
              />
            </View>

            <View style={tailwind('mb-5')}>
              <InputPassword
                style={[
                  tailwind(
                    'p-4 pr-6 w-full bg-white border border-gray-200 rounded'
                  ),
                ]}
                label="Konfirmasi Password"
                placeholder={'Konfirmasi Password'}
                control={control}
                rules={{
                  required: true,
                  validate: async (value: string) => {
                    const candidate = getValues('password')
                    const { isValid, message } = passwordValidator(
                      value,
                      candidate
                    )

                    return isValid || message
                  },
                }}
                name="confirmPassword"
                defaultValue=""
                passwordVisible={passConfirmVisibleState}
                onTogglePasswordVisible={() =>
                  setVisiblePassConfirm(!passVisibleState)
                }
                errors={errors}
              />
            </View>
          </View>
        </View>

        <View style={[tailwind('sticky bottom-1.5 w-full px-4')]}>
          <Button
            color="primary"
            text="Kirim"
            disabled={isError}
            style={tailwind('text-center py-3')}
            onPress={handleSubmit(onSubmit)}
          />
        </View>
      </ScrollView>
    </Container>
  )
}
