import { useFocusEffect } from '@react-navigation/native'
import { CartItemAttributesFull } from '@withbioma/apitype-publicapi-customer/v1/common/cart_item_full'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Modal, Pressable, ScrollView, StyleSheet, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { getAll } from '~/store/asset_availability'
import { getCurrentCart, recreateCart, removeCartItem } from '~/store/cart'
import { RootState, useAppDispatch, useAppSelector } from '~/store/store'

import Container from '~/components/Container'
import Text from '~/components/StyledText'
import Button from '~/components/base/Button/Button'
import CustomActionSheet from '~/components/base/CustomActionSheet'
import AvailableStockInfo from '~/components/cart/AvailableStockInfo'
import CartItemRow from '~/components/cart/CartItemRow'
import EmptyCart from '~/components/cart/EmptyCart'
import StockValidityInfo from '~/components/cart/StockValidityInfo'
import UnavailableStockInfo from '~/components/cart/UnavailableStockInfo'
import CartCheckout from '~/components/checkout/CartCheckout'
import BackHeader from '~/components/headers/BackHeader'

import { RootStackProps } from '~/navigation/types'
import CloseIcon from '~/svg/Close'

export default function Cart({ route, navigation }: RootStackProps<'Cart'>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()

  const { user } = useAppSelector((state: RootState) => state.user)
  const { cart } = useAppSelector((state: RootState) => state.cart)
  const { authenticated } = useAppSelector((state: RootState) => state.auth)

  const [timeRemaining, setTimeRemaining] = useState({
    minutes: 0,
    seconds: 0,
  })

  const [modalVisible, setModalVisible] = useState(false)
  const [termsConditionVisible, setTermsConditionVisible] = useState(false)
  const [actionSheetVisible, setActionSheetVisible] = useState(false)

  const [unavailableIds, setUnavailableIds] = useState<number[]>([])
  const [allNotAvailable, setAllNotAvailable] = useState(false)

  const getSubTotal = useCallback(
    (pricingRateId: number) => {
      if (!cart) return []
      const cartItems = cart?.CartItems as any[]

      const pricingRates = cartItems
        ?.flatMap((item) => {
          return item?.ProductItem?.PricingRates?.find(
            (val: any) => val.id == pricingRateId
          )?.price
        })
        .filter((v) => v !== undefined)

      return pricingRates[0] || 0
    },
    [cart]
  )

  const totalPrice = useMemo(() => {
    if (!cart) return undefined

    const cartItems = cart?.CartItems as any[]
    const pricingRates = cartItems?.flatMap((item) => {
      return (
        item?.ProductItem?.PricingRates?.find(
          (val: any) => val.id == item.pricingRateId
        )?.price || 0
      )
    })

    if (pricingRates && pricingRates.length > 0) {
      const price = pricingRates.reduce((acc, curr) => acc + curr)
      return price
    }

    return 0
  }, [cart, unavailableIds])

  const isExpired = useMemo(() => {
    const currentCart = cart as {
      deletedAt?: Date | string
    } & typeof cart

    const expiredTime = timeRemaining.minutes < 0 || timeRemaining.seconds < 0
    const expiredDeletedAt = !!currentCart?.deletedAt

    return expiredTime || expiredDeletedAt
  }, [cart, timeRemaining])

  useFocusEffect(
    useCallback(() => {
      dispatch(getCurrentCart())
    }, [user?.LatestCart?.[0]?.id])
  )

  // If cart expired, cart will be recreated
  const fetchRecreateCart = useCallback(async () => {
    const response = await dispatch(recreateCart())

    if (recreateCart.fulfilled.match(response)) {
      const failedProductItems = response.payload?.body.failed_product_items

      if (failedProductItems && failedProductItems.length > 0) {
        setUnavailableIds(failedProductItems.map((item) => item.id))
        setActionSheetVisible(true)

        if (cart?.CartItems && cart?.CartItems.length <= 1) {
          setAllNotAvailable(true)
        }
      }
    }
  }, [cart])

  useEffect(() => {
    if (cart) {
      const at = String(cart.expiresAt)
      const expired = DateTime.fromISO(at)

      function updateTimeRemaining() {
        const now = DateTime.local()
        const diff = expired.diff(now, ['minutes', 'seconds'])

        setTimeRemaining({
          minutes: diff.minutes,
          seconds: diff.seconds,
        })
      }

      const counter = setInterval(() => {
        updateTimeRemaining()
      }, 1000)

      updateTimeRemaining()

      return () => clearInterval(counter)
    }
  }, [cart])

  useFocusEffect(
    useCallback(() => {
      if (!authenticated) {
        navigation.navigate('Root')
      }
    }, [authenticated])
  )

  const handleDeleteCartItem = async (carItemId: number) => {
    const res = await dispatch(
      removeCartItem({
        cartId: cart?.id!,
        carItemId,
      })
    )

    if (removeCartItem.fulfilled.match(res)) {
      dispatch(getCurrentCart())
    }
  }

  const handleCheckout = async () => {
    if (cart?.id) {
      setTermsConditionVisible(true)
      // navigation.navigate('Checkout', {})
    }
  }

  const unavailableCartItems = useMemo(() => {
    if (unavailableIds.length > 0) {
      if (cart && cart.CartItems && cart.CartItems.length > 0) {
        return cart.CartItems.filter((cartItem) => {
          return unavailableIds.includes(cartItem.ownerId)
        })
      }
    }
    return []
  }, [unavailableIds, cart])

  return (
    <Container>
      <BackHeader
        navigation={navigation}
        fallback="Home"
        text="Keranjang Kamu"
      />
      {cart &&
        cart?.CartItems &&
        cart?.CartItems?.filter((ci) => ci.ownerType === 'productItem').length >
          0 &&
        !isExpired &&
        !allNotAvailable && (
          <AvailableStockInfo
            timeRemaining={timeRemaining}
            visibleState={[modalVisible, setModalVisible]}
          />
        )}

      {cart &&
        cart?.CartItems &&
        cart?.CartItems?.filter((ci) => ci.ownerType === 'productItem').length >
          0 &&
        isExpired &&
        !allNotAvailable && (
          <StockValidityInfo
            visibleState={[modalVisible, setModalVisible]}
            onPress={fetchRecreateCart}
          />
        )}

      {cart &&
        cart?.CartItems &&
        cart?.CartItems?.filter((ci) => ci.ownerType === 'productItem').length >
          0 &&
        unavailableIds.length > 0 &&
        !allNotAvailable && (
          <UnavailableStockInfo
            visibleState={[modalVisible, setModalVisible]}
            onPress={() => {
              setActionSheetVisible(true)
            }}
            count={unavailableIds.length}
          />
        )}

      <ScrollView
        style={tailwind('pt-5 px-4 flex flex-col w-full')}
        showsVerticalScrollIndicator={false}
      >
        {(!cart ||
          !cart?.CartItems?.filter((ci) => ci.ownerType === 'productItem')
            .length ||
          allNotAvailable) && <EmptyCart navigation={navigation} />}

        {cart &&
          cart?.CartItems &&
          cart?.CartItems?.filter((ci) => ci.ownerType === 'productItem')
            .length > 0 &&
          !allNotAvailable && (
            <React.Fragment>
              <View style={tailwind('flex flex-row justify-between mb-3')}>
                <Text style={tailwind('text-lg font-bold')}>
                  Alamat Pengiriman
                </Text>
                {user && user?.PrimaryAddress && (
                  <Pressable
                    onPress={() =>
                      navigation.navigate('Root', {
                        screen: 'Dashboard',
                        params: { screen: 'Address' },
                      })
                    }
                  >
                    <Text style={[tailwind('text-sm'), { color: '#00443E' }]}>
                      Ganti Alamat
                    </Text>
                  </Pressable>
                )}
              </View>

              {user && !user?.PrimaryAddress && (
                <View style={tailwind('mb-4')}>
                  <Text style={tailwind('mb-2')}>
                    Belum ada alamat pengiriman yang tersimpan. Tambah alamat?
                  </Text>
                  <Pressable
                    onPress={() =>
                      navigation.navigate('Root', {
                        screen: 'Dashboard',
                        params: { screen: 'Address' },
                      })
                    }
                    style={[
                      tailwind('p-2 rounded border border-teal-500 w-fit'),
                    ]}
                  >
                    <Text style={tailwind('text-teal-500 font-bold')}>
                      Tambah Alamat
                    </Text>
                  </Pressable>
                </View>
              )}

              {user && user?.PrimaryAddress && (
                <View style={tailwind('mb-4')}>
                  <Text style={tailwind('mb-2 font-bold')}>Alamat Utama</Text>
                  {(user.PrimaryAddress.recipientName ||
                    user.PrimaryAddress.recipientPhone) && (
                    <Text style={tailwind('mb-1')}>
                      {[
                        user.PrimaryAddress.recipientName,
                        user.PrimaryAddress.recipientPhone,
                      ].join(' - ')}
                    </Text>
                  )}
                  <Text>
                    {`${user.PrimaryAddress.address}, ${user.PrimaryAddress.district}, ${user.PrimaryAddress.regency}, ${user.PrimaryAddress.province}`}
                  </Text>
                </View>
              )}

              <View style={tailwind('py-5')}>
                {cart.CartItems &&
                  cart.CartItems.filter(
                    (item) => item.ownerType === 'productItem'
                  ).map((item, i) => {
                    const len =
                      cart?.CartItems && cart?.CartItems?.length > 1
                        ? cart?.CartItems?.length
                        : 0
                    const isFirstIndex = i == 0
                    const isLastIndex = len > 0 ? len - 1 == i : false

                    return (
                      <CartItemRow
                        key={i}
                        cartItem={item}
                        isLastIndex={isLastIndex}
                        isFirstIndex={isFirstIndex}
                        onDelete={handleDeleteCartItem}
                        subTotal={getSubTotal(item.pricingRateId!)}
                        hideDelete={isExpired}
                      />
                    )
                  })}
              </View>
            </React.Fragment>
          )}
      </ScrollView>

      <CartCheckout
        total={totalPrice}
        btnText="Checkout"
        disabled={
          !cart || !cart?.CartItems?.length || isExpired || allNotAvailable
        }
        onPress={() => handleCheckout()}
      />

      <CustomActionSheet
        title="Produk terhapus dari keranjang"
        open={actionSheetVisible}
        setVisible={() => {
          setActionSheetVisible(false)
          setUnavailableIds([])
          dispatch(getCurrentCart())
        }}
      >
        <Text>
          Produk berikut harus dihapus dari keranjang karena tidak tersedia pada
          tanggal yang kamu pilih. Silahkan tambahkan kembali.
        </Text>

        {unavailableCartItems.length > 0 && (
          <View style={tailwind('py-5')}>
            {unavailableCartItems.map((item, i) => {
              const len = unavailableCartItems.length
              const isFirstIndex = i == 0
              const isLastIndex = len > 0 ? len - 1 == i : false

              return (
                <CartItemRow
                  key={i}
                  cartItem={item}
                  isLastIndex={isLastIndex}
                  isFirstIndex={isFirstIndex}
                  onDelete={handleDeleteCartItem}
                  subTotal={getSubTotal(item.pricingRateId!)}
                  hideDelete
                />
              )
            })}
          </View>
        )}
      </CustomActionSheet>

      {termsConditionVisible && (
        <TermsConditionModal
          navigation={navigation}
          onClose={() => setTermsConditionVisible(false)}
          onHandleNavigate={() => {
            setTermsConditionVisible(false)
            cart?.id && navigation.navigate('Checkout', {})
          }}
        />
      )}
    </Container>
  )
}

type TermsConditionModalProps = {
  navigation: any
  onClose: Function
  onHandleNavigate: Function
}

const TermsConditionModal: React.FC<TermsConditionModalProps> = ({
  navigation,
  onClose,
  onHandleNavigate,
}) => {
  const tailwind = useTailwind()

  return (
    <Modal
      visible
      transparent={true}
      animationType="none"
      onRequestClose={() => onClose()}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <View
            style={tailwind(
              'w-full flex flex-row items-center justify-between'
            )}
          >
            <Text style={styles.modalText}>Syarat & Ketentuan</Text>
            <Pressable style={[styles.button]} onPress={() => onClose()}>
              <CloseIcon />
            </Pressable>
          </View>

          <Text style={tailwind('text-gray-500')}>
            Dengan Ini saya membaca, Memahami & menyetujui hal-hal yang
            tercantum pada
            <Pressable
              onPress={() => {
                onClose()

                navigation.navigate('TermsCondition', {
                  navigationTo: 'Checkout',
                  showNext: true,
                })
              }}
            >
              <Text style={tailwind('text-blue-400')}>
                Syarat dan ketentuan
              </Text>
            </Pressable>
          </Text>

          <View style={tailwind('w-full mt-2')}>
            <Button
              color="primary"
              style={[
                tailwind(
                  'rounded flex flex-row justify-center items-center text-center'
                ),
              ]}
              onPress={() => onHandleNavigate()}
              text="Lanjutkan"
            />
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
    fontSize: 18,
  },
  button: {
    padding: 0,
    elevation: 2,
    marginTop: '-10px',
    border: 0,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 25,
    alignItems: 'flex-start',
    shadowColor: 'transparent',
    maxWidth: 'calc(400px - 50px)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    top: '50%',
    transform: [
      {
        translateY: -90,
      },
      { perspective: 100 },
    ],
  },
})
