import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  Action,
  combineReducers,
  configureStore,
  createAction,
} from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'

import assetAvailabilityReducer from '~/store/asset_availability'
import authReducer from '~/store/auth'
import cartReducer from '~/store/cart'
import collectionReducer from '~/store/collection'
import orderReducer from '~/store/order'
import orderHistoryReducer from '~/store/order_history'
import productReducer from '~/store/product'
import productItemReducer from '~/store/productItem'
import productTypeReducer from '~/store/productType'
import provinceReducer from '~/store/province'
import resetPasswordReducer from '~/store/resetPassword'
import searchReducer from '~/store/search'
import transactionReducer from '~/store/transaction'
import userReducer from '~/store/user'
import waitlistReducer from '~/store/waitlist'

export const RESET_REDUX = createAction<void>('RESET_REDUX')
const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
}

const appReducer = combineReducers({
  assetAvailability: assetAvailabilityReducer,
  auth: authReducer,
  cart: cartReducer,
  collection: collectionReducer,
  order: orderReducer,
  product: productReducer,
  productType: productTypeReducer,
  province: provinceReducer,
  search: searchReducer,
  user: userReducer,
  waitlist: waitlistReducer,
  transaction: transactionReducer,
  orderHistory: orderHistoryReducer,
  resetPassword: resetPasswordReducer,
  productItem: productItemReducer,
})
const rootReducer = (state: any, action: Action) => {
  if (RESET_REDUX.match(action)) {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})
const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default () => {
  return { store, persistor }
}
