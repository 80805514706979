import {
  ActionTypes,
  ProductSelectedAction,
  ProductSelectedState,
} from '~/components/product/types'

export const initialState: ProductSelectedState = {
  variantSelected: new Map(),
  selectedProductId: NaN,
  selectedProductItemId: NaN,
  selectedDuration: NaN,
  selectedDate: null,
}

const productSelectedReducer = <T,>(
  state: ProductSelectedState,
  action: ProductSelectedAction
): Readonly<ProductSelectedState> => {
  const { type } = action

  switch (type) {
    case ActionTypes.setVariantSelected:
      return {
        ...state,
        variantSelected: action.value,
      }
    case ActionTypes.setSelectedProductId:
      return {
        ...state,
        selectedProductId: action.value,
      }
    case ActionTypes.setSelectedProductItemId:
      return {
        ...state,
        selectedProductItemId: action.value,
      }
    case ActionTypes.setSelectedDuration:
      return {
        ...state,
        selectedDuration: action.value,
      }
    case ActionTypes.setSelectedDate:
      return {
        ...state,
        selectedDate: action.value,
      }
    case ActionTypes.reset:
      return initialState
    default:
      return state
  }
}

export default productSelectedReducer
