import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const BellLight = (props: any) => (
  <Svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M16 22C16 23.0952 15.119 24 14 24C12.881 24 12 23.119 12 22"
      stroke="#B2E2DF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M7.25 17.1781C7.25 17.1781 5 17.1781 5 19.4137C5 22.001 12.8624 21.9759 14 21.9759C14 21.9759 23 22.4281 23 19.4137C23 17.1781 20.75 17.1781 20.75 17.1781C20.75 17.1781 7.32584 16.952 7.27528 14.3899C7.22472 12.0286 7.27528 10.4712 9.52528 8.23562C11.7753 6 14.0253 6 14.0253 6C14.0253 6 16.2753 6 18.5253 8.23562C20.7753 10.4712 20.7753 14.9425 20.7753 14.9425"
      stroke="#B2E2DF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default BellLight
