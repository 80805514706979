import { BottomTabParamList } from './types'
import React from 'react'
import { Pressable, View } from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import Text from '~/components/StyledText'

import Grid from '~/svg/Grid'
import House from '~/svg/House'
import Order from '~/svg/Order'

export default function CustomTabBar({ state, descriptors, navigation }: any) {
  const tailwind = useTailwind()

  return (
    <View style={[tailwind('w-full mx-auto'), { maxWidth: 400 }]}>
      <View
        style={[
          {
            height: 80,
            backgroundColor: '#fff',
            shadowColor: 'rgba(0,0,0,0.25)',
            shadowRadius: 8,
            elevation: 5,
          },
          tailwind('flex flex-row items-center justify-center'),
        ]}
      >
        {state.routes.map((route: any, index: number) => {
          const { options } = descriptors[route.key]

          const renderTabIcon = (isFocused: boolean) => {
            const defaultProps = {
              color: isFocused ? '#00443E' : '#333',
            }
            switch (route.name as keyof BottomTabParamList) {
              case 'Dashboard':
                return <House {...defaultProps} />
              case 'Catalogue':
                return <Grid {...defaultProps} />
              case 'Order':
                return <Order {...defaultProps} />
              default:
                return <React.Fragment />
            }
          }

          const label =
            options.tabBarLabel !== undefined
              ? options.tabBarLabel
              : options.title !== undefined
              ? options.title
              : route.name

          const isFocused = state.index === index

          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
            })

            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate(route.name)
            }
          }

          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key,
            })
          }

          return (
            <Pressable
              key={index}
              accessibilityRole="button"
              accessibilityState={{
                selected: isFocused,
              }}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              onLongPress={onLongPress}
              style={[
                {
                  flex: 1,
                  alignItems: 'center',
                  backgroundColor: isFocused ? '#B2E2DF' : '#fff',
                  maxWidth: 108,
                },
                tailwind('p-2 rounded-full'),
              ]}
            >
              <Text
                style={[
                  { color: isFocused ? '#00443E' : '#333' },
                  tailwind('flex flex-col items-center text-xs'),
                ]}
              >
                {renderTabIcon(isFocused)}
                {label}
              </Text>
            </Pressable>
          )
        })}
      </View>
    </View>
  )
}
