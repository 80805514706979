import { useEffect, useState } from 'react'
import { Pressable, View } from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import Text from '~/components/StyledText'

import { excerptStr } from '~/lib/string'

const MAX_LEN = 350

interface ProductDescriptionProps {
  description: string
}

export default function ProductDescription({
  description: desc,
}: ProductDescriptionProps) {
  const tailwind = useTailwind()

  const [isOpened, setOpen] = useState(false)
  const [descState, setDesc] = useState(desc)

  useEffect(() => {
    if (isOpened) {
      setDesc(desc)
    } else {
      setDesc(excerptStr(desc, MAX_LEN))
    }
  }, [isOpened, desc])

  return (
    <View>
      <Text style={tailwind('text-sm')}>{descState}</Text>

      {/* :: Only show, when text length == MAX_LEN */}
      {desc.length >= MAX_LEN && (
        <Pressable
          onPress={() => {
            setOpen((open) => !open)
          }}
          style={tailwind('mt-2')}
        >
          <Text style={[tailwind('text-sm'), { color: '#00461A' }]}>
            Baca {isOpened ? 'Lebih Sedikit' : 'Selengkapnya'}
          </Text>
        </Pressable>
      )}
    </View>
  )
}
