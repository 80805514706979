import React, { useEffect, useState } from 'react'
import { Pressable, StyleProp, ViewStyle } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import Text from '~/components/StyledText'

import {
  getCurrentProductAvailability,
  getNextProductAvailability,
} from '~/apis/product_availabilities'
import { addDays, formatDate } from '~/lib/datetime'

const DAYS_BUFFER = 5

type ReadyToRentBadgeProps = {
  productId: number
  style?: StyleProp<ViewStyle>
}

const ReadyToRentBadge: React.FC<ReadyToRentBadgeProps> = ({
  productId,
  style,
}) => {
  const tailwind = useTailwind()

  const [availableState, setAvailableState] = useState({
    currentAvailable: false,
    loading: true,
    nextAvailableDate: '',
  })

  const isReady = availableState.currentAvailable

  let btnText = ''
  if (isReady) {
    btnText = 'Siap disewa'
  } else if (!isReady && !availableState.nextAvailableDate) {
    btnText = 'Cek Ketersediaan'
  } else if (!isReady && availableState.nextAvailableDate) {
    btnText = `Tersedia ${formatDate(availableState.nextAvailableDate)}`
  }

  useEffect(() => {
    const fetchAvailability = async () => {
      const currentAvailabilityRes = await getCurrentProductAvailability(
        productId,
        {
          durationDays: 30,
        }
      )

      setAvailableState((state) => ({
        ...state,
        loading: true,
      }))

      if (
        !currentAvailabilityRes ||
        !currentAvailabilityRes[0] ||
        !currentAvailabilityRes[0]?.data
      ) {
        return null
      }

      const { availability } = currentAvailabilityRes[0].data

      let nextAvailableDate = ''

      if (!availability) {
        const nextAvailabilityRes = await getNextProductAvailability(productId)

        if (nextAvailabilityRes[0]?.data) {
          const { availability: nextAvailability } =
            nextAvailabilityRes[0]?.data

          if (nextAvailability) {
            nextAvailableDate = addDays(
              nextAvailability?.nextBooking as Date,
              DAYS_BUFFER
            )!.toISOString()
          }
        }
      }

      setAvailableState((state) => ({
        loading: false,
        currentAvailable: availability,
        nextAvailableDate,
      }))
    }

    if (productId) fetchAvailability()
  }, [productId])

  if (availableState.loading)
    return (
      <Pressable
        style={[
          tailwind(
            'mt-2 rounded flex flex-row justify-center items-center text-center'
          ),
          {
            backgroundColor: 'rgb(185, 185, 185)',
            width: '90%',
            height: 20,
          },
          style,
        ]}
      >
        <Text
          style={[
            tailwind('text-[10px]'),
            {
              color: 'rgb(58, 58, 58)',
            },
          ]}
        >
          Pengecekan Booking...
        </Text>
      </Pressable>
    )

  return (
    <Pressable
      style={[
        tailwind(
          'mt-2 rounded flex flex-row justify-center items-center text-center'
        ),
        {
          backgroundColor: availableState.currentAvailable
            ? '#B2E2DF'
            : 'rgb(185, 185, 185)',
          width: availableState.currentAvailable ? '60%' : '90%',
          height: 20,
        },
        style,
      ]}
    >
      <Text
        style={[
          tailwind('text-[10px]'),
          {
            color: '#00443E',
          },
        ]}
      >
        {btnText}
      </Text>
    </Pressable>
  )
}

export default ReadyToRentBadge
