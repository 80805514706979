import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const InstagramWhiteIconXs = (props: any) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M8.00008 5.91711C6.8532 5.91711 5.91726 6.85305 5.91726 7.99993C5.91726 9.1468 6.8532 10.0827 8.00008 10.0827C9.14695 10.0827 10.0829 9.1468 10.0829 7.99993C10.0829 6.85305 9.14695 5.91711 8.00008 5.91711ZM14.247 7.99993C14.247 7.13743 14.2548 6.28274 14.2063 5.4218C14.1579 4.4218 13.9298 3.5343 13.1985 2.80305C12.4657 2.07024 11.5798 1.84368 10.5798 1.79524C9.71727 1.7468 8.86258 1.75462 8.00164 1.75462C7.13914 1.75462 6.28445 1.7468 5.42351 1.79524C4.42351 1.84368 3.53601 2.0718 2.80476 2.80305C2.07195 3.53586 1.84539 4.4218 1.79695 5.4218C1.74851 6.2843 1.75632 7.13899 1.75632 7.99993C1.75632 8.86086 1.74851 9.71711 1.79695 10.578C1.84539 11.578 2.07351 12.4655 2.80476 13.1968C3.53758 13.9296 4.42351 14.1562 5.42351 14.2046C6.28601 14.253 7.1407 14.2452 8.00164 14.2452C8.86414 14.2452 9.71883 14.253 10.5798 14.2046C11.5798 14.1562 12.4673 13.928 13.1985 13.1968C13.9313 12.464 14.1579 11.578 14.2063 10.578C14.2563 9.71711 14.247 8.86242 14.247 7.99993ZM8.00008 11.2046C6.22664 11.2046 4.79539 9.77336 4.79539 7.99993C4.79539 6.22649 6.22664 4.79524 8.00008 4.79524C9.77352 4.79524 11.2048 6.22649 11.2048 7.99993C11.2048 9.77336 9.77352 11.2046 8.00008 11.2046ZM11.336 5.41243C10.922 5.41243 10.5876 5.07805 10.5876 4.66399C10.5876 4.24993 10.922 3.91555 11.336 3.91555C11.7501 3.91555 12.0845 4.24993 12.0845 4.66399C12.0846 4.76231 12.0653 4.85969 12.0277 4.95055C11.9902 5.04141 11.935 5.12397 11.8655 5.19349C11.796 5.26301 11.7134 5.31814 11.6226 5.35571C11.5317 5.39328 11.4343 5.41255 11.336 5.41243Z"
      fill="white"
    />
  </Svg>
)

export default InstagramWhiteIconXs
