import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CartAttributesFull } from '@withbioma/apitype-publicapi-customer/lib/v1/common/cart_full'
import { DateTime } from 'luxon'

import {
  constructErrorPayload,
  constructExtraReducer,
  constructSuccessPayload,
} from '~/store/helper'
import { get as getUser } from '~/store/user'

import {
  create as createCall,
  get as getCall,
  getDeleted as getDeletedCall,
  recreate as recreateCall,
  update as updateCall,
} from '~/apis/cart'
import {
  create as createCartItemCall,
  remove as removeCartItemCall,
} from '~/apis/cartItems'
import { ISchema } from '~/interfaces/Cart'
import { toDate } from '~/lib/datetime'

interface IState {
  cart?: CartAttributesFull
  loading: object
  responses: object
}
const initialState: IState = {
  cart: undefined,
  loading: {},
  responses: {},
}

export const getCurrentCart = createAsyncThunk(
  'cart/getCurrentCart',
  async (_, { rejectWithValue, getState, dispatch }) => {
    const state: any = getState()

    const { LatestCart } = state.user.user
    //Note: Double check error handling
    if (LatestCart.length > 0) {
      const cart = LatestCart[0]
      const [res, resErr] = !cart.deletedAt
        ? await getCall(cart.id)
        : await getDeletedCall(cart.id)
      if (resErr) {
        return rejectWithValue(constructErrorPayload(resErr))
      }

      return res && constructSuccessPayload(res)
    }
  }
)

export const create = createAsyncThunk(
  'cart/create',
  async (payload: ISchema, { rejectWithValue, getState, dispatch }) => {
    const state = getState()

    // return constructSuccessPayload({});
    const [res, resErr] = await createCall({
      ...payload,
      cartItems: payload.cartItems?.map((cartItem) => ({
        ...cartItem,
        startsAt: toDate(cartItem.startsAt),
      })),
    })
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }

    return res && constructSuccessPayload(res)
  }
)

export const update = createAsyncThunk(
  'cart/update',
  async (payload: ISchema, { rejectWithValue, dispatch }) => {
    const [res, resErr] = await updateCall(payload)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }

    return res && constructSuccessPayload(res)
  }
)

export const addCartItem = createAsyncThunk(
  'cart/addCartItem',
  async (payload: ISchema, { rejectWithValue, getState, dispatch }) => {
    const state: any = getState()

    const { LatestCart } = state.user.user
    //Note: Double check error handling
    if (LatestCart.length) {
      const cart = LatestCart[0]
      const expiry = DateTime.fromISO(cart.expiresAt)

      //Note: Double check error handling
      if (expiry.toMillis() > DateTime.local().toMillis()) {
        const cartItemPayload = payload!.cartItems?.[0]
        if (cartItemPayload) {
          //Note: Double check error handling
          const [createCartItemRes, createCartItemErr] =
            await createCartItemCall(
              {
                ...cartItemPayload,
                startsAt: new Date(cartItemPayload.startsAt),
              },
              cart.id
            )

          console.log('createCartItemRes', createCartItemRes)

          if (!createCartItemRes) {
            return rejectWithValue(constructErrorPayload(createCartItemErr))
          }

          // Note: Double check error handling
          const [res, resErr] = await getCall(cart.id)
          return res && constructSuccessPayload(res)
        }
      }
    }

    const [res, resErr] = await createCall({
      ...payload,
      cartItems: payload.cartItems?.map((cartItem) => ({
        ...cartItem,
        startsAt: new Date(cartItem.startsAt),
      })),
    })
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }

    // Update LatestCart call
    await dispatch(getUser())

    return res && constructSuccessPayload(res)
  }
)

export const removeCartItem = createAsyncThunk(
  'cart/removeCartItem',
  async (
    payload: {
      cartId: number
      carItemId: number
    },
    { rejectWithValue, getState, dispatch }
  ) => {
    const [res, resErr] = await removeCartItemCall(
      payload.cartId,
      payload.carItemId
    )
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }

    // Update LatestCart call
    await dispatch(getUser())

    // Refresh Cart
    const [resCart, resErrCart] = await getCall(payload.cartId)
    return res && constructSuccessPayload(res)
  }
)

export const recreateCart = createAsyncThunk(
  'cart/recreateCart',
  async (_, { rejectWithValue, getState }) => {
    const state: any = getState()

    const { LatestCart } = state.user.user

    // Note: Double check error handling
    if (LatestCart.length) {
      const cart = LatestCart[0]

      const [res, resErr] = await recreateCall(cart.id)
      if (resErr) {
        return rejectWithValue(constructErrorPayload(resErr))
      }

      return res && constructSuccessPayload(res)
    }
  }
)

export const reducerSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    resetResponse(state: any, action: PayloadAction<any>) {
      const apiName = action.payload
      state.responses = {
        ...state.responses,
        [apiName]: null,
      }
    },
  },
  extraReducers: (builder) => {
    constructExtraReducer(
      builder,
      getCurrentCart,
      (state: IState, action: PayloadAction<any>) => {
        if (action.payload && action.payload.body) {
          state.cart = action.payload.body.cart
        } else {
          state.cart = undefined
        }
      }
    )
    constructExtraReducer(
      builder,
      create,
      (state: IState, action: PayloadAction<any>) => {}
    )
    constructExtraReducer(builder, update, (state: IState) => {})
    constructExtraReducer(
      builder,
      addCartItem,
      (state: IState, action: PayloadAction<any>) => {
        if (action.payload) {
          state.cart = action.payload.body.cart
        } else {
          state.cart = undefined
        }
      }
    )
    constructExtraReducer(
      builder,
      removeCartItem,
      (state: IState, action: PayloadAction<any>) => {
        if (action.payload) {
          state.cart = action.payload.body.cart
        } else {
          state.cart = undefined
        }
      }
    )
    constructExtraReducer(
      builder,
      recreateCart,
      (state: IState, action: PayloadAction<any>) => {
        if (action.payload) {
          state.cart = action.payload.body.cart
        } else {
          state.cart = undefined
        }
      }
    )
  },
})

export const { resetResponse } = reducerSlice.actions

export default reducerSlice.reducer
