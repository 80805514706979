import { Image, Pressable, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { useTailwind } from 'tailwind-rn/dist'

import Container from '~/components/Container'
import Text from '~/components/StyledText'

import { RootStackProps } from '~/navigation/types'

const RegisterSuccess = ({
  route,
  navigation,
}: RootStackProps<'RegisterSuccess'>) => {
  const tailwind = useTailwind()
  const params = route.params

  return (
    <Container>
      <ScrollView
        style={[
          tailwind('pt-2 flex flex-col w-full min-h-screen relative'),
          {
            backgroundColor: '#D7DDAE',
            height: '100%',
          },
        ]}
      >
        <View style={tailwind('pt-6 px-4 h-full relative')}>
          <Image
            source={require('~/assets/images/bioma-logo.png')}
            style={[
              {
                width: 72,
                height: 20,
                resizeMode: 'contain',
              },
              tailwind('mb-4 p-2'),
            ]}
          />

          <Text
            style={[
              tailwind('text-2xl mb-5 font-bold'),
              {
                color: '#00433E',
              },
            ]}
          >
            Pendaftaran Sukses
          </Text>

          <View
            style={{
              height: 'calc(100vh - 255px)',
            }}
          >
            <Text style={tailwind('text-md mb-5')}>
              Selamat, pendaftaran akun Bioma telah sukses. Jangan lupa lakukan
              konfirmasi email dengan mengunjungi link yang diberikan pada email
              yang terdaftar
            </Text>
          </View>

          <View style={[tailwind('mt-12 sticky bottom-0 w-full')]}>
            <Pressable
              style={[
                tailwind('rounded p-4 text-center'),
                {
                  backgroundColor: '#B2E2DF',
                  height: 55,
                },
              ]}
              onPress={() => {
                navigation.navigate('Login')
                // if (params?.redirect_from) {
                //   navigation.navigate(params.redirect_from, {
                //     [params?.param_name]: params?.param_value,
                //   })
                // } else {
                //   navigation.navigate('Login')
                // }
              }}
            >
              <Text
                style={[
                  tailwind('text-sm'),
                  {
                    color: '#00443E',
                  },
                ]}
              >
                Login ke Akun
              </Text>
            </Pressable>
          </View>

          {/* :: Background Image*/}
          <View
            style={[
              tailwind('absolute'),
              {
                width: '100%',
                bottom: '-6%',
                left: 0,
                right: 0,
                zIndex: -2,
              },
            ]}
          >
            <Image
              source={require('~/assets/images/register/pattern.png')}
              style={[
                {
                  aspectRatio: 1,
                  resizeMode: 'cover',
                },
              ]}
            />
          </View>

          <View
            style={[
              tailwind('absolute'),
              {
                bottom: 11,
                left: '50%',
                width: '100%',
                transform: 'translateX(-50%)',
                zIndex: -1,
              },
            ]}
          >
            <Image
              source={require('~/assets/images/register/dab.png')}
              style={[
                tailwind('rounded-lg'),
                {
                  aspectRatio: 1,
                  height: 730,
                  resizeMode: 'cover',
                },
              ]}
            />
          </View>
        </View>
      </ScrollView>
    </Container>
  )
}

export default RegisterSuccess
