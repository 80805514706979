import type { InitiateResetPasswordRequest } from '@withbioma/apitype-publicapi-public/lib/v1/api'
import type { ResetPasswordRequest } from '@withbioma/apitype-publicapi-public/lib/v1/api'

import API from '~/apis/client'

export async function initiateResetPassword(
  payload: InitiateResetPasswordRequest
) {
  return await API.handle(
    API.public.post(`/public/v1/initiate_reset_password`, payload)
  )
}

export async function resetPassword(payload: ResetPasswordRequest) {
  return await API.handle(API.public.post(`/public/v1/reset_password`, payload))
}
