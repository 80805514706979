import { CustomOrderAttributesSchema } from '@withbioma/apitype-publicapi-public/lib/v1/orders/api'
import { View } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { z } from 'zod'

import Text from '~/components/StyledText'

import { toCurrency } from '~/lib/money'

const CustomPublicOrderItem =
  CustomOrderAttributesSchema.shape.OrderItems.unwrap().unwrap().element

interface IProps {
  orderItem: z.infer<typeof CustomPublicOrderItem>
}

const AddOnOrderItemRow: React.FC<IProps> = ({ orderItem }) => {
  const tailwind = useTailwind()

  if (!orderItem.AddOn) {
    return <></>
  }

  return (
    <View style={tailwind('flex flex-row justify-between mt-2')}>
      <Text style={tailwind('text-sm')}>{orderItem.AddOn?.name}</Text>
      <Text style={tailwind('text-sm')}>
        {toCurrency(orderItem.AddOn?.price)}
      </Text>
    </View>
  )
}
export default AddOnOrderItemRow
