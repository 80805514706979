import { compose } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'

import { inRange } from '~/utils/common'
import { isDate, isValidDate } from '~/utils/validation'

export const DATE_CONFIG = {
  DATE_FORMAT: 'yyyy-MM-dd', // 2022-11-11
  DATE_FORMAT_INPUT: 'dd MMM yyyy', // 11 Nov 2022
  DATE_FORMAT_ISO: 'yyyy LLL dd', // 2022 Nov 11
  SIMPLE_DATE_STRING_FORMAT: 'yyyy-M-d', // 2022-11-1
  DATE_TIME_FORMAT: 'y-MM-dd h:mm:ss a', // 2022-11-11 6:12:03 AM
  ZONE_NAME: 'Asia/Jakarta', // GMT+7
}

export type NullableDate = Date | string | number | null | undefined

export const now = (): DateTime => {
  return DateTime.now().setZone(DATE_CONFIG.ZONE_NAME)
}

export const timestamp = () => {
  return now().toJSDate()
}

export const dateWithoutTime = () => {
  return DateTime.fromFormat(
    now().toFormat(DATE_CONFIG.SIMPLE_DATE_STRING_FORMAT),
    DATE_CONFIG.SIMPLE_DATE_STRING_FORMAT
  ).toJSDate()
}

export const startOf = (d: NullableDate) =>
  toDateTime(d)?.startOf('day').toJSDate()

export const endOf = (d: NullableDate) => toDateTime(d)?.endOf('day').toJSDate()

export const ageFormat = function (patientDOB: string): number {
  return Math.floor(
    Math.abs(DateTime.fromISO(patientDOB).diffNow('years').years)
  )
}

export const diffFormat = function (startsAt: string): number {
  return Math.max(
    Math.floor(-1 * DateTime.fromISO(startsAt).diffNow('days').days),
    0
  )
}

export const normalizeDate = (d: Date): DateTime => {
  return DateTime.fromFormat(
    `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`,
    DATE_CONFIG.SIMPLE_DATE_STRING_FORMAT
  ).setZone(DATE_CONFIG.ZONE_NAME)
}

export const toDateTime = (
  date: NullableDate,
  zone = DATE_CONFIG.ZONE_NAME
): DateTime | undefined => {
  let result: DateTime | undefined = undefined

  if (date && isDate(date)) {
    result = DateTime.fromJSDate(date)
  } else if (typeof date === 'number') {
    result = DateTime.fromMillis(date)
  } else if (date) {
    result = DateTime.fromISO(date)
  }

  if (result && isValidDate(result.toJSDate())) {
    return result.setZone(zone)
  }
}

export const toDate = (date: NullableDate, zone = DATE_CONFIG.ZONE_NAME) => {
  return toDateTime(date, zone)?.toJSDate()
}

export const formatDate = (
  date: NullableDate,
  dateFormat = DATE_CONFIG.DATE_FORMAT_INPUT
) => {
  const d = toDateTime(date)?.toISO()

  return (d && formatISODate(d, dateFormat)) || ''
}

export const formatISODate = (
  date: string,
  format = DATE_CONFIG.DATE_FORMAT_ISO
) => {
  return toDateTime(date)?.toFormat(format)
}

type DiffDayParam = {
  start: NullableDate
  end: NullableDate
}

export const diffDays = (start: NullableDate, end: NullableDate) => {
  const dateTimeStart = toDateTime(start)
  const dateTimeEnd = toDateTime(end)

  if (
    dateTimeStart &&
    dateTimeEnd &&
    (!dateTimeStart.isValid || !dateTimeEnd.isValid)
  ) {
    console.error(`Invalid parse date`)

    return NaN
  }

  const days =
    (dateTimeStart &&
      dateTimeEnd &&
      Math.round(dateTimeEnd!.diff(dateTimeStart!, 'days').days)) ||
    0

  return Number(days.toPrecision(7))
}

export const diffDaysObject = (
  original: DiffDayParam,
  candidate: DiffDayParam
): number => {
  const [origDuration, candDuration] = [
    diffDays(startOf(original.start), startOf(original.end)),
    diffDays(startOf(candidate.start), startOf(candidate.end)),
  ]

  // Compare date with prev duration
  return Math.round(candDuration - origDuration)
}

export const calcDiff = diffDaysObject

export const inRangeDate = (
  params: {
    candidate: NullableDate
    start: NullableDate
    end: NullableDate
  },
  config?: {
    truncate?: boolean
  }
) => {
  const truncate = config?.truncate

  const ƒ = (d: NullableDate) => toDate(d)?.getTime()

  const candidateTime = truncate
      ? ƒ(startOf(params.candidate))
      : ƒ(params.candidate),
    startTime = truncate ? ƒ(startOf(params.start)) : ƒ(params.start),
    endTime = truncate ? ƒ(endOf(params.end)) : ƒ(params.end)

  if (candidateTime && startTime && endTime) {
    return inRange(candidateTime, startTime, endTime)
  }

  return false
}

export function addDays(d: NullableDate, days: number) {
  const date = toDateTime(d)

  return date && date.plus({ days }).toJSDate()
}
