import {
  GestureResponderEvent,
  ImageURISource,
  Pressable,
  View,
} from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import Text from '~/components/StyledText'
import Image from '~/components/base/Image'

type Props = {
  title: string
  description: string
  source: ImageURISource
  btnText: string
  onPress: (event: GestureResponderEvent) => void
}

const EmptyStates: React.FC<Props> = ({
  title,
  description,
  source,
  onPress,
  btnText,
}) => {
  const tailwind = useTailwind()

  return (
    <View
      style={[
        tailwind('flex flex-col items-center justify-center'),
        { height: 'calc(100vh - 280px)' },
      ]}
    >
      <Image
        source={source}
        width={112}
        height={112}
        transparent
        imageStyles={[
          {
            aspectRatio: 1,
          },
        ]}
      />

      <View style={tailwind('pt-5 pb-10 text-center')}>
        <Text style={tailwind('text-lg')}>{title}</Text>
        <Text style={tailwind('text-gray-400 text-sm')}>{description}</Text>
      </View>

      <Pressable
        style={tailwind('bg-primary p-2 w-5/12 rounded')}
        onPress={onPress}
      >
        <Text
          style={[
            tailwind('text-center text-sm'),
            {
              color: '#00443E',
            },
          ]}
        >
          {btnText}
        </Text>
      </Pressable>
    </View>
  )
}

export default EmptyStates
