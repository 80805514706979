import { useFocusEffect } from '@react-navigation/native'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { Linking, Pressable, ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import { RootState, useAppDispatch, useAppSelector } from '~/store/store'
import { get as getTransaction } from '~/store/transaction'

import Container from '~/components/Container'
import Text from '~/components/StyledText'
import Image from '~/components/base/Image'
import BackHeader from '~/components/headers/BackHeader'
import { parseOrderStatus } from '~/components/order_histories/utils'

import { calcDiff, diffDays, formatDate } from '~/lib/datetime'
import { toCurrency } from '~/lib/money'
import { RootStackProps } from '~/navigation/types'
import Calendar from '~/svg/Calendar'
import { transformCoupon } from '~/utils/Coupon'

function TransactionDetail({
  route,
  navigation,
}: RootStackProps<'TransactionDetail'>) {
  const tailwind = useTailwind()
  const params = route.params

  const dispatch = useAppDispatch()
  const { authenticated } = useAppSelector((state: RootState) => state.auth)
  const { transaction } = useAppSelector(
    (state: RootState) => state.transaction
  )

  const [transactionState, setTransaction] = useState<any>()
  const orderStatus =
    transactionState && parseOrderStatus(transactionState?.Order?.status)
  const transactionOrderItems = transactionState?.Order?.OrderItems

  console.log('transactionOrderItems', transactionOrderItems)

  const subTotal =
    transactionOrderItems
      ?.filter((orderItem: any) => orderItem.ProductItem)
      ?.reduce((prev: any, cur: any) => prev + (cur.price - cur.discount), 0) ||
    0

  const hasCreditNote =
    (transaction as any)?.creditNoteId || (transaction as any)?.CreditNote

  useEffect(() => {
    if (params?.transaction_uuid) {
      dispatch(getTransaction(params.transaction_uuid))
    } else {
      navigation.navigate('Root', { screen: 'Dashboard' })
    }
  }, [params])

  useEffect(() => {
    let data = transaction as any

    if (data && hasCreditNote) {
      const creditNotes = new Map(
        data.CreditNote?.CreditNoteLineItems.map((val: any) => [
          val.orderItemId,
          val,
        ])
      )

      // To find difference with original duration
      const orderItemRevisions = new Map(
        data.CreditNote?.OriginOrderRevision?.OrderItemRevisions.map(
          (val: any) => [val.orderItemId, val]
        )
      )

      const mapOrderCreditNoteLineItems = data.Order?.OrderItems?.map(
        (orderItem: any) => {
          const creditNoteItem: any = creditNotes.get(orderItem.id)
          const orderItemRevision: any = orderItemRevisions.get(orderItem.id)

          const extensionDuration = calcDiff(
            {
              start: orderItemRevision?.startsAt,
              end: orderItemRevision?.endsAt,
            },
            {
              start: creditNoteItem?.startsAt,
              end: creditNoteItem?.endsAt,
            }
          )

          if (creditNoteItem) {
            return {
              ...orderItem,
              price: creditNoteItem?.subtotal,
              ...(orderItem.ownerType === 'productItem' && {
                startsAt: creditNoteItem?.startsAt,
                endsAt: creditNoteItem?.endsAt,
                ProductItem: {
                  ...orderItem.ProductItem,
                  Product: {
                    ...orderItem.ProductItem?.Product,
                    name: creditNoteItem?.name,
                  },
                },
              }),
              ...(orderItem.ownerType === 'addOn' && {
                name: `${creditNoteItem?.name} bem`,
              }),
              discountPrice: creditNoteItem?.discount,
              ...(orderItem?.Coupon && {
                Coupon: {
                  ...orderItem?.Coupon,
                  amountOff: orderItem?.discount,
                },
              }),
              extensionDuration,
            }
          }
        }
      )

      setTransaction({
        ...data,
        total: data.CreditNote?.total,
        Order: {
          ...data.Order,
          OrderItems: mapOrderCreditNoteLineItems.filter((val: any) => val),
        },
      })
    }

    if (!hasCreditNote) {
      const mapOrderInvoiceLineItems = data.Order?.OrderItems?.map(
        (orderItem: any) => {
          const invoiceLineItems = new Map(
            data.Order?.Invoices?.[0]?.InvoiceLineItems.map((val: any) => [
              val.orderItemId,
              val,
            ])
          )
          const invoiceLineItem: any = invoiceLineItems.get(orderItem.id)

          if (invoiceLineItem) {
            return {
              ...orderItem,
              price: invoiceLineItem?.subtotal,
              ...(orderItem.ownerType === 'productItem' && {
                startsAt: invoiceLineItem?.startsAt,
                endsAt: invoiceLineItem?.endsAt,
                ProductItem: {
                  ...orderItem.ProductItem,
                  Product: {
                    ...orderItem.ProductItem?.Product,
                    name: invoiceLineItem?.name,
                  },
                },
              }),
              ...(orderItem.ownerType === 'addOn' && {
                name: invoiceLineItem?.name,
              }),
              ...(invoiceLineItem?.OrderItem?.Coupon && {
                discountPrice: transformCoupon(
                  invoiceLineItem?.OrderItem?.Coupon,
                  invoiceLineItem?.subtotal
                ).discountPrice,
              }),
              Coupon: invoiceLineItem?.OrderItem?.Coupon,
            }
          }
        }
      )

      setTransaction({
        ...data,
        total: data.Order?.Invoices?.[0]?.total,
        Order: {
          ...data.Order,
          OrderItems: mapOrderInvoiceLineItems.filter((val: any) => val),
          Coupons: mapOrderInvoiceLineItems
            .filter((val: any) => val?.Coupon)
            .map((val: any) => val?.Coupon),
          discountTotal:
            mapOrderInvoiceLineItems
              ?.filter((orderItem: any) => orderItem?.Coupon)
              ?.reduce((prev: any, cur: any) => prev + cur.discountPrice, 0) ||
            0,
        },
      })
    }
  }, [transaction, hasCreditNote])

  return (
    <Container>
      <BackHeader
        navigation={navigation}
        fallback="Home"
        noPrev={!authenticated}
        text="Detail transaksi"
      />

      <ScrollView
        style={[
          tailwind('pt-2 pb-7'),
          {
            marginTop: 40,
          },
        ]}
      >
        <View style={tailwind('pt-3 px-4')}>
          <Text style={tailwind('text-lg mb-3 font-bold')}>
            Alamat Pengiriman
          </Text>

          <View style={tailwind('mb-4')}>
            <Text style={tailwind('mb-2 font-bold')}>
              {transactionState?.Order?.DeliveryAddress?.addressName || '-'}
            </Text>
            <Text style={tailwind('mb-1')}>
              {transactionState?.Order?.Customer?.name} (
              {transactionState?.Order?.Customer?.User?.phone})
            </Text>
            <Text>
              {transactionState?.Order?.Invoices?.[0]?.deliveryAddress || '-'}
            </Text>
          </View>

          <View style={tailwind('mb-4')}>
            <Text style={tailwind('text-lg mb-3 font-bold')}>
              Rincian Produk
            </Text>

            {transactionState &&
              transactionState.Order &&
              transactionOrderItems
                .filter((item: any) => item.ownerType === 'productItem')
                .map((item: any, index: number) => {
                  const len = (transactionState as any).Order.OrderItems.length
                  const isLastIndex = len > 1 ? len - 1 == index : false

                  const coupon = transactionState.Order?.Coupons?.find(
                    (val: any) => val.id === item.couponId
                  )

                  const durationDays = Math.floor(
                    DateTime.fromISO(`${item.endsAt}`).diff(
                      DateTime.fromISO(`${item.startsAt}`),
                      ['days']
                    ).days
                  )

                  return (
                    <TransactionItemRow
                      key={item.id}
                      isLastIndex={isLastIndex}
                      metadata={{
                        durationDays: !transactionState?.creditNoteId
                          ? `${durationDays || 0} hari`
                          : `+${item?.extensionDuration || 0} hari`,
                        startsAt: formatDate(item.startsAt, 'dd LLL yy'),
                        endsAt: formatDate(item.endsAt, 'dd LLL yy'),
                        imageUrl:
                          item.ProductItem?.Images?.[0]?.url ||
                          item.ProductItem?.Product?.Images?.[0]?.url ||
                          '',
                        coupon,
                        productName: item.ProductItem?.Product?.name,
                        productBrand: item.ProductItem?.Product?.brand,
                        productVariants:
                          item.ProductItem?.ProductVariants?.map(
                            (variant: any) => variant.name
                          ).join(' - ') || '',
                        price: item.price,
                        discount: item.discount,
                      }}
                    />
                  )
                })}

            <View style={tailwind('flex flex-col pt-4 pb-2')}>
              <View style={tailwind('flex flex-col')}>
                <View style={tailwind('flex flex-row justify-between mb-2')}>
                  <Text style={tailwind('text-sm')}>Total biaya sewa</Text>
                  <Text style={tailwind('text-sm')}>
                    {toCurrency(subTotal)}
                  </Text>
                </View>
              </View>

              <View>
                {transactionState &&
                  transactionState.Order &&
                  transactionOrderItems
                    .filter((val: any) => val?.Coupon)
                    .map((item: any, index: number) => {
                      return (
                        <View
                          key={`${item.id}-${index}`}
                          style={tailwind('flex flex-row justify-between mb-2')}
                        >
                          <Text style={tailwind('text-sm')}>
                            {item?.Coupon?.code}
                          </Text>

                          <Text style={tailwind('text-sm')}>
                            -
                            {toCurrency(
                              Math.floor(
                                item.price -
                                  transformCoupon(item?.Coupon, item.price)
                                    .discountPrice
                              )
                            )}
                          </Text>
                        </View>
                      )
                    })}
              </View>

              {transactionState &&
                transactionState.Order &&
                transactionOrderItems
                  .filter(
                    (item: any) => item.price && item.ownerType === 'addOn'
                  )
                  .map((item: any, index: number) => {
                    return (
                      <View
                        key={item.id}
                        style={tailwind('flex flex-row justify-between mb-2')}
                      >
                        <Text style={tailwind('text-sm')}>
                          {item?.AddOn?.name}
                        </Text>
                        <Text style={tailwind('text-sm')}>
                          {toCurrency(item?.price)}
                        </Text>
                      </View>
                    )
                  })}
            </View>

            <View style={tailwind('flex flex-col pt-4')}>
              <View style={tailwind('flex flex-row justify-between')}>
                <Text style={tailwind('text-sm')}>Total bayar</Text>
                <Text style={tailwind('text-sm font-bold')}>
                  {!hasCreditNote
                    ? transactionState?.Order?.Invoices?.[0]?.total &&
                      toCurrency(transactionState.Order.Invoices[0].total)
                    : toCurrency(transactionState?.total)}
                </Text>
              </View>

              <View style={tailwind('flex flex-col pt-4')}>
                <View style={tailwind('flex flex-row justify-between')}>
                  <Text style={tailwind('text-sm')}>Pembayaran</Text>
                  <Text style={tailwind('text-sm')}>Xendit</Text>
                </View>
              </View>

              <View style={tailwind('flex flex-col pt-4')}>
                <View style={tailwind('flex flex-row justify-between')}>
                  <Text style={tailwind('text-sm')}>Status Pembayaran</Text>
                  <Text
                    style={[
                      tailwind('text-sm'),
                      {
                        color: '#00443E',
                      },
                    ]}
                  >
                    {transactionState?.paymentStatus === 'pending' ||
                      (transactionState?.paymentStatus === 'open' &&
                        'Menunggu Pembayaran')}
                    {transactionState?.paymentStatus === 'paid' && 'Sukses'}
                  </Text>
                </View>
              </View>

              <View style={tailwind('flex flex-col pt-4')}>
                <View style={tailwind('flex flex-row justify-between')}>
                  <Text style={tailwind('text-sm')}>Nomor Pesanan</Text>
                  <Pressable
                    onPress={() => {
                      if (
                        transactionState &&
                        transactionState.xenditInvoiceUrl
                      ) {
                        Linking.openURL(transactionState.xenditInvoiceUrl)
                      }
                    }}
                  >
                    <Text
                      style={[
                        tailwind('text-sm'),
                        {
                          color: '#00443E',
                        },
                      ]}
                    >
                      {transactionState?.orderId || '-'}
                    </Text>
                  </Pressable>
                </View>
              </View>

              <View style={tailwind('flex flex-row justify-between py-4')}>
                <Text style={tailwind('text-sm')}>Status Pesanan</Text>
                <Text
                  style={[
                    tailwind(''),
                    {
                      fontSize: 14,
                      color: orderStatus?.primaryColor,
                    },
                  ]}
                >
                  {orderStatus?.orderStatus}
                </Text>
              </View>
            </View>
          </View>

          <View style={tailwind('pt-4 mb-10')}>
            <Text style={tailwind('text-lg mb-2 font-bold')}>Catatan</Text>
            <Text style={tailwind('text-sm')}>
              {transactionState?.Order?.DeliveryAddress?.instructions || ''}
            </Text>
          </View>

          {transactionState?.paymentStatus === 'pending' && (
            <View style={tailwind('mb-10')}>
              <Text style={[tailwind('font-bold text-sm mb-3')]}>
                Ubah Pesanan
              </Text>
              <Text style={tailwind('text-xs text-gray-500 mb-4')}>
                Anda dapat melakukan perubahan detail pemesanan dengan
                menghubungi CS untuk melakukan perubahan pada pesanan.
              </Text>

              <Pressable
                style={[
                  tailwind('py-2 px-5 rounded border text-white mb-2'),
                  {
                    borderColor: '#00443E',
                  },
                ]}
                onPress={() => {
                  Linking.openURL('https://wa.me/6281284455345')
                }}
              >
                <Text
                  style={[
                    tailwind('text-center text-sm font-bold'),
                    {
                      color: '#00443E',
                    },
                  ]}
                >
                  Hubungi Tim CS di WhatsApp
                </Text>
              </Pressable>
            </View>
          )}

          {transactionState?.paymentStatus === 'pending' && (
            <Pressable
              style={[
                tailwind('py-2 px-5 rounded border text-white mb-2'),
                {
                  borderColor: '#00443E',
                },
              ]}
              onPress={() => {
                navigation.navigate('TransactionReceipt', {
                  transaction_uuid: params?.transaction_uuid,
                })
              }}
            >
              <Text
                style={[
                  tailwind('text-center text-sm font-bold'),
                  {
                    color: '#00443E',
                  },
                ]}
              >
                {transactionState?.Order?.status !== 'canceled'
                  ? 'Lihat Cara Bayar'
                  : 'Lihat Detail Invoice'}
              </Text>
            </Pressable>
          )}

          {authenticated && (
            <Pressable
              style={tailwind('bg-primary p-2 rounded text-white')}
              onPress={() => {
                navigation.navigate('Root', {
                  screen: 'Order',
                })
              }}
            >
              <Text
                style={[
                  tailwind('text-center text-sm font-bold'),
                  {
                    color: '#00443E',
                  },
                ]}
              >
                Kembali ke Dashboard
              </Text>
            </Pressable>
          )}
        </View>
      </ScrollView>
    </Container>
  )
}

type Metadata = {
  startsAt: string
  endsAt: string
  durationDays: string
  imageUrl: string
  productName: string
  productBrand: string
  productVariants: string
  price: number
  discount: number
  coupon?: any
}
type TransactionItemRowProps = {
  metadata: Partial<Metadata>
  isLastIndex: boolean
}

const TransactionItemRow: React.FC<TransactionItemRowProps> = ({
  isLastIndex,
  metadata,
}) => {
  const tailwind = useTailwind()

  const renderItem = () => {
    if (!metadata.coupon && !metadata.discount) {
      return (
        <View style={[tailwind('flex flex-col'), { flexGrow: '1' }]}>
          <View style={tailwind('flex flex-row items-center mb-1')}>
            <Calendar />
            <Text style={tailwind('text-sm ml-1')}>
              {metadata?.startsAt || ''} - {metadata?.endsAt || ''} (
              {metadata?.durationDays})
            </Text>
          </View>

          <Text style={tailwind('text-sm font-bold')}>
            {toCurrency(metadata.price)}
          </Text>
        </View>
      )
    }

    return (
      <View style={[tailwind('flex flex-col'), { flexGrow: '1' }]}>
        <View style={tailwind('flex flex-row items-center mb-1')}>
          <Calendar />
          <Text style={tailwind('text-sm ml-1')}>
            {metadata?.startsAt || ''} - {metadata?.endsAt || ''} (
            {metadata?.durationDays})
          </Text>
        </View>

        <View style={tailwind('flex flex-row items-center mb-1')}>
          <Text
            style={[
              {
                textDecorationLine: 'line-through',
                textDecorationStyle: 'solid',
              },
              tailwind('text-sm font-bold'),
            ]}
          >
            {toCurrency(metadata.price)}
          </Text>
          <Text style={tailwind('text-sm font-bold pl-3')}>
            {!metadata.discount &&
              toCurrency(
                transformCoupon(metadata.coupon, metadata.price).discountPrice
              )}
            {metadata.discount &&
              toCurrency(Number(metadata.price) - metadata.discount)}
          </Text>
        </View>
      </View>
    )
  }

  return (
    <View
      style={[
        tailwind(
          'flex flex-row justify-between border-b-2 border-gray-200 pb-4 w-full'
        ),
        {
          marginTop: 15,
          borderBottomWidth: !isLastIndex ? 2 : 0,
        },
      ]}
    >
      <View style={tailwind('flex flex-row justify-start w-full')}>
        <Image
          imageStyles={[tailwind('mb-4'), { aspectRatio: 1 }]}
          source={{
            uri: metadata?.imageUrl || '',
          }}
          width={60}
          height={60}
          rounded
        />
        <View style={[tailwind('flex pl-4'), { flexGrow: '1' }]}>
          <Text style={tailwind('font-bold text-base')}>
            {metadata?.productBrand} - {metadata?.productName || ''}
          </Text>
          <Text style={tailwind('text-sm')}>
            {metadata?.productVariants || ''}
          </Text>
          {renderItem()}
        </View>
      </View>
    </View>
  )
}

export default TransactionDetail
