import { useFocusEffect } from '@react-navigation/native'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Image, Pressable, ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { initiateResetPassword } from '~/store/resetPassword'
import { useAppDispatch } from '~/store/store'

import Container from '~/components/Container'
import Text from '~/components/StyledText'
import Button from '~/components/base/Button/Button'
import Input from '~/components/base/Input'
import BackHeader from '~/components/headers/BackHeader'

import { RootStackProps } from '~/navigation/types'

export default function ForgotPassword({
  route,
  navigation,
}: RootStackProps<'ForgotPassword'>) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()
  const params = route.params

  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
  } = useForm({
    mode: 'onChange',
  })

  const isError = Object.keys(errors)?.length > 0

  const onSubmit = async (data: any) => {
    const res = await dispatch(
      initiateResetPassword({
        email: data.email,
      })
    )

    if (initiateResetPassword.fulfilled.match(res)) {
      navigation.navigate('ForgotPasswordSuccess', {
        email: data.email,
      })
    }
  }

  // Trigger form on init
  useFocusEffect(
    useCallback(() => {
      trigger()
    }, [])
  )

  return (
    <Container>
      <BackHeader navigation={navigation} text={'Lupa Password'} />

      <ScrollView
        style={[
          tailwind('pt-14 flex flex-col w-full relative'),
          {
            height: 'calc(100% - 60px)',
            paddingBottom: 20,
          },
        ]}
        contentContainerStyle={{
          height: '100%',
        }}
      >
        <View
          style={[
            tailwind('pt-6 px-4 relative'),
            {
              height: 'calc(100% - 60px)',
            },
          ]}
        >
          <Image
            source={require('~/assets/images/bioma-logo.png')}
            style={[
              {
                width: 72,
                height: 20,
                resizeMode: 'contain',
              },
              tailwind('mb-4 p-2'),
            ]}
          />
          <Text
            style={[
              tailwind('mb-5 text-3xl font-bold'),
              {
                color: '#00433E',
              },
            ]}
          >
            Lupa Password?
          </Text>

          <Text style={tailwind('text-base mb-5')}>
            Jangan khawatir, Silahkan masukan email kamu untuk proses reset
            password-mu.
          </Text>

          <View>
            <Text style={tailwind('mb-2')}>Email</Text>
            <Input
              style={[
                tailwind(
                  'p-4 pr-6 w-full bg-white border border-gray-200 rounded'
                ),
              ]}
              placeholder={'Masukkan Email'}
              keyboardType="email-address"
              control={control}
              name="email"
              errors={errors}
              rules={{
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Format email tidak valid',
                },
              }}
              defaultValue=""
            />
          </View>
        </View>

        <View style={[tailwind('sticky bottom-1.5 w-full px-4')]}>
          <Button
            color="primary"
            text="Kirim"
            disabled={isError}
            style={tailwind('text-center py-3')}
            onPress={handleSubmit(onSubmit)}
          />
        </View>
      </ScrollView>
    </Container>
  )
}
