import { useMemo } from 'react'
import { useTailwind } from 'tailwind-rn'

import { RootState, useAppSelector } from '~/store/store'

import Text from '~/components/StyledText'

import { toCurrency } from '~/lib/money'

interface IProps {
  selectedProductItemId: number
  productItemsMap: Map<number, any> | undefined
}

export default function PriceRetail({
  selectedProductItemId,
  productItemsMap,
}: IProps) {
  const tailwind = useTailwind()

  const { product } = useAppSelector((state: RootState) => state.product)

  const selectedProductItemRetailPrice: number = useMemo(() => {
    if (productItemsMap && selectedProductItemId) {
      if (productItemsMap.get(selectedProductItemId)) {
        return productItemsMap
          .get(selectedProductItemId)
          .retailPrice.toLocaleString()
      }
    }
    return 0
  }, [productItemsMap, selectedProductItemId])

  const retailPriceRange: number[] | undefined = useMemo(() => {
    if (product) {
      let min = Number.MAX_VALUE
      let max = 0

      product.ProductItems?.forEach((productItem: any) => {
        min = Math.min(min, productItem.retailPrice)
        max = Math.max(max, productItem.retailPrice)
      })

      return [min, max]
    }
    return [0, 0]
  }, [product])

  if (!selectedProductItemRetailPrice || !retailPriceRange)
    return (
      <Text style={tailwind('text-xs text-gray-400  leading-4 mt-1')}>
        Harga Pasaran Rp.0
      </Text>
    )

  return (
    <Text style={tailwind('text-xs text-gray-400  leading-4 mt-1')}>
      Harga Pasaran{' '}
      {selectedProductItemId
        ? toCurrency(selectedProductItemRetailPrice)
        : retailPriceRange[0] !== retailPriceRange[1]
        ? `${toCurrency(retailPriceRange[0])} - ${toCurrency(
            retailPriceRange[1]
          )}`
        : toCurrency(retailPriceRange[0])}
    </Text>
  )
}
