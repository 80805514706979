import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const Calendar = (props: any) => (
  <Svg
    width={12}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M4 7a.484.484 0 0 1-.357-.144A.483.483 0 0 1 3.5 6.5c0-.142.048-.26.143-.356A.485.485 0 0 1 4 6c.142 0 .26.048.356.144A.485.485 0 0 1 4.5 6.5c0 .142-.048.26-.144.356A.484.484 0 0 1 4 7Zm2 0a.483.483 0 0 1-.356-.144A.483.483 0 0 1 5.5 6.5c0-.142.048-.26.144-.356A.483.483 0 0 1 6 6c.142 0 .26.048.356.144A.485.485 0 0 1 6.5 6.5c0 .142-.048.26-.144.356A.484.484 0 0 1 6 7Zm2 0a.483.483 0 0 1-.356-.144A.483.483 0 0 1 7.5 6.5c0-.142.048-.26.144-.356A.483.483 0 0 1 8 6c.142 0 .26.048.356.144A.484.484 0 0 1 8.5 6.5c0 .142-.048.26-.144.356A.483.483 0 0 1 8 7Zm-5.5 4a.964.964 0 0 1-.706-.293A.964.964 0 0 1 1.5 10V3c0-.275.098-.51.294-.706A.963.963 0 0 1 2.5 2H3V1h1v1h4V1h1v1h.5c.275 0 .51.098.707.294A.962.962 0 0 1 10.5 3v7c0 .275-.098.51-.293.707A.964.964 0 0 1 9.5 11h-7Zm0-1h7V5h-7v5Z"
      fill="#333"
    />
  </Svg>
)

export default Calendar
