import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form'
import { KeyboardTypeOptions, TextInput, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import Text from '~/components/StyledText'

type Rules = UseControllerProps['rules']

interface IProps {
  multiline?: boolean
  numberOfLines?: number
  style?: any[] | object
  control: Control<any>
  placeholder: string
  placeholderTextColor?: string
  forwardRef?: any
  keyboardType?: KeyboardTypeOptions
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters'
  autoCorrect?: boolean
  rules: Rules
  name: string
  defaultValue: string
  secureTextEntry?: boolean
  errors: DeepMap<FieldValues, FieldError>
}

const Input: React.FC<IProps> = ({
  multiline = false,
  numberOfLines = 1,
  style,
  placeholder,
  placeholderTextColor,
  keyboardType,
  autoCapitalize,
  autoCorrect,
  control,
  rules,
  name,
  defaultValue = '',
  forwardRef,
  secureTextEntry,
  errors,
}) => {
  const tailwind = useTailwind()

  let styleUnpacked: any[] = []
  if (style) {
    styleUnpacked = typeof style === 'object' ? [style] : style
  }
  return (
    <View style={tailwind('w-full')}>
      <Controller
        control={control}
        rules={rules}
        render={({ field: { onChange, onBlur, value } }: { field: any }) => (
          <TextInput
            multiline={multiline}
            numberOfLines={numberOfLines}
            placeholder={placeholder}
            placeholderTextColor={placeholderTextColor}
            style={[
              tailwind('p-4 pr-6 w-full border rounded'),
              { borderColor: '#EAEAEA' },
              ...styleUnpacked,
            ]}
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            keyboardType={keyboardType}
            autoCapitalize={autoCapitalize}
            autoCorrect={autoCorrect}
            secureTextEntry={secureTextEntry}
            ref={forwardRef}
          />
        )}
        name={name}
        defaultValue={defaultValue}
      />
      {errors[name] && (
        <Text style={tailwind('text-sm text-red-500 mt-1')}>
          {errors[name].message}
        </Text>
      )}
    </View>
  )
}

export type { IProps }

export default Input
