import { DateTime } from 'luxon'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Linking, Pressable, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { addCartItem, getCurrentCart } from '~/store/cart'
import { RootState, useAppDispatch, useAppSelector } from '~/store/store'

import Text from '~/components/StyledText'
import Toast, { IRef } from '~/components/base/Toast'
import CartSuccessInfo from '~/components/product/CartSuccess'
import UnavailableItemInfo from '~/components/product/UnavailableItemInfo'
import { ProductSelectedAction } from '~/components/product/types'

import { toDateTime } from '~/lib/datetime'
import { toCurrency } from '~/lib/money'
import ChatDots from '~/svg/ChatDots'

interface IProps {
  selectedDuration: number
  selectedDate: Date | null
  pricingRates: any[]
  selectedProductId: number
  selectedProductItemId: number
  navigation: any
  reducerDispatch: React.Dispatch<ProductSelectedAction>
}

export default function ProductCheckout({
  selectedDuration,
  selectedDate,
  pricingRates,
  selectedProductId,
  selectedProductItemId,
  navigation,
  reducerDispatch,
}: IProps) {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()

  const { user } = useAppSelector((state: RootState) => state.user)

  const [cartInfoVisible, setCartInfoVisible] = useState(false)
  const [errorState, setError] = useState<number>(0)

  const toastRef = useRef<IRef>(null)

  const pricingRate = useMemo(() => {
    if (pricingRates && pricingRates.length)
      return pricingRates.find((value) => value?.id == selectedDuration)
  }, [pricingRates, selectedDuration])

  const onAddToCart = async () => {
    if (!user) {
      navigation.navigate('Login', {
        redirect_from: 'Product',
        param_name: 'product_id',
        param_value: String(selectedProductId),
      })
      return
    } else if (!user.PrimaryAddress) {
      navigation.navigate('Root', {
        screen: 'Dashboard',
        params: { screen: 'Address' },
      })
      return
    }

    const selectedPricingRate = pricingRate
    const payload = {
      addressId: user?.addressId || 0,
      cartItems: [
        {
          productItemId: selectedProductItemId,
          startsAt: selectedDate
            ? toDateTime(selectedDate)!.toISO()
            : DateTime.local().toISO(),
          endsAt: selectedDate
            ? toDateTime(selectedDate)!
                .plus({ days: selectedPricingRate.duration })
                .toISO()
            : DateTime.local()
                .plus({ days: selectedPricingRate.duration })
                .toISO(),
          pricingRateId: selectedPricingRate.id,
        },
      ],
    }

    const unsafeIsoToDate = (at: string) => {
      return DateTime.fromISO(at).toISO() as unknown as Date
    }

    const res = await dispatch(addCartItem(payload))
    console.log(res)

    if (addCartItem.fulfilled.match(res)) {
      console.log('create cart res', res)
      await dispatch(getCurrentCart())
      setCartInfoVisible(true)
    } else {
      console.log('failed')
      toastRef.current?.open('Tidak dapat dimasukkan ke keranjang.')
      setError(1)
    }
  }

  const isValid = useMemo(() => {
    const values = [selectedProductItemId, pricingRate, selectedDate]
    return !values.includes(undefined)
  }, [selectedProductItemId, pricingRate, selectedDate])

  useEffect(() => {
    console.log('alls', [selectedProductItemId, pricingRate, selectedDate])
  }, [selectedProductItemId, pricingRate, selectedDate])

  return (
    <View
      style={[
        tailwind('flex flex-row p-4 justify-between bg-white sticky bottom-0'),
        { shadowColor: 'rgba(0,0,0,0.25)', shadowRadius: 8, elevation: 5 },
      ]}
    >
      <CartSuccessInfo
        visible={cartInfoVisible}
        onPress={() => navigation.navigate('Cart')}
        onClose={() => setCartInfoVisible(false)}
      />

      <View>
        <Text style={tailwind('text-xs text-gray-500')}>Total biaya sewa</Text>
        <Text style={tailwind('text-sm font-bold leading-5')}>
          {selectedProductItemId && pricingRate
            ? toCurrency(pricingRate?.price)
            : 'Pilih varian produk'}
        </Text>
      </View>
      <View style={tailwind('flex flex-row items-center justify-between')}>
        <Pressable
          style={[
            tailwind('flex items-center justify-center rounded mr-1'),
            {
              width: 44,
              height: 35,
              backgroundColor: '#E9E9E9',
              borderRadius: 5,
            },
          ]}
          onPress={() => {
            Linking.openURL('https://wa.me/+6281284455345')
          }}
        >
          <ChatDots />
        </Pressable>

        <Pressable
          disabled={!Boolean(isValid)}
          style={[
            tailwind('py-2 px-5 rounded text-white ml-2'),
            {
              backgroundColor: Boolean(isValid)
                ? '#94DEC6'
                : 'rgb(225, 225, 225)',
            },
          ]}
          onPress={() => {
            if (isValid) onAddToCart()
          }}
        >
          <Text
            style={{
              color: Boolean(isValid) ? 'rgb(0, 0, 0)' : '#9b9b9b',
            }}
          >
            {cartInfoVisible ? '✓ Berhasil' : '+ Keranjang'}
          </Text>
        </Pressable>
      </View>

      {!!errorState && (
        <UnavailableItemInfo open={!!errorState} setOpen={() => setError(0)} />
      )}
    </View>
  )
}
