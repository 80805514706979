import React from 'react'
import { Linking, Modal, Pressable, StyleSheet, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import Text from '~/components/StyledText'
import Button from '~/components/base/Button/Button'

import CartUnavailableIcon from '~/svg/CartUnavailable'
import CloseIcon from '~/svg/Close'

type UnavailableItemInfoProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const UnavailableItemInfo: React.FC<UnavailableItemInfoProps> = ({
  open,
  setOpen,
}) => {
  const tailwind = useTailwind()

  return (
    <View style={[styles.container]}>
      <Modal
        animationType="fade"
        transparent={true}
        visible
        onRequestClose={() => setOpen(!open)}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View
              style={tailwind(
                'w-full flex flex-row items-center justify-between'
              )}
            >
              <Text style={styles.modalTitle}>Mengenai Keranjang</Text>
              <Pressable
                style={[styles.closeBtn]}
                onPress={() => setOpen(!open)}
              >
                <CloseIcon />
              </Pressable>
            </View>

            <View>
              <View style={tailwind('flex items-center justify-between mb-4')}>
                <CartUnavailableIcon />
              </View>

              <Text style={tailwind('text-black')}>
                Maaf produk tidak tersedia untuk tanggal sewa yang kamu
                tentukan. Kamu dapat menghubungi customer service kami untuk
                mendapatkan bantuan terkait produk yang ingin kamu sewa.
              </Text>

              <View style={tailwind('flex items-center justify-between')}>
                <Button
                  variant="outlined"
                  text="Hubungi Customer Service"
                  style={tailwind('mt-5 w-8/12')}
                  onPress={() => {
                    Linking.openURL('https://wa.me/+6281284455345')
                  }}
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 25,
    alignItems: 'flex-start',
    shadowColor: '#000',
    maxWidth: 'calc(400px - 50px)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: 'absolute',
    top: '10%',
  },
  modalTitle: {
    marginBottom: 15,
    textAlign: 'center',
    fontSize: 18,
  },
  closeBtn: {
    padding: 0,
    elevation: 2,
    marginTop: '-10px',
    border: 0,
  },
})

export default UnavailableItemInfo
