import {
  DrawerContentScrollView,
  DrawerItem,
  createDrawerNavigator,
} from '@react-navigation/drawer'
import { Image, Text, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { logout } from '~/store/auth'
import { getCurrentCart } from '~/store/cart'
import { RootState, useAppDispatch, useAppSelector } from '~/store/store'

import Cart from '~/screens/Cart'
import Checkout from '~/screens/Checkout'
import ForgotPassword from '~/screens/ForgotPassword'
import ForgotPasswordSuccess from '~/screens/ForgotPasswordSuccess'
import Login from '~/screens/Login'
import NotFoundScreen from '~/screens/NotFoundScreen'
import OrderConfirmation from '~/screens/OrderConfirmation'
import Product from '~/screens/Product'
import Products from '~/screens/Products'
import Register from '~/screens/Register'
import RegisterSuccess from '~/screens/RegisterSuccess'
import ResetPassword from '~/screens/ResetPassword'
import ResetPasswordSuccess from '~/screens/ResetPasswordSuccess'
import TermsCondition from '~/screens/TermsCondition'
import TransactionDetail from '~/screens/TransactionDetail'
import TransactionReceipt from '~/screens/TransactionReceipt'
import Waitlist from '~/screens/Waitlist'
import WaitlistInfo from '~/screens/WaitlistInfo'
import WaitlistSuccess from '~/screens/WaitlistSuccess'
import Xendit from '~/screens/Xendit'

import BottomTabNavigator from '~/navigation/BottomTabNavigator'
import { RootStackParamList } from '~/navigation/types'

const Drawer = createDrawerNavigator<RootStackParamList>()

const HiddenPages = ['Root', 'NotFound', 'Cart']

export default function DrawerNavigator() {
  const tailwind = useTailwind()
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state: RootState) => state.user)
  const { authenticated } = useAppSelector((state: RootState) => state.auth)

  return (
    <Drawer.Navigator
      initialRouteName="Root"
      screenOptions={{
        drawerPosition: 'right',
        drawerStyle: {
          borderTopLeftRadius: 16,
          borderBottomLeftRadius: 16,
        },
      }}
      screenListeners={({ navigation }) => ({
        focus: () => {
          dispatch(getCurrentCart())
        },
      })}
      drawerContent={(props) => {
        const filteredProps = {
          ...props,
          state: {
            ...props.state,
            routeNames: props.state.routeNames.filter(
              (routeName) => !HiddenPages.includes(routeName)
            ),
            routes: props.state.routes.filter(
              (route) => !HiddenPages.includes(route.name)
            ),
          },
        }

        if (!authenticated) {
          return (
            <DrawerContentScrollView {...filteredProps}>
              <View style={tailwind('pt-4 px-4')}>
                <Image
                  source={require('~/assets/images/bioma-logo.png')}
                  style={[{ height: '20px', width: '73px' }]}
                />
              </View>
              <DrawerItem
                style={{
                  borderBottomColor: '#E9E9E9',
                  borderBottomWidth: 1,
                  borderRadius: 0,
                }}
                labelStyle={{ color: '#333333' }}
                label="Login"
                icon={() => (
                  <Image
                    source={require('~/assets/images/drawer/address.png')}
                    style={{ aspectRatio: 1 }}
                  />
                )}
                onPress={() => {
                  props.navigation.navigate('Login')
                }}
              />
              <DrawerItem
                style={{
                  borderBottomColor: '#E9E9E9',
                  borderBottomWidth: 1,
                  borderRadius: 0,
                }}
                labelStyle={{ color: '#333333' }}
                label="Register"
                icon={() => (
                  <Image
                    source={require('~/assets/images/drawer/address.png')}
                  />
                )}
                onPress={() => {
                  props.navigation.navigate('Register')
                }}
              />
              <DrawerItem
                style={{
                  borderBottomColor: '#E9E9E9',
                  borderBottomWidth: 1,
                  borderRadius: 0,
                }}
                labelStyle={{ color: '#333333' }}
                label="Syarat & Ketentuan"
                icon={() => (
                  <Image
                    source={require('~/assets/images/drawer/address.png')}
                  />
                )}
                onPress={() => {
                  props.navigation.navigate('TermsCondition')
                }}
              />
            </DrawerContentScrollView>
          )
        }

        return (
          <DrawerContentScrollView {...filteredProps}>
            <View style={tailwind('pt-4 px-4')}>
              <Image
                source={require('~/assets/images/bioma-logo.png')}
                style={[{ height: '20px', width: '73px' }]}
              />
            </View>
            <View style={tailwind('p-4')}>
              <Text style={tailwind('text-lg font-bold text-teal mb-2')}>
                {user?.name}
              </Text>
              <Text style={tailwind('text-gray')}>{user?.User?.email}</Text>
            </View>
            <DrawerItem
              style={{
                borderBottomColor: '#E9E9E9',
                borderBottomWidth: 1,
                borderRadius: 0,
              }}
              labelStyle={{ color: '#333333', marginLeft: '-20px' }}
              label="Alamat"
              icon={() => (
                <Image
                  source={require('~/assets/images/drawer/address.png')}
                  style={{ height: '20px', width: '20px' }}
                />
              )}
              onPress={() => {
                props.navigation.navigate('Root', {
                  screen: 'Dashboard',
                  params: {
                    screen: 'Address',
                  },
                })
              }}
            />
            <DrawerItem
              style={{
                borderBottomColor: '#E9E9E9',
                borderBottomWidth: 1,
                borderRadius: 0,
              }}
              labelStyle={{ color: '#333333', marginLeft: '-20px' }}
              label="Daftar Transaksi"
              icon={() => (
                <Image
                  source={require('~/assets/images/drawer/transaction.png')}
                  style={{ height: '20px', width: '20px' }}
                />
              )}
              onPress={() => {
                props.navigation.navigate('Root', {
                  screen: 'Order',
                  params: {
                    screen: 'OrderHistory',
                  },
                })
              }}
            />
            <DrawerItem
              style={{
                borderBottomColor: '#E9E9E9',
                borderBottomWidth: 1,
                borderRadius: 0,
              }}
              labelStyle={{ color: '#333333', marginLeft: '-20px' }}
              label="Syarat & Ketentuan"
              icon={() => (
                <Image
                  source={require('~/assets/images/drawer/transaction.png')}
                  style={{ height: '20px', width: '20px' }}
                />
              )}
              onPress={() => {
                props.navigation.navigate('TermsCondition')
              }}
            />
            <DrawerItem
              style={{
                borderBottomColor: '#E9E9E9',
                borderBottomWidth: 1,
                borderRadius: 0,
              }}
              labelStyle={{ color: '#333333', marginLeft: '-20px' }}
              label="Keluar"
              icon={() => (
                <Image
                  source={require('~/assets/images/drawer/logout.png')}
                  style={{ height: '20px', width: '20px' }}
                />
              )}
              onPress={() => {
                dispatch(logout())
              }}
            />
          </DrawerContentScrollView>
        )
      }}
    >
      <Drawer.Screen
        name="Root"
        component={BottomTabNavigator}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="Login"
        component={Login}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="Register"
        component={Register}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="Product"
        component={Product}
        options={{ headerShown: false }}
      />

      <Drawer.Screen
        name="Products"
        component={Products}
        options={{ headerShown: false }}
      />

      <Drawer.Screen
        name="Cart"
        component={Cart}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="Waitlist"
        component={Waitlist}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="WaitlistSuccess"
        component={WaitlistSuccess}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="RegisterSuccess"
        component={RegisterSuccess}
        options={{ headerShown: false }}
      />

      <Drawer.Screen
        name="OrderConfirmation"
        component={OrderConfirmation}
        options={{ headerShown: false }}
      />

      <Drawer.Screen
        name="TransactionReceipt"
        component={TransactionReceipt}
        options={{ headerShown: false }}
      />

      <Drawer.Screen
        name="TransactionDetail"
        component={TransactionDetail}
        options={{ headerShown: false }}
      />

      <Drawer.Screen
        name="Xendit"
        component={Xendit}
        options={{ headerShown: false }}
      />

      <Drawer.Screen
        name="Checkout"
        component={Checkout}
        options={{ headerShown: false }}
      />

      <Drawer.Screen
        name="ForgotPassword"
        component={ForgotPassword}
        options={{ headerShown: false }}
      />

      <Drawer.Screen
        name="ForgotPasswordSuccess"
        component={ForgotPasswordSuccess}
        options={{ headerShown: false }}
      />

      <Drawer.Screen
        name="ResetPassword"
        component={ResetPassword}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="ResetPasswordSuccess"
        component={ResetPasswordSuccess}
        options={{ headerShown: false }}
      />
      <Drawer.Screen
        name="TermsCondition"
        component={TermsCondition}
        options={{ headerShown: false }}
      />
    </Drawer.Navigator>
  )
}
