export default [
  {
    name: 'Baby Clothes',
    id: 1025,
    icon: '👶',
  },
  {
    name: 'Baby Toys',
    id: 1001,
    icon: '🧸',
  },
  {
    name: 'Child Toys',
    id: 1029,
    icon: '🪀',
  },
  {
    name: 'Moms',
    id: 6712,
    icon: '🤱',
  },
  {
    name: 'Breast Pump',
    id: 1017,
    icon: '🍼',
  },
  {
    name: 'Crib & Baby Box',
    id: 2897,
    icon: '🛏️',
  },
  {
    name: 'Playhouse',
    id: 1032,
    icon: '🛋️',
  },
  {
    name: 'Bedrail',
    id: 2833,
    icon: '🛏️',
  },
  {
    name: 'Bikes',
    id: 5942,
    icon: '🚲',
  },
  {
    name: 'Toys',
    id: 1022,
    icon: '🎠',
  },
]
