import { CartAttributesFull } from '@withbioma/apitype-publicapi-customer/lib/v1/common/cart_full'
import { DateTime } from 'luxon'
import React from 'react'
import { Pressable, View } from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import Text from '~/components/StyledText'
import Image from '~/components/base/Image'

import { toCurrency } from '~/lib/money'
import Calendar from '~/svg/Calendar'
import Trash from '~/svg/Trash'

type CartItem = NonNullable<CartAttributesFull['CartItems']>[number]

type Props = {
  cartItem: CartItem | null | undefined
  unavailableIds?: number[]
  isFirstIndex: boolean
  isLastIndex: boolean
  onDelete: (id: number) => void
  subTotal: number
  hideDelete?: boolean
}

const CartItemRow: React.FC<Props> = ({
  cartItem,
  isFirstIndex,
  isLastIndex,
  unavailableIds,
  onDelete,
  subTotal,
  hideDelete = false,
}) => {
  const tailwind = useTailwind()

  const unavailableItem = (id: number) => {
    return unavailableIds?.includes(id) || false
  }

  if (!cartItem) return <React.Fragment />

  return (
    <View
      style={[
        tailwind('border-gray-200 w-full'),
        {
          paddingTop: !isFirstIndex ? 25 : 0,
          paddingBottom: 30,
          borderBottomWidth: !isLastIndex ? 2 : 0,
        },
      ]}
    >
      <View style={tailwind('flex flex-row justify-start w-full')}>
        <Image
          imageStyles={[tailwind('mb-4'), { aspectRatio: 1 }]}
          source={{
            uri:
              cartItem?.ProductItem?.Images?.[0]?.url ||
              cartItem.ProductItem?.Product?.Images?.[0]?.url ||
              '',
          }}
          width={60}
          height={60}
          rounded
        />

        <View style={[{ flexGrow: 1 }, tailwind('w-8/12')]}>
          <View style={[tailwind('flex flex-row justify-between pl-4')]}>
            <View style={[tailwind('flex'), { maxWidth: 200 }]}>
              <Text
                style={[
                  tailwind('font-bold text-base'),
                  {
                    opacity: !unavailableItem(cartItem.id) ? 1 : '.5',
                  },
                ]}
              >
                {cartItem?.ProductItem?.Product?.name || ''}
              </Text>
              <Text
                style={[
                  tailwind('text-sm'),
                  {
                    opacity: !unavailableItem(cartItem.id) ? 1 : '.5',
                  },
                ]}
              >
                {cartItem?.ProductItem?.ProductVariants?.map(
                  (variant) => variant.name
                ).join(' - ') || ''}
              </Text>

              {unavailableItem(cartItem.id) && (
                <Text style={[tailwind('py-1'), { color: '#E05151' }]}>
                  Stok tidak tersedia di tanggal ini
                </Text>
              )}

              <View
                style={[
                  tailwind('flex flex-row justify-between items-center'),
                  { flexGrow: '1' },
                ]}
              >
                <View style={tailwind('flex flex-row items-center mb-1')}>
                  <Calendar />
                  <Text style={tailwind('text-sm ml-1')}>
                    {DateTime.fromISO(`${cartItem.startsAt}`).toFormat(
                      'dd LLL'
                    ) || ''}{' '}
                    -{' '}
                    {DateTime.fromISO(`${cartItem.endsAt}`).toFormat(
                      'dd LLL'
                    ) || ''}{' '}
                    (
                    {Math.floor(
                      DateTime.fromISO(`${cartItem.endsAt}`).diff(
                        DateTime.fromISO(`${cartItem.startsAt}`),
                        ['days']
                      ).days
                    ) || 0}{' '}
                    hari)
                  </Text>
                </View>
              </View>
            </View>

            <View style={[tailwind('flex flex-col justify-between items-end')]}>
              {!unavailableItem(cartItem.id) && !hideDelete && (
                <Pressable onPress={() => onDelete(cartItem.id)}>
                  <Trash />
                </Pressable>
              )}

              <Text
                style={[
                  tailwind('text-sm font-bold'),
                  {
                    opacity: !unavailableItem(cartItem.id) ? 1 : '.5',
                  },
                ]}
              >
                {toCurrency(subTotal) || 'Rp.0'}
              </Text>
            </View>
          </View>

          <View
            style={[
              tailwind('pl-4'),
              {
                maxWidth: 305,
              },
            ]}
          >
            {unavailableItem(cartItem.id) && (
              <Text style={[tailwind(''), { color: '#E05151' }]}>
                Ganti tanggal atau hapus produk sewa untuk melanjutkan
              </Text>
            )}
          </View>
        </View>
      </View>
    </View>
  )
}

export default CartItemRow
