import * as React from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

const PaymentFailed = (props: any) => (
  <Svg
    width={120}
    height={120}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G clipPath="url(#a)">
      <Path
        d="M48.889 52.91H15.694a.688.688 0 0 1-.694-.695c0-.39.306-.696.694-.696H48.89c.389 0 .694.306.694.696 0 .389-.305.695-.694.695ZM15.694 92.393a.688.688 0 0 1-.694-.695V54.439c0-.389.306-.695.694-.695.39 0 .695.306.695.695v37.259c0 .39-.306.695-.695.695ZM51.944 92.393H17.64a.688.688 0 0 1-.695-.695c0-.39.306-.695.695-.695h34.305c.39 0 .695.306.695.695 0 .39-.306.695-.695.695ZM51.944 90.585a.704.704 0 0 1-.569-.292l-4.958-6.854 3.07-6.84-6.237-9.703 4.986-13.263a.688.688 0 0 1 .889-.404.689.689 0 0 1 .403.89l-4.736 12.596 6.264 9.76-3.042 6.784 4.486 6.214a.705.705 0 0 1-.153.973.645.645 0 0 1-.403.14ZM90.833 52.91h-32.5a.688.688 0 0 1-.694-.695c0-.39.305-.696.694-.696h32.5c.389 0 .695.306.695.696 0 .389-.306.695-.695.695ZM90.833 72.512a.688.688 0 0 1-.694-.695V53.883c0-.39.305-.696.694-.696.389 0 .695.306.695.696v17.934c0 .389-.306.695-.695.695ZM61.944 90.585a.684.684 0 0 1-.555-.278l-4.806-6.562 3.334-8.605-6.667-8.467 4.43-13.013a.689.689 0 1 1 1.306.445l-4.18 12.29 6.68 8.494-3.333 8.634 4.347 5.95a.693.693 0 0 1-.153.973.618.618 0 0 1-.403.14ZM70.833 92.393h-7.639a.688.688 0 0 1-.694-.695c0-.39.306-.695.694-.695h7.64c.388 0 .694.306.694.695 0 .39-.306.695-.695.695Z"
        fill="#5A3D28"
      />
      <Path
        d="M45.403 62.85H15.694a.688.688 0 0 1-.694-.695c0-.39.306-.695.694-.695h29.709c.389 0 .694.306.694.695 0 .39-.305.695-.694.695ZM15.694 70.566a.688.688 0 0 1-.694-.695c0-.39.306-.695.694-.695h30.14c.388 0 .694.306.694.695 0 .39-.306.695-.695.695H15.694ZM90.833 62.85h-35.32a.688.688 0 0 1-.694-.695c0-.39.306-.695.695-.695h35.32c.388 0 .694.306.694.695 0 .39-.306.695-.695.695ZM90.833 70.566h-34.18a.688.688 0 0 1-.695-.695c0-.39.306-.695.695-.695h34.18c.389 0 .695.306.695.695 0 .39-.306.695-.695.695ZM22.5 88.917a3.475 3.475 0 0 1 0-6.951 3.475 3.475 0 0 1 0 6.951Zm0-5.839a2.36 2.36 0 0 0-2.361 2.363 2.36 2.36 0 1 0 4.722 0 2.36 2.36 0 0 0-2.361-2.363ZM33.334 88.917a3.475 3.475 0 0 1 0-6.951 3.475 3.475 0 0 1 0 6.951Zm0-5.839a2.36 2.36 0 0 0-2.362 2.363 2.36 2.36 0 1 0 4.723 0 2.36 2.36 0 0 0-2.361-2.363Z"
        fill="#5A3D28"
      />
      <Path
        d="M55.833 62.503h34.723v7.09h-33.75l-2.5-3.197 1.527-3.893ZM15.973 62.642v6.812h29.444L43.75 66.95l1.528-4.31H15.973Z"
        fill="#5A3D28"
      />
      <Path
        d="M39.861 16.764a.705.705 0 0 1-.694-.626.68.68 0 0 1 .625-.75l10.694-.974a.69.69 0 0 1 .75.626.68.68 0 0 1-.624.75l-10.695.974h-.056ZM43.472 38.59a.687.687 0 0 1-.68-.57L39.18 18.14a.693.693 0 0 1 1.361-.25l3.61 19.88a.694.694 0 0 1-.555.807c-.041.014-.083.014-.125.014ZM52.5 39.285a.709.709 0 0 1-.694-.653l-1.25-21.966a.698.698 0 0 1 .652-.737.708.708 0 0 1 .736.654l1.25 21.965a.698.698 0 0 1-.652.737H52.5Z"
        fill="#E05151"
      />
      <Path
        d="M44.445 40.398a.678.678 0 0 1-.68-.598.682.682 0 0 1 .596-.778l8.056-1.112a.674.674 0 0 1 .778.598.682.682 0 0 1-.597.778l-8.056 1.112h-.097ZM68.194 23.993a.621.621 0 0 1-.278-.056l-8.333-3.753c-.347-.153-.5-.57-.347-.918.153-.347.57-.5.917-.347l8.333 3.753c.347.153.5.57.347.918a.72.72 0 0 1-.639.403ZM57.222 36.644h-.083a.704.704 0 0 1-.611-.778l1.805-15.015a.703.703 0 0 1 .778-.611c.375.041.653.389.611.778l-1.806 15.015a.704.704 0 0 1-.694.611ZM64.027 40.815a.699.699 0 0 1-.667-.89l4.167-15.014a.698.698 0 0 1 .861-.487.699.699 0 0 1 .487.862l-4.167 15.015a.726.726 0 0 1-.68.514Z"
        fill="#E05151"
      />
      <Path
        d="M64.028 40.815a.813.813 0 0 1-.18-.028l-7.084-1.946a.69.69 0 0 1-.486-.848.69.69 0 0 1 .847-.487l7.083 1.947a.69.69 0 0 1 .486.848.696.696 0 0 1-.666.514ZM49.444 48.739a3.623 3.623 0 0 1-3.611-3.615 3.623 3.623 0 0 1 3.611-3.614 3.623 3.623 0 0 1 3.611 3.614 3.623 3.623 0 0 1-3.61 3.615Zm0-6.117a2.509 2.509 0 0 0-2.5 2.502 2.509 2.509 0 0 0 2.5 2.503c1.375 0 2.5-1.126 2.5-2.503a2.509 2.509 0 0 0-2.5-2.502ZM59.167 47.14a2.983 2.983 0 0 1-2.986-2.989 2.983 2.983 0 0 1 2.986-2.989 2.983 2.983 0 0 1 2.986 2.99 2.983 2.983 0 0 1-2.986 2.988Zm0-4.866a1.886 1.886 0 0 0-1.875 1.877c0 1.029.847 1.877 1.875 1.877a1.886 1.886 0 0 0 1.875-1.877 1.886 1.886 0 0 0-1.875-1.877ZM89.166 105.6c-8.736 0-15.833-7.104-15.833-15.849 0-8.745 7.097-15.849 15.833-15.849 8.737 0 15.834 7.105 15.834 15.85 0 8.744-7.097 15.848-15.834 15.848Zm0-30.585c-8.11 0-14.722 6.617-14.722 14.736 0 8.12 6.611 14.737 14.722 14.737 8.112 0 14.723-6.618 14.723-14.737s-6.611-14.737-14.723-14.737Z"
        fill="#E05151"
      />
      <Path
        d="m91.389 90.168 5.403-5.408a.882.882 0 0 0 0-1.251l-.723-.723a.88.88 0 0 0-1.25 0l-5.402 5.408-5.403-5.408a.88.88 0 0 0-1.25 0l-.722.723a.882.882 0 0 0 0 1.251l5.402 5.408-5.402 5.408a.882.882 0 0 0 0 1.252l.722.722a.88.88 0 0 0 1.25 0l5.403-5.408 5.402 5.408a.88.88 0 0 0 1.25 0l.723-.722a.882.882 0 0 0 0-1.252l-5.403-5.408Z"
        fill="#E05151"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(15 14.4)" d="M0 0h90v91.2H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default PaymentFailed
