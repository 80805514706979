export default [
  {
    name: 'Fridges',
    id: 20,
    icon: '🧊',
  },
  {
    name: 'Microwaves',
    id: 24,
    icon: '🔥',
  },
  {
    name: 'Freezers',
    id: 26,
    icon: '❄️',
  },
  {
    name: 'Routers Wi-Fi',
    id: 28,
    icon: '🌐',
  },
  {
    name: 'Washing Machines',
    id: 40,
    icon: '🌪️',
  },
  {
    name: 'Mattresses',
    id: 43,
    icon: '🛏️',
  },
  {
    name: 'Sofas',
    id: 47,
    icon: '🛋️',
  },
  {
    name: 'Dining Chairs',
    id: 48,
    icon: '🪑',
  },
  {
    name: 'Desks',
    id: 6,
    icon: '☕',
  },
  {
    name: 'Laptops',
    id: 1,
    icon: '💻',
  },
  {
    name: 'Computer Monitors',
    id: 3,
    icon: '🖥️',
  },
  {
    name: 'Speakers',
    id: 10,
    icon: '🔈',
  },
  {
    name: 'Headphones',
    id: 12,
    icon: '🎧',
  },
  {
    name: 'WebCams',
    id: 13,
    icon: '📷',
  },
  {
    name: 'Microphones',
    id: 14,
    icon: '🎤',
  },
]
