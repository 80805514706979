import API from '~/apis/client'

export async function getCurrentProductAvailability(
  productId: number,
  params: {
    durationDays?: number
  }
) {
  return await API.handle(
    API.public.get<any>(
      `/public/v1/availability/${productId}/get_current_availability`,
      {
        params,
      }
    )
  )
}

export async function getNextProductAvailability(productId: number) {
  return await API.handle(
    API.public.get<any>(
      `/public/v1/availability/${productId}/get_next_availability`
    )
  )
}

export async function getNextProductAvailabilityForProductItem(
  productId: number,
  productItem: number
) {
  return await API.handle(
    API.public.get<any>(
      `/public/v1/availability/${productId}/product_item/${productItem}/get_next_availability`
    )
  )
}

export async function getAllAvailabilityForProductItem(
  productId: number,
  productItem: number,
  params: {
    // startsAt: Date,
    durationDays?: number
  }
) {
  return await API.handle(
    API.public.get<any>(
      `/public/v1/availability/${productId}/product_item/${productItem}/get_all`,
      {
        params,
      }
    )
  )
}
