import { createStackNavigator } from '@react-navigation/stack'

import Address from '~/screens/Address'
import Babyloania from '~/screens/Babyloania'
import Category from '~/screens/Category'
import Collection from '~/screens/Collection'
import Collections from '~/screens/Collections'
import Home from '~/screens/Home'
import Product from '~/screens/Product'
import Search from '~/screens/Search'

import { DashboardParamList } from '~/navigation/types'

const Dashboard = createStackNavigator<DashboardParamList>()

function DashboardStackNavigator() {
  return (
    <Dashboard.Navigator initialRouteName="Home">
      <Dashboard.Screen
        name="Home"
        component={Home}
        options={{ headerShown: false }}
      />

      <Dashboard.Screen
        name="Search"
        component={Search}
        options={{ headerShown: false }}
      />
      <Dashboard.Screen
        name="Category"
        component={Category}
        options={{ headerShown: false }}
      />
      <Dashboard.Screen
        name="Product"
        component={Product}
        options={{ headerShown: false }}
      />
      <Dashboard.Screen
        name="Collection"
        component={Collection}
        options={{ headerShown: false }}
      />
      <Dashboard.Screen
        name="Collections"
        component={Collections}
        options={{ headerShown: false }}
      />
      <Dashboard.Screen
        name="Address"
        component={Address}
        options={{ headerShown: false }}
      />
      <Dashboard.Screen
        name="Babyloania"
        component={Babyloania}
        options={{ headerShown: false }}
      />
    </Dashboard.Navigator>
  )
}
export default DashboardStackNavigator
