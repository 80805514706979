import React, { SetStateAction } from 'react'
import {
  GestureResponderEvent,
  Pressable,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

import Text from '~/components/StyledText'
import CartInfoModal from '~/components/cart/Modal'

import InformationIcon from '~/svg/Information'

type Props = {
  visibleState: [boolean, React.Dispatch<SetStateAction<boolean>>]
  onPress: (event: GestureResponderEvent) => void
  style?: StyleProp<ViewStyle>
}

const StockValidityInfo: React.FC<Props> = ({
  style,
  visibleState,
  onPress,
}) => {
  const tailwind = useTailwind()

  const [modalVisible, setModalVisible] = visibleState

  return (
    <React.Fragment>
      <View
        style={[
          tailwind('pt-14 mt-16 bg-red-500 px-4 mx-4 py-3 mb-4 '),
          {
            borderRadius: 8,
          },
          style,
        ]}
      >
        <View style={tailwind('flex flex-row justify-between')}>
          <Text style={tailwind('text-sm text-white')}>
            Waktu jaminan stok tersedia:{' '}
            <Text style={tailwind('font-bold')}>Habis</Text>
          </Text>

          <Pressable onPress={() => setModalVisible(true)}>
            <InformationIcon style={tailwind('text-white')} />
          </Pressable>
        </View>

        <Pressable onPress={onPress} style={tailwind('inline-block pr-2 py-1')}>
          <Text
            style={[
              tailwind('border-white text-xs pt-1 text-white'),
              {
                borderBottomWidth: 1,
                borderBottomColor: '#fff',
              },
            ]}
          >
            Cek ulang ketersediaan produk
          </Text>
        </Pressable>
      </View>

      <CartInfoModal open={modalVisible} setOpen={setModalVisible} />
    </React.Fragment>
  )
}

export default StockValidityInfo
