import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CustomerAttributesFull } from '@withbioma/apitype-publicapi-customer/lib/v1/common/customer_full'

import {
  constructErrorPayload,
  constructExtraReducer,
  constructSuccessPayload,
} from '~/store/helper'

import {
  get as getCall,
  register as registerCall,
  updateAddress as updateAddressCall,
} from '~/apis/user'
import { ISchema } from '~/interfaces/User'

interface IState {
  user?: CustomerAttributesFull
  loading: object
  responses: object
}
const initialState: IState = { user: undefined, loading: {}, responses: {} }

export const get = createAsyncThunk(
  'user/get',
  async (_, { rejectWithValue, dispatch }) => {
    const [res, resErr] = await getCall()
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const register = createAsyncThunk(
  'user/register',
  async (payload: ISchema, { rejectWithValue, dispatch }) => {
    const [res, resErr] = await registerCall(payload)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const updateAddress = createAsyncThunk(
  'user/updateAddress',
  async (payload: any, { rejectWithValue, dispatch }) => {
    const [res, resErr] = await updateAddressCall(payload)
    if (resErr) {
      return rejectWithValue(constructErrorPayload(resErr))
    }
    return res && constructSuccessPayload(res)
  }
)

export const reducerSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetResponse(state: any, action: PayloadAction<any>) {
      const apiName = action.payload
      state.responses = {
        ...state.responses,
        [apiName]: null,
      }
    },
    resetUser(state: IState) {
      state.user = undefined
    },
  },
  extraReducers: (builder) => {
    constructExtraReducer(
      builder,
      get,
      (state: IState, action: PayloadAction<any>) => {
        // console.log(action)
        state.user = action.payload!.body.customer
      }
    )
    constructExtraReducer(builder, register)
    constructExtraReducer(
      builder,
      updateAddress,
      (state: IState, action: PayloadAction<any>) => {
        state.user = action.payload!.body.customer
      }
    )
  },
})

export const { resetResponse, resetUser } = reducerSlice.actions
export default reducerSlice.reducer
