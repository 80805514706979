import { AntDesign } from '@expo/vector-icons'
import { useState } from 'react'
import { GestureResponderEvent, Pressable, View } from 'react-native'
import { useTailwind } from 'tailwind-rn/dist'

interface IProps {
  isActive: boolean
  onPress: (event: GestureResponderEvent, state: boolean) => void
}

export default function Wishlist({ isActive, onPress }: IProps) {
  const tailwind = useTailwind()

  const [checkedState, setChecked] = useState(isActive)

  const handlePress = (event: GestureResponderEvent) => {
    setChecked((state) => !state)

    if (typeof onPress == 'function') {
      onPress(event, checkedState)
    }
  }

  return (
    <View>
      <Pressable
        style={tailwind('mb-2 flex-row h-4 items-center')}
        onPress={handlePress}
      >
        <AntDesign
          size={24}
          style={tailwind('mr-2')}
          name={checkedState ? 'heart' : 'hearto'}
        />
      </Pressable>
    </View>
  )
}
